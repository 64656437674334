import { memo } from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
const Image = props => {
    const { src, alt } = props;
    const {height} = useWindowDimensions();
    const preventDragHandler = (e) => {
        e.preventDefault();
    }
    return (
        
        <img onDragStart={(e) => {preventDragHandler(e)}}  alt={alt} className="rounded mx-auto d-block" style={{maxHeight:`${height / 11}px`}} src={src}></img>
                           
    );
}

export default memo(Image);