import React, { Fragment, useContext, useEffect, useState } from 'react';
import {getCategories} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { AuthContext } from '../../../context/Auth.context';
import { Link, useNavigate } from 'react-router-dom';
const Categories = () => {
    let navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const {state, CheckResponse, setConfig } = useContext(AuthContext);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }
    
    useEffect(() => {
        getCategories(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setCategories(data.categories);
                setConfig(data.config);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoading(false);
          });
    }, []);

    return (
        <Fragment>
             <Toast toastList={list}/>
            <div className="row">
                <div className="col-sm-4">
                    <h1>Categorias</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <Link to="/catalogs/category/" className="btn btn-success mt-2"><i className="bi bi-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Ieps</th>
                                {state.config.cake_labels && <th>Etiqueta de pasteles</th>}
                                <th>Activo</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map(item => 
                                <tr key={item.id}>
                                    <th>{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.ieps == true && <span className="badge text-bg-success">Si </span>}
                                        {item.ieps == false && <span className="badge text-bg-danger">No </span>}
                                    </td>
                                    {state.config.cake_labels && <td>
                                        {item.cake_label == true && <span className="badge text-bg-success">Si </span>}
                                        {item.cake_label == false && <span className="badge text-bg-danger">No </span>}
                                    </td>}
                                    <td>
                                        {item.active == true && <span className="badge text-bg-success">Si </span>}
                                        {item.active == false && <span className="badge text-bg-danger">No </span>}
                                    </td>
                                    <th>
                                        <Link className="btn btn-primary" to={`/catalogs/category/${item.id}`} role="button"><i className="bi bi-pencil"></i></Link>
                                    </th>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                </div>
            </div>}

            {loading &&  <div className="d-flex justify-content-center mt-4">
                            <div className="spinner-border text-primary" style={{"width" : "8rem", "height": "8rem"}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                         </div>}
        </Fragment>
    );
}

export default Categories;