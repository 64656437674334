import { EChart } from '@kbox-labs/react-echarts'
import React, {useContext, useEffect, useState } from 'react';
import { getDashboard } from './services/genealServises';
import { AuthContext } from '../context/Auth.context';
import Toast from './helpers/Toast';
import Spinner from './helpers/Spinner';
import { moneyFormat } from './Constants';
const Dashboard = () => {
  
  const [list, setList] = useState([]);
  const [dailySales, setDailySales] = useState(0);
  const [monthlySales, setMonthlySales] = useState(0);
  const [weeklySales, setWeeklySales] = useState(0);

  const [barData, setBarData] = useState([]);
  const [barTitles, setBarTitles] = useState([]);

  const [lineData, setLineData] = useState([]);
  const [lineTitles, setLineTitles] = useState([]);
  const { state, CheckResponse } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      getDashboardData();
      setTimer(timer + 1);
    }, 60000);
  }, [timer]);

  const showToast = (t,d,tt) => {
    setList([...list, {
      id: Math.floor((Math.random() * 101) + 1),
      title: t,
      description: d,
      type: tt
    }]);
  }

  useEffect(() => {
    getDashboardData();
    setTimer(timer + 1);
  }, []);

  const getDashboardData = () => {
    getDashboard(state.auth).then(data => {
      CheckResponse(data);
      if(data.code == 200){
        // console.log(data);
        setDailySales(data['daily']);
        setMonthlySales(data['monthly']);
        setWeeklySales(data['weekly']);

        setLineData(data['monthly_earns_values']);
        setLineTitles(data['monthly_earns_labels']);
        var newBarTitles = [];
        var newBardata = [];
        for (let index = 0; index < data['tops'].length; index++) {
          const element = data['tops'][index];
          newBarTitles.push(data['tops'][index].name);
          newBardata.push(data['tops'][index].qty);
        }
        setBarData(newBardata);
        setBarTitles(newBarTitles);
      }else{
        showToast('Oops!','Algo salio mal!','danger');
      }
      setLoading(false);
    });
  } 

  return (
    <>
      <Toast toastList={list}/>
      <div className="row">
        <div className="mt-3 col-sm-12 col-md-4">
            <span className ="badge bg-primary text-wrap text-center bg-gradient" style={{width: "100%"}}>
                <h4>Ventas Diarias</h4>
                <h5 className="mt-3">{moneyFormat.format(dailySales)}</h5>
            </span>
        </div>  
        <div className="mt-3 col-sm-12 col-md-4">
            <span className ="badge bg-primary text-wrap text-center bg-gradient" style={{width: "100%"}}>
                <h4>Ventas Semanales</h4>
                <h5 className="mt-3">{moneyFormat.format(weeklySales)}</h5>
            </span>
        </div>  
        <div className="mt-3 col-sm-12 col-md-4">
            <span className ="badge bg-primary text-wrap text-center bg-gradient" style={{width: "100%"}}>
                <h4>Ventas Mensuales</h4>
                <h5 className="mt-3">{moneyFormat.format(monthlySales)}</h5>
            </span>
        </div>
      </div>
      
      {!loading  && <div className="row mt-4">
        <div className="col-sm-12 col-md-6 mt-3 text-center">
          <h2>10 Mas Vendidos</h2>
          <EChart
            style={{
              height: '500px',
              width: '100%'
            }}
            xAxis={{
              type: 'category',
              data: barTitles,
              axisLabel: { interval: 0, rotate: 45 }
            }}
            yAxis={{
              type: 'value'
            }}
            series={[
              {
                name: 'Vendidos',
                data: barData,
                type: 'bar',
                areaStyle: {},
                // color: [
                //   '#0d6efd',
                // ]
              }
            ]}
            tooltip= {{
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
            }}
            grid={{
              bottom: "40%"
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6 mt-3 text-center">
        <h2>Historial de Ganancias</h2>
          <EChart
            style={{
              height: '450px',
              width: '100%'
            }}
            xAxis={{
              type: 'category',
              boundaryGap: false,
              data: lineTitles,
              axisLabel: { interval: 0, rotate: 60 }
            }}
            yAxis={{
              type: 'value'
            }}
            series={[
              {
                name: 'Ganancias',
                data: lineData,
                type: 'line',
                areaStyle: {},
                tooltip: {
                  valueFormatter: value => moneyFormat.format(value)
                },
                // color: [
                //   '#0d6efd',
                // ]
              }
            ]}
            tooltip= {{
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
            }}
            grid={{
              bottom: "25%"
            }}
          />
        </div>
      </div>}
      {loading  && <div className="row mt-4">
        <Spinner size={8} margin={4}></Spinner>
      </div>}
    </>
  );
}

export default Dashboard;