import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const Toast = props => {
    const { toastList, position } = props;
    ///list types: primary,success, danger, warning, info
    const [list, setList] = useState(toastList);
    // const [deleteIn, setDeleteIn] = useState(5);

    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);
    
    useEffect(() => {
        let deleteIn = 0;
        if(toastList.length && list.length && toastList[0].delete !== undefined){
            if(toastList[0].delete){
                deleteIn = toastList[0].delete;
            }
        }else{
            deleteIn = 5;
        }
        
        if(deleteIn > 0){     
            const interval = setInterval(() => {
                if(toastList.length && list.length){
                    deleteToast(toastList[0].id);
                }
            }, deleteIn * 1000);
            
            return () => {
                clearInterval(interval);
            }
        }

        // eslint-disable-next-line
    }, [toastList, list]);

    const deleteToast = id => {
        const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    }

    return (
        <>
            <div className={`toast-container p-1 ${position}`}>
                {
                    list.map((toast, i) =>     
                        <div key={toast.id} className={`toast p-0 text-bg-${toast.type} fade show`} role="alert" aria-live="assertive" aria-atomic="true" style={{"height": "10%", maxWidth: `${toast.short ? '200px' : '100%'}`}}>
                            <div className="toast-header p-1">
                               {toast.type == "danger" && <i className="bi bi-x-circle" style={{"fontSize": "1.5em"}}></i>}
                               {toast.type == "success" && <i className="bi bi-check-circle" style={{"fontSize": "1.5em"}}></i>}
                               {toast.type == "info" && <i className="bi bi-info-circle" style={{"fontSize": "1.5em"}}></i>}
                               {toast.type == "warning" && <i className="bi bi-exclamation-circle" style={{"fontSize": "1.5em"}}></i>}
                                <strong className="ms-2 me-auto">{toast.title}</strong>

                                {toast.loading === undefined && <button onClick={() => deleteToast(toast.id)} type="button" className="btn-close me-2" data-bs-dismiss="toast" aria-label="Close"></button>}
                            </div>
                            {toast.action === undefined && toast.loading === undefined && <div className="toast-body">
                                {toast.description}
                            </div>}
                            {toast.action !== undefined && <div className="toast-body"> 
                                {toast.description}
                                <div className="text-center mt-2" style={{marginBottom: "-16px"}}>
                                    <p className="text-center">
                                        <button type="button" onClick={() => {toast.action(); deleteToast(toast.id);}} className="btn btn-light btn-sm">{toast.linkDesc}</button>
                                    </p>
                                </div>
                            </div>}
                            {toast.loading !== undefined && <div className="toast-body"> 
                                {toast.description}
                                <Spinner size={3} />
                            </div>}
                        </div>
                    )
                }
            </div>
        </>
    );
}

Toast.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string.isRequired,
    short: PropTypes.bool,
    container: PropTypes.bool,
}

Toast.defaultProps = {
    toastList: PropTypes.array.isRequired,
    position: 'top-0 start-50 translate-middle-x',
    short: false,
    container: true,
}

export default Toast;