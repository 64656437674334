import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ContextProvider } from './context/Auth.context.js';
import App from './App'
import { HashRouter } from 'react-router-dom';

function AppWithProvider() {
  return (
    <ContextProvider value={500}>
      <App />
    </ContextProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <AppWithProvider />
  </HashRouter>
);

serviceWorkerRegistration.register();

