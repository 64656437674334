import { backend } from "../Constants";

export async function getSaleItems(ctx) {
    return await fetch(backend + 'sale/info', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getCuts(ctx, rows = 10, page = 1) {
    return await fetch(backend + 'cuts/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function startCut(ctx) {
    return await fetch(backend + 'cut/start', {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function endCut(ctx) {
    return await fetch(backend + 'cut/end', {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function setCutLimits(ctx,newLimit) {
    return await fetch(backend + 'cut/limit/'+newLimit, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getCutPrints(ctx,id) {
    return await fetch(backend + 'cut/print/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getCurrentCut(ctx) {
    return await fetch(backend + 'cut/current', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addTicket(ctx,data,cut,exr) {
    return await fetch(backend + 'sale', {
        method: 'POST',
        body: JSON.stringify({
            sale: data,
            cut: cut,
            exr: exr,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateCutAlert(ctx,cut) {
    return await fetch(backend + 'cut/alert', {
        method: 'PUT',
        body: JSON.stringify({
            cut: cut,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function saveOfflineCart(ctx, sales, cut) {
    return await fetch(backend + 'sale/offline', {
        method: 'POST',
        body: JSON.stringify({
            sales: sales,
            cut: cut,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function switchUser(ctx) {
    return await fetch(backend + 'user/switch', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getCutTickets(ctx, id, rows = 10, page = 1) {
    return await fetch(backend + 'cut/tickets/'+id+'/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getTickets(ctx, initDate, endDate, rows = 10, page = 1) {
    return await fetch(backend + 'tickets/'+initDate+'/'+endDate+'/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function deleteTicket(ctx, id) {
    return await fetch(backend + 'tickets/'+id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}