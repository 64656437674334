import { backend } from "../Constants";

//payments
export async function getPayments(ctx) {
    return await fetch(backend + 'payments', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getPayment(ctx,id) {
    return await fetch(backend + 'payment/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updatePayment(ctx,data) {
    return await fetch(backend + 'payment/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            count_for_cut_withdraw: data.count_for_cut_withdraw,
            active: data.active,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addPayment(ctx,data) {
    return await fetch(backend + 'payment', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

//categories
export async function getCategories(ctx) {
    console.log(ctx);
    return await fetch(backend + 'categories', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getCategory(ctx,id) {
    return await fetch(backend + 'category/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateCategory(ctx,data) {
    return await fetch(backend + 'category/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            ieps: data.ieps,
            cake_label: data.cake_label,
            active: data.active,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addCategory(ctx,data) {
    return await fetch(backend + 'category', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

//products
export async function getProducts(ctx, rows = 10, page = 1) {
    return await fetch(backend + 'products/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getProduct(ctx,id) {
    return await fetch(backend + 'product/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateProduct(ctx,data) {
    return await fetch(backend + 'product/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            price: data.price,
            price_baker: data.price_baker,
            id_category: data.id_category,
            taxes: data.taxes,
            is_a_cake: data.is_a_cake,
            image: data.image,
            active: data.active,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addProduct(ctx,data) {
    return await fetch(backend + 'product', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
            price: data.price,
            price_baker: data.price_baker,
            id_category: data.id_category,
            taxes: data.taxes,
            is_a_cake: data.is_a_cake,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

// sizes
export async function getSizes(ctx,rows = 10, page = 1) {
    return await fetch(backend+'sizes/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getSize(ctx,id) {
    return await fetch(backend+'size/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateSize(ctx,data) {
    return await fetch(backend+'size/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            floors: data.floors,
            active: data.active,
            options: data.options
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addSize(ctx,data) {
    return await fetch(backend+'size', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
            floors: data.floors,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

// flavosrs
export async function getFlavors(ctx,rows = 10, page = 1) {
    return await fetch(backend+'flavors/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getFlavor(ctx,id) {
    return await fetch(backend+'flavor/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateFlavor(ctx,data) {
    return await fetch(backend+'flavor/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            active: data.active,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addFlavor(ctx,data) {
    return await fetch(backend+'flavor', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

//fillings
export async function getFillings(ctx,rows = 10, page = 1) {
    return await fetch(backend+'fillings/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getFilling(ctx,id) {
    return await fetch(backend+'filling/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateFilling(ctx,data) {
    return await fetch(backend+'filling/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            active: data.active,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addFilling(ctx,data) {
    return await fetch(backend+'filling', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

//size options
export async function getSizeoptions(ctx,rows = 10, page = 1) {
    return await fetch(backend+'sizeoptions/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getSizeoption(ctx,id) {
    return await fetch(backend+'sizeoption/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateSizeoption(ctx,data) {
    return await fetch(backend+'sizeoption/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            active: data.active
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addSizeoption(ctx,data) {
    return await fetch(backend+'sizeoption', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

// //showcase cakes
// export async function getShowcasecakes(ctx,rows = 10, page = 1) {
//     return await fetch(backend+'showcasecakes/'+rows+'?page='+page, {
//         method: 'GET',
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//             'Authorization': ctx.token,
//             'Type': ctx.type,
//         },
//         })
//         .then(data => data.json())
//         .catch((err) => {
//             return'Error en el servidor';
//         });
// }

// export async function getShowcasecake(ctx,id) {
//     return await fetch(backend+'showcasecake/'+id, {
//         method: 'GET',
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//             'Authorization': ctx.token,
//             'Type': ctx.type,
//         },
//         })
//         .then(data => data.json())
//         .catch((err) => {
//             return'Error en el servidor';
//         });
// }

// export async function updateShowcasecake(ctx,data) {
//     return await fetch(backend+'showcasecake/'+data.id, {
//         method: 'PUT',
//         body: JSON.stringify({
//             name: data.name,
//             active: data.active
//         }),
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//             'Authorization': ctx.token,
//             'Type': ctx.type,
//         },
//         })
//         .then(data => data.json())
//         .catch((err) => {
//             return'Error en el servidor';
//         });
// }

// export async function addShowcasecake(ctx,data) {
//     return await fetch(backend+'showcasecake', {
//         method: 'PUT',
//         body: JSON.stringify({
//             name: data.name,
//         }),
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//             'Authorization': ctx.token,
//             'Type': ctx.type,
//         },
//         })
//         .then(data => data.json())
//         .catch((err) => {
//             return'Error en el servidor';
//         });
// }

// users
export async function getUsers(ctx,rows = 10, page = 1) {
    return await fetch(backend+'users/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getUser(ctx,id) {
    return await fetch(backend+'user/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateUser(ctx,data) {
    return await fetch(backend+'user/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
            login: data.login,
            password: data.newPassword,
            type: data.type,
            active: data.active
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addUser(ctx,data) {
    return await fetch(backend+'user', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
            type: data.type,
            login: data.login,
            password: data.password,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function deleteUser(ctx,data) {
    return await fetch(backend+'user/'+data.id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

//configuration

export async function getConfig(ctx) {
    return await fetch(backend+'config', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateConfig(ctx,data) {
    return await fetch(backend+'config', {
        method: 'PUT',
        body: JSON.stringify({
            printer_name: data.printer_name,
            local_name: data.local_name,
            rfc: data.rfc,
            dir_1: data.dir_1,
            dir_2: data.dir_2,
            city: data.city,
            phone: data.phone,
            email: data.email,
            cake_labels: data.cake_labels,
            cake_management: data.cake_management,
            dlls_count_for_withdraw: data.dlls_count_for_withdraw,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

//order creators
export async function getOrderCreators(ctx) {
    return await fetch(backend + 'creators/order', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getOrderCreator(ctx,id) {
    return await fetch(backend + 'creator/order/'+id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateOrderCreator(ctx,data) {
    return await fetch(backend + 'creator/order/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function addOrderCreator(ctx,data) {
    return await fetch(backend + 'creator/order', {
        method: 'POST',
        body: JSON.stringify({
            name: data.name,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}