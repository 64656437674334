import React, {useContext, useEffect, useState } from 'react';
import {deleteUser, getUsers} from '../services/catalogServices';
import Toast from '../helpers/Toast';
import { AuthContext } from '../../context/Auth.context';
import Spinner from '../helpers/Spinner';
import { Link, useNavigate } from 'react-router-dom';

const Users = () => {
    let navigate = useNavigate();

    const newUserToDelete = {id: 0, name: ''};
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pages, setPages] = useState([]);
    const [total_current, setTotal_current] = useState(1);
    const [current_page, setCurrent_page] = useState(1);
    const [first_item, setFirst_item] = useState(1);
    const [last_item, setLast_item] = useState(1);
    const { state, CheckResponse } = useContext(AuthContext);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState(newUserToDelete);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }
    
    const loadUsers = () => {
        getUsers(state.auth, 10, current_page).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setUsers(data.users);
                setPages(data.pages);
                setTotal_current(data.total_current);
                setCurrent_page(data.current_page);
                setFirst_item(data.first_item);
                setLast_item(data.last_item);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        loadUsers();
    }, [current_page]);

    const setUserToDeleteModal = (id,name) =>{
        setDeleteModal(true);
        setUserToDelete({id : id, name : name});
    }
    
    const procesDelete = () =>{
        setLoadingDelete(true);
        deleteUser(state.auth, userToDelete).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                loadUsers();
                setUserToDelete(newUserToDelete);
                showToast('Hecho!','Elminado con exito!','success');
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }

            setDeleteModal(false);
            setLoadingDelete(false);
        });
    }

    return (
        <>
            <Toast toastList={list}/>
            <div className="row">
                <div className="col-sm-4">
                    <h1>Usuarios</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                    <Link to="/user" className="btn btn-success mt-2"><i className="bi bi-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Activo</th>
                                <th>Editar</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map(item => 
                                <tr key={item.id}>
                                    <th>{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.active == true && <span className="badge text-bg-success">Si </span>}
                                        {item.active == false && <span className="badge text-bg-danger">No </span>}
                                    </td>
                                    <th>
                                        <Link to={`/user/${item.id}`} className="btn btn-primary"><i className="bi bi-pencil"></i></Link>
                                        </th>
                                    <th>
                                        <button onClick={() => {setUserToDeleteModal(item.id,item.name)}} className="btn btn-danger" type="button"><i className="bi bi-trash"></i></button>
                                    </th>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <ul className="pagination">
                                {pages.map(page => 
                                    <div key={page}>
                                        {current_page === page ? 
                                            <span key={page} className="page-link active me-2" style={{"width": "40px", "textAlign": "center"}}>{page}</span> 
                                            : 
                                            <li key={page} className="page-page me-2" style={{"width": "40px", "textAlign": "center"}}><a className="page-link" onClick={() => setCurrent_page(page)}>{page}</a></li>
                                        }
                                    </div>
                                )}
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <p className="text-sm-start mt-2">Mostrando <b>{first_item}</b> a <b>{last_item}</b> de un total de <b>{total_current}</b></p>
                        </div>
                    </div>
                </div>
            </div>}                        
            {loading && <Spinner size={8} margin={4} ></Spinner>}

            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: deleteModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Eliminar Usuarios</h1>
                            <button onClick={() => { setDeleteModal(false) }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {/* orden de pedido */}
                                {!loadingDelete && <>
                                    <div className="row">
                                        <div className="col-sm-12 mt-1 text-center">
                                                <h3>Estas seguro de eliminar a {userToDelete.name}?</h3>
                                         </div>
                                    </div>
                                </>}

                                {loadingDelete && <Spinner size={8} margin={4} ></Spinner>}
                            </div>
                        </div>


                        {!loadingDelete && <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { setDeleteModal(false) }} className="btn btn-danger btn-sm" type="button">Cancelar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { procesDelete() }} className={`btn btn-success btn-sm`} type="button">Si, Elminar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;