import { useContext,useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/Auth.context";
import { getConfig } from "../services/catalogServices";
import Spinner from "../helpers/Spinner";


const Catalogs = () => {
    const {state, CheckResponse, setConfig} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getConfig(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setConfig(data.config);
            }
            setLoading(false);
          });
    }, []);

    return (
        <>
            {!loading && <>
                <div className="row">
                    <div className="col-sm-12">
                        <h1>Catalogos</h1>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12">
                        <h2>Ventas</h2>
                    </div>
                </div>
                {/* al estar dentro de catalogs el /catalogs/ va implicito */}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="list-group">
                            <Link to="payments" className="list-group-item list-group-item-action"><h4>Metodos de Pagos</h4></Link>
                            <Link to="categories" className="list-group-item list-group-item-action"><h4>Categorias</h4></Link>
                            <Link to="products" className="list-group-item list-group-item-action"><h4>Productos</h4></Link>
                        </div>
                    </div>
                </div> 
                {state.config.cake_management == true && <>
                    <div className="row mt-4">
                        <div className="col-sm-12">
                            <h2>Administracion de Pasteles</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="list-group">
                                <Link to="sizes" className="list-group-item list-group-item-action"><h4>Tamaños</h4></Link>
                                <Link to="sizeoptions" className="list-group-item list-group-item-action"><h4>Opciones de Tamaño</h4></Link>
                                <Link to="flavors" className="list-group-item list-group-item-action"><h4>Sabores</h4></Link>
                                <Link to="fillings" className="list-group-item list-group-item-action"><h4>Rellenos</h4></Link>
                                <Link to="order/creators" className="list-group-item list-group-item-action"><h4>Quien hace pedidos</h4></Link>
                            </div>
                        </div>
                    </div>
                </>}
            </>}
            
            {loading && <Spinner size={8} margin={4} ></Spinner>}
        </>
    );
}

export default Catalogs;