import PropTypes from 'prop-types';

const Spinner = props => {
    const { size, margin} = props;

    return (
        <div className="d-flex justify-content-center">
            <div className={`spinner-border text-primary mt-${margin}`} style={{"width" : `${size}em`, "height": `${size}em`}} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

Spinner.propTypes = {
    size: PropTypes.number.isRequired,
    margin: PropTypes.number.isRequired,
}

Spinner.defaultProps = {
    size: 2,
    margin: 0,
}

export default Spinner;