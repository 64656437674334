import React, { Fragment, useContext, useEffect, useState } from 'react';
import {addProduct,getCategories} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../helpers/Spinner';
import { AuthContext } from '../../../context/Auth.context';

const ProductAdd = () => {
    let navigate = useNavigate();

    const [product, setProduct] = useState({
        id: '',
        name: '',
        id_category: 0,
        active: '',
        taxes: false,
        is_a_cake: false,
        created_at: '',
        updated_at: ''
    });

    const [loadingC, setLoadingC] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const [categories, setCategories] = useState([]);
    const {state, CheckResponse } = useContext(AuthContext);

    useEffect(() => {
        getCategories(state.auth,).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setCategories(data.categories);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoadingC(false);
          });
    }, []);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let error = false;
        
        if(!error && product.name.length < 3){
            error = true;
            showToast('Oops!','El nombre debe tener al menos 3 caracteres!', 'warning');
        }
        
        if(!error &&  product.id_category == 0){
            error = true;
            showToast('Oops!','Debe seleccionar una categoria!', 'warning');
        }

        if(!error){
            setSaving(true);
            addProduct(state.auth,product).then(data => {
                CheckResponse(data);
                if(data.code == 200){
                    showToast('Hecho!','Guardado con exito, Redireccionando a otra pagina!','success');
                    setTimeout(function() {
                        navigate('/catalogs/product/'+data.id);
                        }, 5000);
                }else{
                    showToast('Oops!','Algo salio mal!','danger');
                }
                setSaving(false);
            });
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Agregar Producto</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {<div className="row">
                <div className="col-sm-12">
                    <Toast toastList={list}/>
                    <form name="ProductForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={e => setProduct({ ...product, name: e.target.value })}
                                    value={product.name}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="price">Precio</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="price"
                                    id="price"
                                    min="0" 
                                    step="0.05"
                                    onChange={e => setProduct({ ...product, price: e.target.value })}
                                    value={product.price}
                                    placeholder="0.00"
                                />
                            </div>
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="price">Precio Panadero</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="price"
                                    id="price"
                                    min="0" 
                                    step="0.05"
                                    onChange={e => setProduct({ ...product, price_baker: e.target.value })}
                                    value={product.price_baker}
                                    placeholder="0.00"
                                />
                            </div>
                            
                            
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="categorySelect">Categoria</label>
                                {!loadingC &&  <select id="categorySelect" className="form-control" value={product.id_category} onChange={e => setProduct({ ...product, id_category: e.target.value })}>
                                    <option value="0" disabled>Selecione...</option>
                                    {categories.map(item => 
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                     )}
                                </select>}
                                {loadingC && <Spinner></Spinner>}
                            </div>

                            <div className="col-sm-3 mt-3">
                                <label className="form-check-label mb-3" htmlFor="taxes_switch">Impuestos</label>
                                <div className="form-check form-switch">
                                    <input onChange={e => setProduct({ ...product, taxes: !product.taxes })} className="form-check-input" type="checkbox" id="taxes_switch" checked={product.taxes ? 'checked' : ''}></input>
                                </div>
                            </div>
                            
                            <div className="col-sm-3 mt-3">
                                    <label className="form-check-label mb-3" htmlFor="is_a_cake_switch">Es un pastel de vitrina?</label>
                                    <div className="form-check form-switch">
                                        <input onChange={e => setProduct({ ...product, is_a_cake: !product.is_a_cake })} className="form-check-input" type="checkbox" id="is_a_cake_switch" checked={product.is_a_cake ? 'checked' : ''}></input>
                                    </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving &&  <Spinner size={5} margin={4}></Spinner>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>} 
            
        </Fragment>
    );
}

export default ProductAdd;