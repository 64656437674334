import React, { useContext, useEffect, useState } from 'react';
import Sidebar from './helpers/Sidebar.js';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { AuthContext } from '../context/Auth.context.js';

import Dashboard from "./Dashboard";
import Catalogs from "./catalogs/Catalogs"

//pagos
import Payments from "./catalogs/payments/Payments";
import Payment from "./catalogs/payments/Payment";
import PaymentAdd from './catalogs/payments/PaymentAdd.js';
//categorias
import Categories from "./catalogs/categories/Categories";
import Category from "./catalogs/categories/Category";
import CategoryAdd from "./catalogs/categories/CategoryAdd";
//productos
import Products from './catalogs/products/Products.js';
import Product from './catalogs/products/Product.js';
import ProductAdd from './catalogs/products/ProductAdd.js';
import Sales from './sales/Sales';

//cake management
// usuarios
import Users from './users/Users.js';
import User from './users/User.js';
import UserAdd from './users/UserAdd.js';

//tamaños
import Sizes from "./catalogs/sizes/Sizes.js"
import SizeAdd from "./catalogs/sizes/SizeAdd.js"
import Size from "./catalogs/sizes/Size.js"

// sabores
import Flavors from './catalogs/flavors/Flavors.js';
import Flavor from './catalogs/flavors/Flavor.js';
import FlavorAdd from './catalogs/flavors/FlavorAdd.js';

//rellenos
import Fillings from './catalogs/fillings/Fillings.js';
import Filling from './catalogs/fillings/Filling.js';
import FillingAdd from './catalogs/fillings/FillingAdd.js';

//opciones de tamaño
import Sizeoptions from './catalogs/sizeoptions/Sizeoptions.js';
import SizeoptionAdd from './catalogs/sizeoptions/SizeoptionAdd.js';
import Sizeoption from './catalogs/sizeoptions/Sizeoption.js';

import { getVersion } from './services/versionServices.js';
import Versions from './Versions.js';
import { version } from './Constants.js';
import { isElectron } from './functions/isElectron.js';
import { useIsOnline } from 'react-use-is-online';

import OfflineIndicator from './helpers/OfflineIndicator.js';
import Config from './config/Config.js';
import Orders from './Orders.js';
import OfflineSidebar from './helpers/OfflineSidebar.js';
import Cuts from './cuts/Cuts.js';
import OrderCreators from './catalogs/order_creators/OrderCreators.js';
import OrderCreator from './catalogs/order_creators/OrderCreator.js';
import OrderCreatorAdd from './catalogs/order_creators/OrderCreatorAdd.js';
import CakeLabels from './cake-labels/CakeLabels.js';
import CutTickets from './cuts/CutTickets.js';
import Tickets from './tickets/Tickets.js';
//-cake management

const Menu = () => {
    const { state ,logout, login, CheckResponse } = useContext(AuthContext);
    
    const onLogout = (e) => {
        e.preventDefault();
        logout();
    }

    const { isOnline, isOffline, error } = useIsOnline();

    const [newVersion, setNewVersion] = useState(false);

    useEffect(() => {
        if(isElectron()){
            setTimeout(()=> {
                getVersion(state.auth,version).then(data => {
                    CheckResponse(data);
                    if (data.code == 200) {
                        setNewVersion(data.new);
                    }
                });
            },2000);
        }
    }, []);

    return (
    <main className="d-flex flex-nowrap" style={{"height": "100vh"}}>
        {isOnline && <Sidebar version={version} newVersion={newVersion} doLogin={login} CheckResponse={CheckResponse} logout={onLogout} state={state}></Sidebar>}
        {!isOnline && <OfflineSidebar/> }
        <div className="container mt-3" style={{overflowY: "auto"}}>
            <div className="row">
                <div className="col-sm-12">
                {isOnline &&  <Routes>     
                    {/* dashboard */}
                    {state.auth.type != 3 && <Route exact path="/" element={<Dashboard />} />}
                    
                    {/* configuracion */}
                    {state.auth.type != 3 && <Route path="/config" element={<Config />} />}
                                    
                    {/* catalogos */}
                    {state.auth.type != 3 && <Route path="/catalogs" element={<Catalogs />} />}
                    
                    {/* versiones */}
                    {state.auth.type != 3 && <Route path="/versions" element={<Versions />} />}
    
                    {/* usaurios */}
                    {state.auth.type != 3 && <Route path="/users" element={<Users />} />}
                    {state.auth.type != 3 && <Route path="/user/:id" element={<User />} />}
                    {state.auth.type != 3 && <Route path="/user" element={<UserAdd />} />}
    
                    {/* pagos */}
                    {state.auth.type != 3 && <Route path="/catalogs/payments" element={<Payments/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/payment/:id" element={<Payment/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/payment" element={<PaymentAdd/>} />}
                    
                    {/* categorias */}
                    {state.auth.type != 3 && <Route path="/catalogs/categories" element={<Categories/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/category/:id" element={<Category/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/category" element={<CategoryAdd/>} />}
                    
                    {/* productos */}
                    {state.auth.type != 3 && <Route path="/catalogs/products" element={<Products/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/product/:id" element={<Product/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/product" element={<ProductAdd/>} />}
                    
                    {/* tamanos */}
                    {state.auth.type != 3 && <Route path="/catalogs/sizes" element={<Sizes />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/size/:id" element={<Size />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/size" element={<SizeAdd />} />}
    
                    {/* sabores */}
                    {state.auth.type != 3 && <Route path="/catalogs/flavors" element={<Flavors />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/flavor/:id" element={<Flavor />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/flavor" element={<FlavorAdd />} />}
    
                    {/* rellenos */}
                    {state.auth.type != 3 && <Route path="/catalogs/fillings" element={<Fillings />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/filling/:id" element={<Filling />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/filling" element={<FillingAdd />} />}
    
                    {/* opciones de tamanos */}
                    {state.auth.type != 3 && <Route path="/catalogs/sizeoptions" element={<Sizeoptions />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/sizeoption/:id" element={<Sizeoption />} />}
                    {state.auth.type != 3 && <Route path="/catalogs/sizeoption" element={<SizeoptionAdd />} />}

                    {/* quien hace pedidos */}
                    {state.auth.type != 3 && <Route path="/catalogs/order/creators" element={<OrderCreators/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/order/creator/:id" element={<OrderCreator/>} />}
                    {state.auth.type != 3 && <Route path="/catalogs/order/creator" element={<OrderCreatorAdd/>} />}
    
                    {/* cortes */}
                    <Route path="/cuts" element={<Cuts/>} />
                    <Route path="/cut/:id" element={<CutTickets/>} />
    
                    {/* ventas */}
                    <Route path="/sales" element={<Sales/>} />

                    {/* recibos/tickets */}
                    <Route path="/tickets" element={<Tickets/>} />

                    {/* ordenes */}
                    <Route path="/orders" element={<Orders/>} />
                    
                    {/* etiquetas de pasteles */}
                    <Route path="/cake-labels" element={<CakeLabels/>} />
    
                    {/* defaults */}
                    
                    {state.auth.type == 3 && <Route path="*" element={<Navigate to="/sales" replace />} />}
                    {state.auth.type != 3 && <Route path="*" element={<Navigate to="/" replace />} />}
    
                </Routes>}
                {!isOnline && isElectron() && <Routes>
                    {/* offline options electron*/}
                    <Route exact path="/" element={<OfflineIndicator />} />
                    <Route path="/sales" element={<Sales/>} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>}
                {!isOnline && !isElectron() && <Routes>
                    {/* offline options web*/}
                    <Route exact path="/" element={<OfflineIndicator />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>}
                </div>
            </div>
        </div>
    </main>
    // <main className="d-flex flex-nowrap" style={{"height": "100vh"}}>
    //     {isOnline && <Sidebar version={version} newVersion={newVersion} logout={onLogout} state={state}></Sidebar>}
    //     {!isOnline && <OfflineSidebar/> }
    //     <div className="container mt-3" style={{overflowY: "auto"}}>
    //         <div className="row">
    //             <div className="col-sm-12">
    //                 {isElectron() && <HashRouter>
    //                     <UrlHelper></UrlHelper>
    //                 </HashRouter>}
    //                 {!isElectron() && <Router>
    //                     <UrlHelper></UrlHelper>
    //                 </Router>}
    //             </div>
    //         </div>
    //     </div>
    // </main>
  );
}

export default Menu;