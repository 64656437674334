import { useContext, useState } from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import Spinner from "./Spinner";
import {acceptBookcakes, cancelBookcakes} from "../services/bookCakeServices";
import { AuthContext } from "../../context/Auth.context";
import { isElectron } from "../functions/isElectron";

function Calendar(props) {
    const firstDayOfMonth = new Date(props.day.getFullYear(), props.day.getMonth(), 1);
    const weekdayOfFirstDay = firstDayOfMonth.getDay();
    let currentDays = [];
    const { width } = useWindowDimensions();
    const [list, setList] = useState([]);

    const weekdays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    const weekdaysComplete = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
    
    const {state, CheckResponse } = useContext(AuthContext);

    const [modaldetails, setModalDetails] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [modalAccept, setModalAccept] = useState(false);

    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

    const [orderToCancel, setOrderToCancel] = useState({id: 0, showcase: false, name: ''});
    const [orderToAccept, setOrderToAccept] = useState({id: 0, showcase: false, name: '', remainingPayment: 0});

    const [daydetail, setDaydetail] = useState({
        index: 0,
        bookcakes: [],
        bookshowcasecakes: [],
        opencake: 0,
    });
    
    const showToast = (t, d, tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    const dateMonth = (date) => {
        return props.months[new Date(Date.parse(date)).getMonth()];
    }
    
    const dateDay = (date) => {
        return weekdaysComplete[new Date(Date.parse(date)).getDay()];
    }
    
    const dateDayNumber = (date) => {
        return new Date(Date.parse(date)).getDate();
    }

    const dateTime = (date) => {
        let newParsedate = Date.parse(date);
        let newDate = new Date(newParsedate);
        return ("0" + newDate.getHours()).slice(-2)  + ':' +  ("0" + newDate.getMinutes()).slice(-2);
    }

    for (let day = 0; day < 42; day++) {
        if (day === 0 && weekdayOfFirstDay === 0) {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
        } else if (day === 0) {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (day - weekdayOfFirstDay));
        } else {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        }

        let calendarDay = {
            currentMonth: (firstDayOfMonth.getMonth() === props.day.getMonth()),
            date: (new Date(firstDayOfMonth)),
            month: firstDayOfMonth.getMonth(),
            number: firstDayOfMonth.getDate(),
            selected: (firstDayOfMonth.toDateString() === props.day.toDateString() &&  props.startDate.getDay() === props.day.getDay() && props.startDate.getMonth() === props.day.getMonth() && props.startDate.getFullYear() === props.day.getFullYear()),
            year: firstDayOfMonth.getFullYear()
        }

        currentDays.push(calendarDay);
    }

    const showDetailsOnModal = (index) => {
        let hascakes = [];
        let hasshowcasecakes = [];

        let showModal = false;

        if (props.bookcakes[index]?.length > 0) {
            hascakes = props.bookcakes[index];
            showModal = true;
        }
        
        if (props.bookshowcasecakes[index]?.length > 0) {
            hasshowcasecakes = props.bookshowcasecakes[index];
            showModal = true;
        }
        if(showModal){
            setDaydetail({...daydetail,bookcakes: hascakes, bookshowcasecakes: hasshowcasecakes});
            setModalDetails(true);
        }
    }
    
    const toggleAcordion = (id) => {
        if(id === daydetail.opencake){
            setDaydetail({...daydetail,opencake: 0});
        }else{
            setDaydetail({...daydetail,opencake: id});
        }
    }

    const numberPrice = (n) => {
        return parseFloat(n).toFixed(2);
    }

    const prepareEditData = (data,showcase) => {
        setModalDetails(false);
        props.setEditData(data,showcase);
    }

    const dataOrderToCancel = (id, showcase, name) => {
        setModalDetails(false);
        setOrderToCancel({id: id, showcase: showcase, name: name});
        setModalCancel(true);
    }
    
    const dataOrderToAccept = (id, showcase, name, remainingPayment) => {
        setModalDetails(false);
        setOrderToAccept({id: id, showcase: showcase, name: name, remainingPayment: remainingPayment});
        setModalAccept(true);
    }

    const returnToDetails = () => {
        setModalCancel(false);
        setModalAccept(false);
        setModalDetails(true);
    }

    const cancelOrder = () => {
        setLoadingChangeStatus(true);
        cancelBookcakes(state.auth,orderToCancel).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                props.reload();
                setOrderToCancel({id: 0, showcase: false, name: ''});
                showToast('Hecho!','Pedido cancelado con exito!','success');
            } else {
                showToast('Oops!', 'Algo salio mal!', 'danger');
            }

            setLoadingChangeStatus(false);
        });
    }
    
    const acceptOrder = () => {
        let remainingPayment = orderToAccept.remainingPayment;
        setLoadingChangeStatus(true);
        acceptBookcakes(state.auth,orderToAccept).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                props.reload();
                setOrderToAccept({id: 0, showcase: false, name: '', remainingPayment: 0});
                showToast('Hecho!','Pedido entregado con exito!','success');
                props.paymenDeliveredOrder(remainingPayment);
            } else {
                showToast('Oops!', 'Algo salio mal!', 'danger');
            }

            setLoadingChangeStatus(false);
        });
    }

    const closeDetailsModal = () => {
        setModalDetails(false);
        toggleAcordion(0);
    }
    
    const prepareToPrint = (id,showcase) => {
        setModalDetails(false);
        toggleAcordion(0);
        props.sendToPrint(id,showcase);
    }

    const cakeArrayToString = (array) => {
        let first = true;
        let string = '';
        array.map(arr =>{
            if(first){first = false;}else{string+=', ';}
            string += arr.name;
        });
        return string;
    }

    return (
        <>
            <div className="calendar">
                <div className="calendar-body">
                    {width < 992 && <>
                        <div className="table-header mb-2">
                            <div className="weekday">
                                <span className="badge bg-success text-wrap ms-1 me-1">
                                    Ordenes
                                </span>
                            </div>
                            <div className="weekday">
                                <span className="badge bg-primary text-wrap ms-1 me-1">
                                    Apartados
                                </span>
                            </div>
                        </div>
                    </>}
                    <div className="table-header">
                        {
                            weekdays.map((weekday) => {
                                return <div key={weekday} className="weekday"><p>{weekday}</p></div>
                            })
                        }
                    </div>
                    <div className="table-content">
                        {
                            currentDays.map((day) => {
                                return (
                                    <div onClick={() => { showDetailsOnModal(day.year + '-' + day.month + '-' + day.number) }} key={day.year + '-' + day.month + '-' + day.number} className={"pb-2 calendar-day" + (day.currentMonth ? " current" : "") + (day.selected ? " selected" : "")}>
                                        <p>{day.number}</p> <br></br>
                                        {props.bookcakes[day.year + '-' + day.month + '-' + day.number]?.length > 0 &&
                                            <>
                                                {/* <span className="badge bg-success text-wrap ms-1 me-1">Ordenes de pastel: {props.bookcakes[day.year + '-' + day.month + '-' + day.number].length}</span> */}
                                                {width > 993 && <>
                                                    <span className="badge bg-success text-wrap ms-1 me-1">
                                                        Ordenes <span className="badge bg-danger">{props.bookcakes[day.year + '-' + day.month + '-' + day.number].length}</span>
                                                    </span>
                                                </>}
                                                {width < 992 && <>
                                                    <span className="badge bg-success text-wrap ms-1 me-1">
                                                        {props.bookcakes[day.year + '-' + day.month + '-' + day.number].length}
                                                    </span> <br></br>
                                                </>}
                                            </>
                                        }
                                        {props.bookshowcasecakes[day.year + '-' + day.month + '-' + day.number]?.length > 0 &&
                                            <>
                                                {/* <span className="badge bg-success text-wrap ms-1 me-1">Ordenes de pastel: {props.bookcakes[day.year + '-' + day.month + '-' + day.number].length}</span> */}
                                                {width > 993 && <>

                                                    <span className="badge bg-primary text-wrap ms-1 me-1">
                                                        Apartados <span className="badge bg-danger">{props.bookshowcasecakes[day.year + '-' + day.month + '-' + day.number].length}</span>
                                                    </span>
                                                </>}
                                                {width < 992 && <>
                                                    <span className="badge bg-primary text-wrap ms-1 me-1">
                                                        {props.bookshowcasecakes[day.year + '-' + day.month + '-' + day.number].length}
                                                    </span>
                                                </>}
                                            </>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {/* modal mostrar detalles */}
            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: modaldetails ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Pedidos del Dia</h1>
                            <button onClick={() => { closeDetailsModal() }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 mt-1">
                                        <h4>Ordenes</h4>
                                        <div className="accordion ">
                                            {daydetail.bookcakes.map((item, index) => 
                                                <div key={item.id} className="accordion-item">
                                                    <h2 onClick={() => {toggleAcordion('bk'+item.id)}} className="accordion-header">
                                                        <button className={`accordion-button ${daydetail.opencake == 'bk'+item.id ? 'show' : 'collapsed'}`} type="button">
                                                            {item.size} {item.size_option}
                                                        </button>
                                                    </h2>
                                                    <div className={`accordion-collapse collapse ${daydetail.opencake == 'bk'+item.id ? 'show' : ''}`}>
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-12 mt-1">
                                                                    <h6>Cliente</h6>
                                                                    <label>{item.customer_name}</label>
                                                                </div>
                                                                <div className="col-lg-3 col-sm-6 mt-1">
                                                                    <h6>Telefono</h6>
                                                                    <label>{item.customer_phone}</label>
                                                                </div>
                                                                <div className="col-lg-3 col-sm-6 mt-1">
                                                                    <h6>Fecha de Entrega</h6>
                                                                    <label> {dateDay(item.delivery_date)} {dateDayNumber(item.delivery_date)} de {dateMonth(item.delivery_date)} a las {dateTime(item.delivery_date)}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 mt-1">
                                                                    <h6>Tamaño</h6>
                                                                    <label>{item.size} {item.size_option}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-3 mt-1">
                                                                    <h6>Sabor {item.floors > 1 ? 'Piso 1': ''}</h6>
                                                                    <label>{item.flavor_1}</label>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3 mt-1">
                                                                    <h6>Relleno {item.floors > 1 ? 'Piso 1': ''}</h6>
                                                                    <label>{item.filling_1}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {item.floors > 1 && <>
                                                                    <div className="col-sm-6 col-lg-3 mt-1">
                                                                        <h6>Sabor {item.floors > 1 ? 'Piso 2': ''}</h6>
                                                                        <label>{item.flavor_2}</label>
                                                                    </div>
                                                                    <div className="col-sm-6 col-lg-3 mt-1">
                                                                        <h6>Relleno {item.floors > 1 ? 'Piso 2': ''}</h6>
                                                                        <label>{item.filling_2}</label>
                                                                    </div>
                                                                </>}
                                                                {item.floors > 2 && <>
                                                                    <div className="col-sm-6 col-lg-3 mt-1">
                                                                        <h6>Sabor {item.floors > 2 ? 'Piso 3': ''}</h6>
                                                                        <label>{item.flavor_3}</label>
                                                                    </div>
                                                                    <div className="col-sm-6 col-lg-3 mt-1">
                                                                        <h6>Relleno {item.floors > 2 ? 'Piso 3': ''}</h6>
                                                                        <label>{item.filling_3}</label>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                            <div className="row">
                                                                {item.sugar_print !== 'No' && <div className="col-sm-3 mt-1">
                                                                    <h6>Impresion de Azucar</h6>
                                                                    <label>{item.sugar_print}</label>
                                                                </div>}
                                                                {item.cake_topper_print !== 'No' &&<div className="col-sm-4 mt-1">
                                                                    <h6>Impresion Cake Topper</h6>
                                                                    <label>{item.cake_topper_print}</label>
                                                                </div>}
                                                                {item.box !== 'No' &&<div className="col-sm-2 mt-1">
                                                                    <h6>Caja</h6>
                                                                    <label>{item.box}</label>
                                                                </div>}
                                                                {item.decorated_base !== 'No' &&<div className="col-sm-3 mt-1">
                                                                    <h6>Base decorada</h6>
                                                                    <label>{item.decorated_base}</label>
                                                                </div>}
                                                            </div>
                                                            <div className="row">
                                                                {item.cake_message.length > 2 && <div className="col-sm-6 mt-1">
                                                                    <h6>Mensaje</h6>
                                                                    <label>{item.cake_message}</label>
                                                                </div>}
                                                                {item.desc.length > 2 && <div className="col-sm-6 mt-1">
                                                                    <h6>Descripcion</h6>
                                                                    <label>{item.desc}</label>
                                                                </div>}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Pago</h6>
                                                                    <label>{item.total}</label>
                                                                </div>
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Adelanto</h6>
                                                                    <label>{item.advance_payment}</label>
                                                                </div>
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Resta</h6>
                                                                    <label>{numberPrice((item.total - item.advance_payment))}</label>
                                                                </div>
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Quien hizo el pedido</h6>
                                                                    <label>{item.creator}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                 <div className="col-md-3 mt-4">
                                                                    {isElectron() &&<div className="d-grid gap-2">
                                                                        <button onClick={() => {prepareToPrint(item.id,false)}} className="btn btn-secondary btn-sm" type="button">Imprimir</button>
                                                                    </div>}
                                                                </div>
                                                                <div className="col-md-3 mt-4">
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => {prepareEditData(item,false)}} className="btn btn-primary btn-sm" type="button">Editar</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mt-4">
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => {dataOrderToCancel(item.id,false,item.customer_name)}} className="btn btn-danger btn-sm" type="button">Cancelar</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mt-4">
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => {dataOrderToAccept(item.id,false,item.customer_name)}} className="btn btn-success btn-sm" type="button">Entregar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <br></br>
                                        <h4>Apartados</h4>
                                        <div className="accordion">
                                            {daydetail.bookshowcasecakes.map((item, index) => 
                                                <div key={item.id} className="accordion-item">
                                                    <h2 onClick={() => {toggleAcordion('bsk'+item.id)}} className="accordion-header">
                                                        <button className={`accordion-button ${daydetail.opencake == 'bsk'+item.id ? 'show' : 'collapsed'}`} type="button">
                                                            {cakeArrayToString(item.showcase_cakes)}
                                                        </button>
                                                    </h2>
                                                    <div className={`accordion-collapse collapse ${daydetail.opencake == 'bsk'+item.id ? 'show' : ''}`}>
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-12 mt-1">
                                                                    <h6>Cliente</h6>
                                                                    <label>{item.customer_name}</label>
                                                                </div>
                                                                <div className="col-lg-3 col-sm-6 mt-1">
                                                                    <h6>Telefono</h6>
                                                                    <label>{item.customer_phone}</label>
                                                                </div>
                                                                <div className="col-lg-3 col-sm-6 mt-1">
                                                                    <h6>Fecha de Entrega</h6>
                                                                    <label> {dateDay(item.delivery_date)} {dateDayNumber(item.delivery_date)} de {dateMonth(item.delivery_date)} a las {dateTime(item.delivery_date)}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 mt-1">
                                                                    <h6>Lista de pasteles</h6>
                                                                    {item.showcase_cakes.map((cake,index) =>
                                                                        <div key={index}>
                                                                            <span  className="badge bg-primary"> 
                                                                                {cake.name}
                                                                            </span>
                                                                            <br></br>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Pago</h6>
                                                                    <label>{item.total}</label>
                                                                </div>
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Adelanto</h6>
                                                                    <label>{item.advance_payment}</label>
                                                                </div>
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Resta</h6>
                                                                    <label>{numberPrice((item.total - item.advance_payment))}</label>
                                                                </div>
                                                                <div className="col-sm-3 mt-1">
                                                                    <h6>Quien hizo el pedido</h6>
                                                                    <label>{item.creator}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3 mt-4">
                                                                     {isElectron() &&<div className="d-grid gap-2">
                                                                        <button onClick={() => {prepareToPrint(item.id,true)}} className="btn btn-secondary btn-sm" type="button">Imprimir</button>
                                                                    </div>}
                                                                </div>
                                                                <div className="col-md-3 mt-4">
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => {prepareEditData(item,true)}} className="btn btn-primary btn-sm" type="button">Editar</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mt-4">
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => {dataOrderToCancel(item.id,true,item.customer_name)}} className="btn btn-danger btn-sm" type="button">Cancelar</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mt-4">
                                                                    <div className="d-grid gap-2">
                                                                        <button onClick={() => {dataOrderToAccept(item.id,true,item.customer_name, (item.total - item.advance_payment))}} className="btn btn-success btn-sm" type="button">Entregar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { closeDetailsModal() }} className="btn btn-primary btn-sm" type="button">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* modal cancelar pedido */}
            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: modalCancel ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Cancelar pedido</h1>
                            <button onClick={() => { setModalCancel(false) }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                            {!loadingChangeStatus && <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 text-center mt-1">
                                        <h3>Seguro que quieres cancelar el pedido de {orderToCancel.name}?</h3>
                                    </div>
                                </div>
                            </div>}

                            {loadingChangeStatus && <Spinner size={8} ></Spinner> }
                            
                        </div>
                        {!loadingChangeStatus && <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { returnToDetails() }} className="btn btn-danger btn-sm" type="button">No</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { cancelOrder() }} className="btn btn-success btn-sm" type="button">Si</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
           
            {/* modal entregar pedido */}
            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: modalAccept ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Entregar pedido</h1>
                            <button onClick={() => { setModalAccept(false) }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                        {!loadingChangeStatus && <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 mt-1">
                                        <h3>Entregar el pedido de {orderToAccept.name}?</h3>
                                    </div>
                                </div>
                            </div>}
                            {loadingChangeStatus && <Spinner size={8} ></Spinner> }
                        </div>
                        {!loadingChangeStatus && <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { returnToDetails() }} className="btn btn-danger btn-sm" type="button">No</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { acceptOrder() }} className="btn btn-success btn-sm" type="button">Si</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calendar;