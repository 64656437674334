import React, { Fragment, useContext, useEffect, useState } from 'react';
import {getProduct,updateProduct,getCategories} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import Spinner from '../../helpers/Spinner';
import ImageUploading from 'react-images-uploading';
import { resizeImg } from '../../functions/resize';
import { AuthContext } from '../../../context/Auth.context';
import { useNavigate, useParams } from 'react-router-dom';

const Product = () => {
    let navigate = useNavigate();
    const [product, setProduct] = useState({
        id: '',
        name: '',
        price: '',
        price: 0,
        price_baker: 0,
        id_category: 0,
        taxes: false,
        is_a_cake: false,
        image: '',
        active: '',
        created_at: '',
        updated_at: ''
    });
    
    const [loading, setLoading] = useState(true);
    const [loadingC, setLoadingC] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [images, setImages] = useState([]);
    const [drag, setDrag] = useState(false);
    const {state, CheckResponse } = useContext(AuthContext);
    const {id} = useParams();
    const onChangeDrop = (imageList) => {
        resizeImg(imageList[0]['data_url']).then((base64) => {
            setProduct({...product, image: base64});
            setDrag(false);
            setImages(imageList);
        });

    };

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    useEffect(() => {
        getProduct(state.auth,id).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setProduct(data.product);
                getCategories(state.auth).then(data => {
                    CheckResponse(data);
                    if(data.code == 200){
                        setCategories(data.categories);
                    }else{
                        showToast('Oops!','Error al cargar las categorias!','danger');
                    }
                    setLoadingC(false);
                  });
            }else{
                showToast('Oops!','Error al cargar el producto!','danger');
            }
            setLoading(false);
          });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSaving(true);
        updateProduct(state.auth,product).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Guardado con exito!','success');
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setSaving(false);
          });
    }

    const dragging = (e) => {
        setDrag(true);
    }

    const preventDragHandler = (e) => {
        e.preventDefault();
    }

    return (
        <Fragment >
            <div className="row" >
                <div className="col-sm-12">
                    <h1>Producto</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <Toast toastList={list}/>
                    <form name="ProductForm" onSubmit={onSubmit} onDragOver={dragging}>
                        <div className="row">
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={e => setProduct({ ...product, name: e.target.value })}
                                    value={product.name}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="price">Precio</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="price"
                                    id="price"
                                    min="0" 
                                    step="0.05"
                                    onChange={e => setProduct({ ...product, price: e.target.value })}
                                    value={product.price}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="price">Precio Panadero</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="price"
                                    id="price"
                                    min="0" 
                                    step="0.05"
                                    onChange={e => setProduct({ ...product, price_baker: e.target.value })}
                                    value={product.price_baker}
                                    placeholder="Nombre"
                                />
                            </div>
                            
                            
                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="categorySelect">Categoria</label>
                                {!loadingC &&  <select id="categorySelect" className="form-control" value={product.id_category} onChange={e => setProduct({ ...product, id_category: e.target.value })}>
                                    <option value="0" disabled>Selecione...</option>
                                    {categories.map(item => 
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                     )}
                                </select>}
                                {loadingC && <Spinner></Spinner>}
                            </div>

                            <div className="col-sm-3 mt-3">
                                    <label className="form-check-label mb-3" htmlFor="taxes_switch">Impuestos</label>
                                    <div className="form-check form-switch">
                                        <input onChange={e => setProduct({ ...product, taxes: !product.taxes })} className="form-check-input" type="checkbox" id="taxes_switch" checked={product.taxes ? 'checked' : ''}></input>
                                    </div>
                            </div>
                            <div className="col-sm-3 mt-3">
                                    <label className="form-check-label mb-3" htmlFor="is_a_cake_switch">Es un pastel de vitrina?</label>
                                    <div className="form-check form-switch">
                                        <input onChange={e => setProduct({ ...product, is_a_cake: !product.is_a_cake })} className="form-check-input" type="checkbox" id="is_a_cake_switch" checked={product.is_a_cake ? 'checked' : ''}></input>
                                    </div>
                            </div>
                            <div className="col-sm-3 mt-3">
                                    <label className="form-check-label mb-3" htmlFor="flexSwitchCheckCheckedActive">Activo</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setProduct({ ...product, active: !product.active })} className="form-check-input" type="checkbox" id="flexSwitchCheckCheckedActive" checked={product.active ? 'checked' : ''}></input>
                                </div>
                            </div>

                            <div className="col-sm-3 mt-3">
                                <label className="form-label" htmlFor="name">Imagen</label>
                                <ImageUploading
                                    value={images}
                                    onChange={onChangeDrop}
                                    dataURLKey="data_url"
                                >
                                    {({
                                    onImageUpload,
                                    dragProps,
                                    }) => (
                                        <>
                                            {drag && <div onDrop={onImageUpload}  {...dragProps} className="container-fluid" style={{"background": "black", "position": "absolute", "top" : "0", "right" : "0", "height": "100vh", "opacity" : "0.7" ,  "zIndex" : "9000"}}>
                                                <div className="position-absolute top-50 start-50 translate-middle p-5" style={{"opacity": "1", "color": "white", "display": "flex", "justifyContent": "center", "border": "white", "borderStyle": "dashed"}}>
                                                    <i className="bi bi-download me-3" style={{"fontSize" : "2em"}}></i> 
                                                    <h1>Arrastra y suelta aqui</h1> 
                                                </div>
                                            </div>}
                                            <div className="d-grid gap-2">
                                                <button type="button" className="btn btn-secondary mb-2"
                                                    onClick={onImageUpload}
                                                    >
                                                        Seleccionar
                                                </button>
                                                <img onDragStart={(e) => {preventDragHandler(e)}} className="img-thumbnail mx-auto" src={product.image} alt="Imagen del producto"/>
                                            </div>
                                        
                                        </>
                                    )}
                                </ImageUploading>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving &&  <Spinner size={5} margin={4}></Spinner>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
            {loading &&  <Spinner size={8} margin={4}></Spinner>}
        </Fragment>
    );
}

export default Product;