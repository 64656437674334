import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/Auth.context.js';
import '.././styles/login.css';
import logo from '../assets/img/byrafaels.png';
import logoPonctium from '../assets/img/ponctium 512.png';
import { isElectron } from './functions/isElectron.js';

const initialState = {
    email: '',
    password: ''
}

export const LoginForm = () => {
    const { state: ContextState, login } = useContext(AuthContext);
    const {
        isLoginPending,
        isLoggedIn,
        loginError
    } = ContextState;

    const [state, setState] = useState(initialState);
    const [capslock, setcapslock] = useState(false);

    // console.log(state);
    const onSubmit = (e) => {
        e.preventDefault();
        const { email, password } = state;
        login(email, password);
        // setState({
        //     email: '',
        //     password: ''
        // });
    }

    const capslockVerification = (input) => {
        if (input.getModifierState("CapsLock")) {
            setcapslock(true);
        } else {
            setcapslock(false);
        }
    }

    const preventDragHandler = (e) => {
        e.preventDefault();
    }

    return (
        <div className="body">
            <div className="card">
                <div className='div-img'>
                    <img onDragStart={(e) => {preventDragHandler(e)}} className='img' src={logo} alt="Logo"></img>
                </div>
                
                <form name="loginForm" className='mb-4' autoComplete="off" onSubmit={onSubmit}>
        
                    <div className="row">
                        <div className="col-sm-12">
                            <b htmlFor="email">Usuario</b>
                            <input
                                className="form-control"
                                type="text"
                                name="email"
                                onChange={e => setState({ ...state, email: e.target.value })}
                                onKeyUp={e => capslockVerification(e)}
                                value={state.email}
                                placeholder="Usuario"
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <b htmlFor="password">Contraseña</b>
                            <input
                                className="form-control"
                                type="password"
                                name="password"
                                onChange={e => setState({ ...state, password: e.target.value })}
                                onKeyUp={e => capslockVerification(e)}
                                value={state.password}
                                placeholder="**********"
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-sm-12">
                            <div className="d-grid gap-2">
                                {!isLoginPending && <button className="btn btn-primary btn-bloc" type="submit"> Ingresar </button>}
                            </div>
                        </div>

                    </div>

                    {isLoginPending &&  <div className="d-flex justify-content-center mt-4">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>}
                    {loginError && <div className='mt-4 text-center'><b className='text-danger'>{loginError.message}</b></div>}
                    {capslock && <div className='mt-4 text-center'><b className='text-danger'>Mayúsculas activadas</b></div>}
                </form>
                <div className='div-img'>
                    <b>powered by</b> <img onDragStart={(e) => {preventDragHandler(e)}} className='imgPonct' src={logoPonctium} alt="Logo"></img>
                </div>
            </div>
            
            {!isElectron() && <div className='bottom'>
                Imagen de <a target='blank_' href="https://www.freepik.es/foto-gratis/mesa-cocina-panaderia-harina_2559745.htm/">Freepik</a>
            </div>}
        </div>
    )
}


export default LoginForm;