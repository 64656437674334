import { useContext, useEffect, useState } from "react";
import '../styles/calendar.css';
import { createNewOrder, getBookcakes, getNecesaryInfo, printBookcake, updateOrder } from "./services/bookCakeServices";
import { AuthContext } from '../context/Auth.context';
import Toast from "./helpers/Toast";
import Spinner from './helpers/Spinner';
import { isElectron } from './functions/isElectron';
import Calendar from "./helpers/Calendar";
import { FakeIpcRenderer } from "./helpers/FakeIpcRenderer";
import { getActualDate } from "./functions/getActualDate";
import { getPayments } from "./services/catalogServices";
import { getID } from "./functions/getID";
import { addTicket, getCurrentCut } from "./services/salesServices";
import { useNavigate } from "react-router-dom";

let ipcRenderer = FakeIpcRenderer;

if(isElectron()){
    ipcRenderer = window.require("electron").ipcRenderer;
}
   
const Orders = () => {
    let navigate = useNavigate();
    //modal de ventas
    const cartInitialValue = {productList:[], payment: 0, total: 0, discount:0, paymentMethod: 1, change:0, notes: '', split : false, payInDlls: false, paymentDlls: 0, changeDlls: 0, totalDlls: 0, partial: false, secondPaymentMethod : 0, secondPayment: 0, date:''};
    const [cart, setCart] = useState(cartInitialValue);
    const [modalPrint, setModalPrint] = useState(false);
    const [modalPayment, setModalPayment] = useState(false);
    const [tipoDeCambio, setTipoDeCamio] = useState(16);
    const [subtotal, setSubTotal] = useState(0);
    const [paymentList, setPaymentList] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(0);
    const [currentCut, setCurrentCut] = useState({});

    const numberPrice = (n) => {
        return parseFloat(n).toFixed(2);
        // return parseFloat(newN);
    }

    const turnDllsOn = () => {
        const newTP = tipoDeCambio > 0 ? parseFloat(tipoDeCambio) : 1;
        let newTotalDlls = subtotal/newTP;
        if(cart.discount > 0){
            newTotalDlls = newTotalDlls - ( newTotalDlls * (cart.discount / 100));
        }
        
        let changeDlls = parseFloat(cart.paymentDlls) - parseFloat(newTotalDlls);
        
        if(changeDlls < 0.10) changeDlls = 0;

        var change = (changeDlls * newTP);

        if(change < 0.50){
            change = 0;
        }

        setCart({...cart, totalDlls: newTotalDlls, changeDlls: changeDlls, change: change, payInDlls: true});
    }
    
    const changeDiscount = (d) => {
        let disc = d;
        if(d > 100) disc = 100;

        if(d < 0) disc = 0;
        setCart({...cart, discount :  disc});
    }

    // quitar poder cancelar el pago
    const cancelPayment = () => {
        setModalPayment(false);
        setCart({...cart, payment: 0, total: 0, discount:0, paymentMethod: defaultPaymentMethod, change:0, notes: '', split: false, payInDlls:false, paymentDlls: 0, changeDlls: 0, totalDlls: 0, partial: false, secondPaymentMethod : defaultPaymentMethod, secondPayment: 0});
    }

    const goToPrint = () => {
        const dateToCart =  getActualDate();

        if(cart.payInDlls){
            const newPayment = (tipoDeCambio * cart.paymentDlls);
            setCart({...cart, date: dateToCart, payment: newPayment});
        }else{
            setCart({...cart, date: dateToCart});
        }

        setModalPayment(false);
        setModalPrint(true);
    }

    const generateTicket = () => {
        console.log(cart);
        ipcRenderer.send("generate-ticket", JSON.stringify({data: cart, config: state.config}));
    };

    const openChasDrawer = () => {
        ipcRenderer.send("open-chash-drawer",JSON.stringify({data: cart, config: state.config}));
    };

    const checkCurrenntCut = () =>{
        getCurrentCut(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                // localStorage.setItem('saleitems',JSON.stringify(data));
                if(data.active){
                    setCurrentCut({...data.current});
                }else{
                    showToast('Oops!','No hay un corte!','danger');
                    navigate('/cuts');
                }
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
        });
    }
    
    //modal de ventas

    const cleanOrder = {
        id: 0,
        showcase: false,
        selected_showcasecake: '',
        selected_showcasecake_other: '',
        showcasecake: [],
        creator: '',
        customer_name: '',
        customer_phone: '',
        delivery_date: '',
        delivery_hour: '',
        size: '',
        size_other: '',
        floors: 0,
        size_option: '',
        size_option_other: '',
        size_options: [],
        flavor_1: '',
        flavor_1_other: '',
        filling_1: '',
        filling_1_other: '',
        flavor_2: '',
        flavor_2_other: '',
        filling_2: '',
        filling_2_other: '',
        flavor_3: '',
        flavor_3_other: '',
        filling_3: '',
        filling_3_other: '',
        sugar_print: 'No',
        cake_tupper_print: 'No',
        box: 'No',
        decorated_base: 'No',
        desc: '',
        message: '',
        total: 0,
        advance_payment: 0,
    };
    
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    
    const [actualDate, setActualDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [list, setList] = useState([]);
    const [bookcakes, setBookcakes] = useState([]);
    const [bookshowcasecakes, setBookshowcasecakes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [print, setPrint] = useState(false);
    const [printInfo, setPrintInfo] = useState({});
    const [modalNewOrder, setModalNewOrder] = useState(false);
    const [modalEditOrder, setModalEditOrder] = useState(false);
    const [newOrder, setNewOrder] = useState(cleanOrder);
    const [editOrder, setEditOrder] = useState(cleanOrder);
    const [oldOrder, setOldOrder] = useState(cleanOrder);
    const [necesaryInfo, setNecesaryInfo] = useState({
        sizes: [],
        flavors: [],
        fillings: [],
        showcasecakes: [],
        creators: [],
    });

    const {state, CheckResponse } = useContext(AuthContext);

    const nextDate = () => {
        setActualDate(new Date(actualDate.setMonth(actualDate.getMonth() + 1)));
    }

    const previousDate = () => {
        setActualDate(new Date(actualDate.setMonth(actualDate.getMonth() - 1)));
    }

    const loadNecesaryInfo = (tries = 0) => {
        setLoadingInfo(true);
        getNecesaryInfo(state.auth).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                setNecesaryInfo({
                    sizes: data.sizes,
                    flavors: data.flavors,
                    fillings: data.fillings,
                    showcasecakes: data.showcasecakes,
                    creators: data.creators,
                });
                setLoadingInfo(false);
            } else {
                if(tries < 3){
                    setTimeout(function(){
                        loadNecesaryInfo(tries+1);
                    },2000);
                }else{
                    showToast('Oops!', 'Algo salio mal!', 'danger');
                };
            }
        });
    }
    
    const loadPayments = (tries) =>{
        getPayments(state.auth).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                setPaymentList(data.payments);
                checkCurrenntCut();
            } else {
                if(tries < 3){
                    setTimeout(function(){
                        loadPayments(tries+1);
                    },2000);
                }else{
                    showToast('Oops!', 'Algo salio mal!', 'danger');
                }
            }
        });
    }

    const loadBookcakes = (tries = 0) => {
        setLoading(true);
        getBookcakes(state.auth,actualDate).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                setBookcakes(data.bookcakes);
                setBookshowcasecakes(data.bookshowcasecakes);
                setLoading(false);
                loadPayments(0);
            } else {
                if(tries < 3){
                    setTimeout(function(){
                        loadBookcakes(tries+1);
                    },2000);
                }else{
                    showToast('Oops!', 'Algo salio mal!', 'danger');
                }
            }
        });
    }

    useEffect(() => {
        loadNecesaryInfo();

        ipcRenderer.removeAllListeners("generate-ticket-reply");

        ipcRenderer.on("print-cake-ticket-reply", (event, args) => {
            if(args){
                showToast('Hecho!','informacion guardada con exito','success');
            }else{
                showToast('Oops!','No se pudo guardar la informacion!','danger');
            }
        });
    }, []);
    
    useEffect(() => {
        loadBookcakes();
        ipcRenderer.removeAllListeners("print-cake-ticket-reply");
        ipcRenderer.on("print-cake-ticket-reply", (event, arg) => {
            // console.log(arg);
        });
    }, [actualDate]);
    
    useEffect(() => {
        let newTotal = 0;
        cart.productList.map(product => {
            newTotal += product.qty * product.price;
        });
        setSubTotal(newTotal);
       
    }, [cart]);
   
    useEffect(() => {
        let newTotal = subtotal;
        
        if(subtotal > 0){

            if(cart.discount > 0){
                newTotal = newTotal - ( newTotal * (cart.discount / 100));
            }
    
            let change = (parseFloat(cart.payment) + parseFloat(parseFloat(cart.secondPayment))) -  parseFloat(newTotal);
    
            let newTP = tipoDeCambio > 0 ? (tipoDeCambio) : 1;

            if(change < 0) change = 0;
            let newTotalDlls = parseFloat((subtotal/newTP));
            
            if(cart.discount > 0){
                newTotalDlls = parseFloat((newTotalDlls - ( newTotalDlls * (cart.discount / 100))));
            }
            let secondPaymentDlls = 0;
    
            if(parseFloat(cart.secondPayment) > 0){
                secondPaymentDlls = parseFloat(cart.secondPayment)/newTP;
            }
            var changeDlls = parseFloat(((parseFloat(cart.paymentDlls) +  parseFloat(secondPaymentDlls)) -  parseFloat(newTotalDlls)));

            if(changeDlls < 0) changeDlls = 0;
    
            if(cart.payInDlls) change =  parseFloat(changeDlls) *  parseFloat(newTP);

            if(change < 0.50){
                change = 0;
            }
            if(changeDlls < 0.10){
                changeDlls = 0;
            }
            
            setCart({...cart, total: newTotal, totalDlls: newTotalDlls, changeDlls: changeDlls, change: change});
        }
        
    }, [cart.payment, cart.discount, parseFloat(cart.paymentDlls), tipoDeCambio, cart.payInDlls, parseFloat(cart.secondPayment), subtotal]);

    const showToast = (t, d, tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    const changeSize = (e) => {
        const idx = e.target.selectedIndex;
        const option = e.target.querySelectorAll('option')[idx];
        const floors = option.getAttribute('data-floors');
        const index = option.getAttribute('data-options');
        const options = necesaryInfo.sizes[index].options;
        setNewOrder({ ...newOrder, size: e.target.value, floors: floors, size_other: '', size_options: options });
    }

    const cancelOrder = () => {
        setModalNewOrder(false);
        setNewOrder(cleanOrder);
    }

    const procesOrder = () => {
        setLoadingSave(true);
        createNewOrder(state.auth,newOrder).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                setPrintInfo(data);
                loadBookcakes();
                setPrint(true);
            } else {
                showToast('Oops!', 'Algo salio mal!', 'danger');
            }
            setLoadingSave(false);
        });
    }

    const checkorder = () => {
        if (newOrder.customer_name?.length < 3) return false;

        if (newOrder.customer_phone?.length < 3) return false;
        if (newOrder.delivery_date?.length < 3) return false;
        if (newOrder.delivery_hour?.length < 3) return false;

        if (newOrder.size?.length < 3) {
            if (newOrder.size_other?.length < 3) {
                return false;
            }
        };

        if (newOrder.flavor_1?.length < 3) {
            if (newOrder.flavor_1_other?.length < 3) {
                return false;
            }
        }

        if (newOrder.filling_1?.length < 3) {
            if (newOrder.filling_1_other?.length < 3) {
                return false;
            }
        }

        if (newOrder.floors > 1) {
            if (newOrder.flavor_2?.length < 3) {
                if (newOrder.flavor_2_other?.length < 3) {
                    return false;
                }
            }

            if (newOrder.filling_2?.length < 3) {
                if (newOrder.filling_2_other?.length < 3) {
                    return false;
                }
            }
        }

        if (newOrder.floors > 2) {
            if (newOrder.flavor_3?.length < 3) {
                if (newOrder.flavor_3_other?.length < 3) {
                    return false;
                }
            }

            if (newOrder.filling_3?.length < 3) {
                if (newOrder.filling_3_other?.length < 3) {
                    return false;
                }
            }
        }

        if (newOrder.total < 1) return false;
        if (newOrder.advance_payment < 1) return false;

        return true;
    }

    const checkorderShowcase = () => {
        if (newOrder.customer_name?.length < 3) return false;

        if (newOrder.customer_phone?.length < 3) return false;
        if (newOrder.delivery_date?.length < 3) return false;
        if (newOrder.delivery_hour?.length < 3) return false;
        if (newOrder.creator?.length < 2) return false;

        if (newOrder.showcasecake?.length < 1) {
            return false;
        };

        if (newOrder.total < 1) return false;
        if (newOrder.advance_payment < 1) return false;

        return true;
    }
   
    const checkEdiOtrder = () => {
        if (editOrder.customer_name?.length < 3) return false;

        if (editOrder.customer_phone?.length < 3) return false;
        if (editOrder.delivery_date?.length < 3) return false;
        if (editOrder.delivery_hour?.length < 3) return false;

        if (editOrder.total < 1) return false;
        if (editOrder.advance_payment < 1) return false;

        return true;
    }

    const sendToPrint = (id,showcase) => {
        printBookcake(state.auth,id,showcase).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                sendDataToPrint(data);
            } else {
                showToast('Oops!', 'Algo salio mal!', 'danger');
            }
        });
    }

    const sendDataToPrint = (oldData) => {
        if(oldData.hasOwnProperty('code')){
            ipcRenderer.send("print-cake-ticket", oldData);
        }else{
            ipcRenderer.send("print-cake-ticket", printInfo);
        }
    };
    
    const printTicket = (printicket) => {

        if(printicket){
            setPrint(true);
            sendDataToPrint({});
        }else{
            setPrint(false);
        }

        setModalNewOrder(false);
        setPrint(false);
        setNewOrder(cleanOrder);

        var r = Math.random();
        let newId = (Math.floor(r * (1 - 1000) + 1000) * -1);

        let newCart = [];

        newCart.push({id: newId, name: 'Apartado Pastel', price: newOrder.advance_payment, qty: 1});

        setSubTotal(newOrder.advance_payment);

        setCart({...cart, productList: newCart});

        setModalPayment(true);
    }
   
    const paymenDeliveredOrder = (remainingPayment) => {
        var r = Math.random();
        let newId = (Math.floor(r * (1 - 1000) + 1000) * -1);

        let newCart = [];

        newCart.push({id: newId, name: 'Pago Apartado Pastel', price: remainingPayment, qty: 1});

        setSubTotal(remainingPayment);

        setCart({...cart, productList: newCart});

        setModalPayment(true);
    }
    
    const printTicketSales = (print) => {
        
        setModalPrint(false);
        
        if(print){
            generateTicket();
            // enviar a imprimir el ticket con el cart y la configuracion que se imprima antes de que se guarde, si algo sale mal que se agrege a los tickets locales
        }else{
            openChasDrawer();
        }

        setCart(cartInitialValue);
        
        
        const loadingListId = getID();
        setList([...list,{
            id: loadingListId,
            title: 'Guardando Recibo',
            description: '',
            type: "info",
            loading: true,
            short: true,
            delete: false
        }]);
        addTicket(state.auth,cart,currentCut,tipoDeCambio).then(data => {
            CheckResponse(data);
            if(data.code === 200){
                setCurrentCut({...data.cut});
                showToast('Hecho!','Recibo guardado!','success',3);
            }else if (data.code === 502){
                showToast('Oops!','No hay un corte!','danger');
                navigate('/cuts');
            }
        });
        
    }

    const setEditData = (data,showcase) =>{
        let delivery = new Date(Date.parse(data.delivery_date));
        let arrayCakes = [];

        if(data.showcase_cakes?.length > 1){
            data.showcase_cakes.map(item => {
                arrayCakes.push(item.name);
            });
        }else{
            arrayCakes = data.showcase_cakes;
        }

        setOldOrder({...oldOrder,
            id: data.id,
            showcasecake: arrayCakes,
            size: data?.size,
            size_option: data?.size_option,
            floors: data?.floors,
            flavor_1: data?.flavor_1,
            filling_1: data?.filling_1,
            flavor_2: data?.flavor_2,
            filling_2: data?.filling_2,
            flavor_3: data?.flavor_3,
            filling_3: data?.filling_3,
        });

        setEditOrder({
            ...editOrder,
            id: data.id,
            showcase: showcase,
            customer_name: data.customer_name,
            customer_phone: data.customer_phone,
            delivery_date: delivery.getFullYear()+'-'+("0" + (delivery.getMonth() + 1)).slice(-2)+'-'+("0" + delivery.getDate()).slice(-2),
            delivery_hour: ("0" + delivery.getHours()).slice(-2)+':'+("0" + delivery.getMinutes()).slice(-2),
            sugar_print: data.sugar_print,
            cake_tupper_print: data.cake_topper_print,
            box: data.box,
            decorated_base: data.decorated_base,
            desc: data.desc,
            message: data.cake_message,
            total: data.total,
            advance_payment: data.advance_payment,
        });

        setModalEditOrder(true);
    }

    const procesEdit = () => {
        setLoadingEdit(true);
        updateOrder(state.auth,editOrder).then(data => {
            CheckResponse(data);
            if (data.code == 200) {
                loadBookcakes();
                setPrint(true);
            } else {
                showToast('Oops!', 'Algo salio mal!', 'danger');
            }
            setLoadingEdit(false);
        });
    }

    const addCake = () => {
        let newCake = newOrder.showcasecake;

        if(newOrder.selected_showcasecake?.length > 2){
            newCake.push(newOrder.selected_showcasecake);
        }
        
        if(newOrder.selected_showcasecake_other?.length > 2){
            newCake.push(newOrder.selected_showcasecake_other);
        }
        
        setNewOrder({...newOrder,showcasecake: newCake, selected_showcasecake: '', selected_showcasecake_other: ''});
    }

    const deleteCake = (index) => {
        let newCake = newOrder.showcasecake;

        newCake.splice(index, 1);
        setNewOrder({...newOrder,showcasecake: newCake});
        
    }

    return (
        <div className="row" style={{width: '100%'}}>
            <Toast toastList={list} />
            <div className="row">
                <div className="col-sm-8 mt-1">
                    <div className="input-group">
                        <button onClick={() => {previousDate()}} className="btn btn-outline-secondary" type="button"><i className="bi bi-caret-left-fill"></i></button>
                        <span style={{minWidth: '140px'}} className="badge bg-primary fs-6 text-center pt-2">{months[actualDate.getMonth()]} {actualDate.getFullYear()}</span>
                        <button onClick={() => {nextDate()}} className="btn btn-outline-secondary" type="button"><i className="bi bi-caret-right-fill"></i></button>
                    </div>
                </div>
                <div className="col-sm-4 text-md-end mt-1">
                    {!loadingInfo && 
                        <button onClick={() => { setModalNewOrder(true) }} className="btn btn-success"><i className="bi bi-plus-circle"></i> Levantar Pedido</button>
                    }
                    {loadingInfo && <Spinner size={3}></Spinner>}
                </div>
            </div>

            {!loading && <div className="row mb-4 mt-4">
                <div className="col-sm-12">
                    <Calendar paymenDeliveredOrder={paymenDeliveredOrder} sendToPrint={sendToPrint} reload={loadBookcakes} setEditData={setEditData} months={months} day={actualDate} startDate={startDate} bookcakes={bookcakes} bookshowcasecakes={bookshowcasecakes}></Calendar>
                </div>
            </div>}

            {loading && <Spinner size={8} margin={5}></Spinner>}

            {/* modal nuevo pedido */}
            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: modalNewOrder ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Crear Pedido</h1>
                            <button onClick={() => { setModalNewOrder(false) }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {/* orden de pedido */}
                                {!loadingSave && !print && !newOrder.showcase && <>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 mt-1">
                                            <label className="form-label" htmlFor="name">Cliente</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, customer_name: e.target.value }) }} type="text" value={newOrder.customer_name} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Telefono</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, customer_phone: e.target.value }) }} type="text" value={newOrder.customer_phone} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Tipo</label> <br></br>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <button onClick={() => { setNewOrder({ ...newOrder, showcase: false }) }} type="button" className={`btn ${!newOrder.showcase ? 'btn-primary' : 'btn-outline-primary'}`}>Orden</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, showcase: true }) }} type="button" className={`btn ${newOrder.showcase ? 'btn-primary' : 'btn-outline-primary'}`}>Apartado</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Fecha de Entrega</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, delivery_date: e.target.value }) }} type="date" value={newOrder.delivery_date} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Hora de Entrega</label>
                                            <select className="form-control form-select-sm" value={newOrder.delivery_hour} onChange={(e) => { setNewOrder({ ...newOrder, delivery_hour: e.target.value }) }}>
                                                <option value="" disabled>Selecione...</option>
                                                <option value="09:00" >09:00 AM</option>
                                                <option value="09:30" >09:30 AM</option>
                                                <option value="10:00" >10:00 AM</option>
                                                <option value="10:30" >10:30 AM</option>
                                                <option value="11:00" >11:00 AM</option>
                                                <option value="11:30" >11:30 AM</option>
                                                <option value="12:00" >12:00 PM</option>
                                                <option value="12:30" >12:30 PM</option>
                                                <option value="01:00" >01:00 PM</option>
                                                <option value="01:30" >01:30 PM</option>
                                                <option value="02:00" >02:00 PM</option>
                                                <option value="02:30" >02:30 PM</option>
                                                <option value="03:00" >03:00 PM</option>
                                                <option value="03:30" >03:30 PM</option>
                                                <option value="04:00" >04:00 PM</option>
                                                <option value="04:30" >04:30 PM</option>
                                                <option value="05:00" >05:00 PM</option>
                                                <option value="05:30" >05:30 PM</option>
                                                <option value="06:00" >06:00 PM</option>
                                                
                                            </select>
                                        </div>
                                        {/* <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Hora de Entrega</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, delivery_hour: e.target.value }) }} type="time" value={newOrder.delivery_hour} className="form-control form-control-sm" />
                                        </div> */}
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Tamaño</label>
                                            <select className="form-control form-select-sm" value={newOrder.size} onChange={e => changeSize(e)} disabled={newOrder.size_other.length > 2 ? '1' : ''}>
                                                <option value="" disabled>Selecione...</option>
                                                {necesaryInfo.sizes?.map((item, index) =>
                                                    <option key={item.id} value={item.name} data-floors={item.floors} data-options={index}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Otro Tamaño</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, size_other: e.target.value, size: '', floors: 1 }) }} type="text" value={newOrder.size_other} className="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    {newOrder.size_options?.length > 0 && <>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Opciones de Tamaño</label>
                                                <select className="form-control form-select-sm" value={newOrder.size_option} onChange={e => { setNewOrder({ ...newOrder, size_option: e.target.value }) }} disabled={newOrder.size_option_other.length > 2 ? '1' : ''}>
                                                    <option value="">Ninguno</option>
                                                    {newOrder.size_options?.map((item, index) =>
                                                        <option key={item.id} value={item.name}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Otra Opcion de Tamaño</label>
                                                <input onChange={(e) => { setNewOrder({ ...newOrder, size_option_other: e.target.value, size_option: '' }) }} type="text" value={newOrder.size_option_other} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>}

                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Sabor {newOrder.floors > 1 ? 'Piso 1' : ''}</label>
                                            <select className="form-control form-select-sm" value={newOrder.flavor_1} onChange={e => { setNewOrder({ ...newOrder, flavor_1: e.target.value }) }} disabled={newOrder.flavor_1_other.length > 2 ? '1' : ''}>
                                                <option value="" disabled>Selecione...</option>
                                                {necesaryInfo.flavors?.map(item =>
                                                    <option key={item.id} value={item.name}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Otro Sabor {newOrder.floors > 1 ? 'Piso 1' : ''}</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, flavor_1_other: e.target.value, flavor_1: '' }) }} type="text" value={newOrder.flavor_1_other} className="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Relleno {newOrder.floors > 1 ? 'Piso 1' : ''}</label>
                                            <select className="form-control form-select-sm" value={newOrder.filling_1} onChange={e => { setNewOrder({ ...newOrder, filling_1: e.target.value }) }} disabled={newOrder.filling_1_other.length > 2 ? '1' : ''}>
                                                <option value="" disabled>Selecione...</option>
                                                {necesaryInfo.fillings?.map(item =>
                                                    <option key={item.id} value={item.name}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Otro Relleno {newOrder.floors > 1 ? 'Piso 1' : ''}</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, filling_1_other: e.target.value, filling_1: '' }) }} type="text" value={newOrder.filling_1_other} className="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    {/* sabor y relleno del piso 2 */}
                                    {newOrder.floors > 1 && <>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Sabor {newOrder.floors > 1 ? 'Piso 2' : ''}</label>
                                                <select className="form-control form-select-sm" value={newOrder.flavor_2} onChange={e => { setNewOrder({ ...newOrder, flavor_2: e.target.value }) }} disabled={newOrder.flavor_2_other.length > 2 ? '1' : ''}>
                                                    <option value="" disabled>Selecione...</option>
                                                    {necesaryInfo.flavors?.map(item =>
                                                        <option key={item.id} value={item.name}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Otro Sabor {newOrder.floors > 1 ? 'Piso 2' : ''}</label>
                                                <input onChange={(e) => { setNewOrder({ ...newOrder, flavor_2_other: e.target.value, flavor_2: '' }) }} type="text" value={newOrder.flavor_2_other} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Relleno {newOrder.floors > 1 ? 'Piso 2' : ''}</label>
                                                <select className="form-control form-select-sm" value={newOrder.filling_2} onChange={e => { setNewOrder({ ...newOrder, filling_2: e.target.value }) }} disabled={newOrder.filling_2_other.length > 2 ? '1' : ''}>
                                                    <option value="" disabled>Selecione...</option>
                                                    {necesaryInfo.fillings?.map(item =>
                                                        <option key={item.id} value={item.name}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Otro Relleno {newOrder.floors > 1 ? 'Piso 2' : ''}</label>
                                                <input onChange={(e) => { setNewOrder({ ...newOrder, filling_2_other: e.target.value, filling_2: '' }) }} type="text" value={newOrder.filling_2_other} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>}

                                    {/* sabor y relleno del piso 3 */}
                                    {newOrder.floors > 2 && <>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Sabor {newOrder.floors > 1 ? 'Piso 3' : ''}</label>
                                                <select className="form-control form-select-sm" value={newOrder.flavor_3} onChange={e => { setNewOrder({ ...newOrder, flavor_3: e.target.value }) }} disabled={newOrder.flavor_3_other.length > 2 ? '1' : ''}>
                                                    <option value="" disabled>Selecione...</option>
                                                    {necesaryInfo.flavors?.map(item =>
                                                        <option key={item.id} value={item.name}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Otro Sabor {newOrder.floors > 1 ? 'Piso 3' : ''}</label>
                                                <input onChange={(e) => { setNewOrder({ ...newOrder, flavor_3_other: e.target.value, flavor_3: '' }) }} type="text" value={newOrder.flavor_3_other} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Relleno {newOrder.floors > 1 ? 'Piso 3' : ''}</label>
                                                <select className="form-control form-select-sm" value={newOrder.filling_3} onChange={e => { setNewOrder({ ...newOrder, filling_3: e.target.value }) }} disabled={newOrder.filling_3_other.length > 2 ? '1' : ''}>
                                                    <option value="" disabled>Selecione...</option>
                                                    {necesaryInfo.fillings?.map(item =>
                                                        <option key={item.id} value={item.name}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <label className="form-label" htmlFor="name">Otro Relleno {newOrder.floors > 1 ? 'Piso 3' : ''}</label>
                                                <input onChange={(e) => { setNewOrder({ ...newOrder, filling_3_other: e.target.value, filling_3: '' }) }} type="text" value={newOrder.filling_3_other} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </>}

                                    <div className="row">
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Impresion de Azucar</label> <br></br>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <button onClick={() => { setNewOrder({ ...newOrder, sugar_print: 'No' }) }} type="button" className={`btn ${newOrder.sugar_print == 'No' ? 'btn-primary' : 'btn-outline-primary'}`}>No</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, sugar_print: 'Chica' }) }} type="button" className={`btn ${newOrder.sugar_print == 'Chica' ? 'btn-primary' : 'btn-outline-primary'}`}>Chica</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, sugar_print: 'Grande' }) }} type="button" className={`btn ${newOrder.sugar_print == 'Grande' ? 'btn-primary' : 'btn-outline-primary'}`}>Grande</button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Cake Topper</label> <br></br>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <button onClick={() => { setNewOrder({ ...newOrder, cake_tupper_print: 'No' }) }} type="button" className={`btn ${newOrder.cake_tupper_print == 'No' ? 'btn-primary' : 'btn-outline-primary'}`}>No</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, cake_tupper_print: 'Si' }) }} type="button" className={`btn ${newOrder.cake_tupper_print == 'Si' ? 'btn-primary' : 'btn-outline-primary'}`}>Si</button>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Caja</label> <br></br>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <button onClick={() => { setNewOrder({ ...newOrder, box: 'No' }) }} type="button" className={`btn ${newOrder.box == 'No' ? 'btn-primary' : 'btn-outline-primary'}`}>No</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, box: 'Si' }) }} type="button" className={`btn ${newOrder.box == 'Si' ? 'btn-primary' : 'btn-outline-primary'}`}>Si</button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Base Decorada</label> <br></br>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <button onClick={() => { setNewOrder({ ...newOrder, decorated_base: 'No' }) }} type="button" className={`btn ${newOrder.decorated_base == 'No' ? 'btn-primary' : 'btn-outline-primary'}`}>No</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, decorated_base: 'Si' }) }} type="button" className={`btn ${newOrder.decorated_base == 'Si' ? 'btn-primary' : 'btn-outline-primary'}`}>Si</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <label className="form-label" htmlFor="name">Mensaje del Pastel</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, message: e.target.value }) }} type="text" value={newOrder.message} className="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <label className="form-label" htmlFor="name">Descripcion</label>
                                            <textarea value={newOrder.desc} onChange={(e) => { setNewOrder({ ...newOrder, desc: e.target.value }) }} className="form-control" rows="3"></textarea>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Precio</label>
                                            <input type="number" value={newOrder.total} onChange={(e) => { setNewOrder({ ...newOrder, total: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Anticipo</label>
                                            <input type="number" value={newOrder.advance_payment} onChange={(e) => { setNewOrder({ ...newOrder, advance_payment: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Restante</label>
                                            <input type="number" value={newOrder.total - newOrder.advance_payment} className="form-control" disabled></input>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Quien hace el pedido</label>
                                            <select className="form-control form-select-sm" value={newOrder.creator}  onChange={(e) => { setNewOrder({ ...newOrder, creator: e.target.value }) }}>
                                                <option value="" disabled>Selecione...</option>
                                                {necesaryInfo.creators?.map((item,) =>
                                                    <option key={item.name} value={item.name}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </>}

                                {/* apartado de vitrina */}
                                {!loadingSave && !print && newOrder.showcase && <>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 mt-1">
                                            <label className="form-label" htmlFor="name">Cliente</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, customer_name: e.target.value }) }} type="text" value={newOrder.customer_name} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Telefono</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, customer_phone: e.target.value }) }} type="text" value={newOrder.customer_phone} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Tipo</label> <br></br>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <button onClick={() => { setNewOrder({ ...newOrder, showcase: false }) }} type="button" className={`btn ${!newOrder.showcase ? 'btn-primary' : 'btn-outline-primary'}`}>Orden</button>
                                                <button onClick={() => { setNewOrder({ ...newOrder, showcase: true }) }} type="button" className={`btn ${newOrder.showcase ? 'btn-primary' : 'btn-outline-primary'}`}>Apartado</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Fecha de Entrega</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, delivery_date: e.target.value }) }} type="date" value={newOrder.delivery_date} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Hora de Entrega</label>
                                            <select className="form-control form-select-sm" value={newOrder.delivery_hour} onChange={(e) => { setNewOrder({ ...newOrder, delivery_hour: e.target.value }) }}>
                                                <option value="" disabled>Selecione...</option>
                                                <option value="09:00" >09:00 AM</option>
                                                <option value="09:30" >09:30 AM</option>
                                                <option value="10:00" >10:00 AM</option>
                                                <option value="10:30" >10:30 AM</option>
                                                <option value="11:00" >11:00 AM</option>
                                                <option value="11:30" >11:30 AM</option>
                                                <option value="12:00" >12:00 PM</option>
                                                <option value="12:30" >12:30 PM</option>
                                                <option value="01:00" >01:00 PM</option>
                                                <option value="01:30" >01:30 PM</option>
                                                <option value="02:00" >02:00 PM</option>
                                                <option value="02:30" >02:30 PM</option>
                                                <option value="03:00" >03:00 PM</option>
                                                <option value="03:30" >03:30 PM</option>
                                                <option value="04:00" >04:00 PM</option>
                                                <option value="04:30" >04:30 PM</option>
                                                <option value="05:00" >05:00 PM</option>
                                                <option value="05:30" >05:30 PM</option>
                                                <option value="06:00" >06:00 PM</option>
                                                
                                            </select>
                                        </div>
                                        {/* <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Hora de Entrega</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, delivery_hour: e.target.value }) }} type="time" value={newOrder.delivery_hour} className="form-control form-control-sm" />
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4 mt-1">
                                            <label className="form-label" htmlFor="name">Pastel de Vitrina</label>
                                            <select className="form-control form-select-sm" value={newOrder.selected_showcasecake} onChange={e => { setNewOrder({ ...newOrder, selected_showcasecake: e.target.value }) }} disabled={newOrder.selected_showcasecake_other.length > 2 ? '1' : ''}>
                                                <option value="" disabled>Selecione...</option>
                                                {necesaryInfo.showcasecakes?.map(item =>
                                                    <option key={item.id} value={item.name}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6 col-lg-4 mt-1">
                                            <label className="form-label" htmlFor="name">Otro Pastel de Vitrina</label>
                                            <input onChange={(e) => { setNewOrder({ ...newOrder, selected_showcasecake_other: e.target.value, selected_showcasecake: '' }) }} type="text" value={newOrder.selected_showcasecake_other} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-md-12 col-lg-4 mt-3">
                                            <div className="d-grid gap-2 mt-1">
                                                <button onClick={() => { addCake() }} className="btn btn-success btn-sm mt-3"><i className="bi bi-plus-circle"></i> Agregar pastel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-sm-12 mt-1">
                                            <h5>Lista de pasteles</h5>
                                        </div>
                                        <div className="col-sm-12">
                                            {newOrder.showcasecake?.map((cake,index) => 
                                                <span key={index} className="badge bg-primary me-2"> 
                                                    {cake}<button onClick={() => {deleteCake(index)}} type="button" className="btn-close ms-2" aria-label="Close"></button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <label className="form-label" htmlFor="name">Descripcion</label>
                                            <textarea value={newOrder.desc} onChange={(e) => { setNewOrder({ ...newOrder, desc: e.target.value }) }} className="form-control" rows="3"></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Precio</label>
                                            <input type="number" value={newOrder.total} onChange={(e) => { setNewOrder({ ...newOrder, total: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Anticipo</label>
                                            <input type="number" value={newOrder.advance_payment} onChange={(e) => { setNewOrder({ ...newOrder, advance_payment: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Restante</label>
                                            <input type="number" value={newOrder.total - newOrder.advance_payment} className="form-control" disabled></input>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Quien hace el pedido</label>
                                            <select className="form-control form-select-sm" value={newOrder.creator}  onChange={(e) => { setNewOrder({ ...newOrder, creator: e.target.value }) }}>
                                                <option value="" disabled>Selecione...</option>
                                                {necesaryInfo.creators?.map((item,) =>
                                                    <option key={item.name} value={item.name}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </>}

                                {print && <>
                                    {!isElectron() && <>
                                        <div className="row ">
                                            <div className="col-sm-12 text-center mt-1">
                                                <h4>Solo se pueden imprimir las notas en la version de escritorio!</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 mt-1">
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => { printTicket(false) }} className="btn btn-success" type="button">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    {isElectron() && <>
                                        <div className="row ">
                                            <div className="col-sm-12 text-center mt-1">
                                                <h3>Imprimir Notas?</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => { printTicket(false) }} className="btn btn-danger" type="button">No</button>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => { printTicket(true) }} className="btn btn-success" type="button">Si</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </>}

                                {loadingSave && <Spinner size={8} margin={4} ></Spinner>}
                            </div>
                        </div>

                        {!loadingSave && !print && !newOrder.showcase && <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { cancelOrder() }} className="btn btn-danger btn-sm" type="button">Cancelar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { procesOrder() }} className={`btn btn-success btn-sm ${checkorder() ? '' : 'disabled'}`} type="button">Crear Pedido</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {!loadingSave && !print && newOrder.showcase && <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 mt-2">
                                        {/* <div className="d-grid gap-2">
                                            <button onClick={() => { cancelOrder() }} className="btn btn-danger btn-sm" type="button">Cancelar</button>
                                        </div> */}
                                    </div>
                                    <div className="col-md-8 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { procesOrder() }} className={`btn btn-success btn-sm ${checkorderShowcase() ? '' : 'disabled'}`} type="button">Crear Pedido</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
           
            {/* modal editar pedido */}
            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: modalEditOrder ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Editar Pedido</h1>
                            <button onClick={() => { setModalEditOrder(false) }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {/* orden de pedido */}
                                {!loadingEdit && !print && !editOrder.showcase && <>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 mt-1">
                                            <label className="form-label" htmlFor="name">Cliente</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, customer_name: e.target.value }) }} type="text" value={editOrder.customer_name} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Telefono</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, customer_phone: e.target.value }) }} type="text" value={editOrder.customer_phone} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Tipo</h6>
                                            {!editOrder.showcase && <label className="form-label">Orden</label>}
                                            {editOrder.showcase && <label className="form-label">Apartado</label>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Fecha de Entrega</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, delivery_date: e.target.value }) }} type="date" value={editOrder.delivery_date} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Hora de Entrega</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, delivery_hour: e.target.value }) }} type="time" value={editOrder.delivery_hour} className="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <h6>Tamaño</h6>
                                            <label className="form-label">{oldOrder?.size} {oldOrder?.size_option}</label> <br></br>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Sabor {oldOrder.floors > 1 ? 'Piso 1' : ''}</h6>
                                            <label className="form-label">{oldOrder?.flavor_1}</label>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Relleno {oldOrder.floors > 1 ? 'Piso 1' : ''}</h6>
                                            <label className="form-label">{oldOrder?.filling_1}</label>
                                        </div>
                                        {/* sabor y relleno del piso 2 */}
                                        {oldOrder.floors > 1 && <>
                                            
                                            <div className="col-sm-6 col-lg-3 mt-1">
                                                <h6>Sabor {oldOrder.floors > 1 ? 'Piso 2' : ''}</h6>
                                                <label className="form-label">{oldOrder?.flavor_2}</label>
                                            </div>
                                            <div className="col-sm-6 col-lg-3 mt-1">
                                                <h6>Relleno {oldOrder.floors > 1 ? 'Piso 2' : ''}</h6>
                                                <label className="form-label">{oldOrder?.filling_2}</label>
                                            </div>
                                        </>}
                                        {/* sabor y relleno del piso 3 */}
                                        {oldOrder.floors > 2 && <>
                                            <div className="col-sm-6 col-lg-3 mt-1">
                                                <h6>Sabor {oldOrder.floors > 2 ? 'Piso 3' : ''}</h6>
                                                <label className="form-label">{oldOrder?.flavor_3}</label>
                                            </div>
                                            <div className="col-sm-6 col-lg-3 mt-1">
                                                <h6>Relleno {oldOrder.floors > 2 ? 'Piso 3' : ''}</h6>
                                                <label className="form-label">{oldOrder?.filling_3}</label>
                                            </div>
                                        </>}
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Impresion de Azucar</h6>
                                            <label className="form-label">{oldOrder.sugar_print }</label>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Cake Topper</h6>
                                            <label className="form-label">{oldOrder.cake_tupper_print }</label>
                                        </div>

                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Caja</h6>
                                            <label className="form-label">{oldOrder.box}</label>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <h6>Base Decorada</h6>
                                            <label className="form-label">{oldOrder.decorated_base}</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <label className="form-label" htmlFor="name">Mensaje del Pastel</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, message: e.target.value }) }} type="text" value={editOrder.message} className="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <label className="form-label" htmlFor="name">Descripcion</label>
                                            <textarea value={editOrder.desc} onChange={(e) => { setEditOrder({ ...editOrder, desc: e.target.value }) }} className="form-control" rows="3"></textarea>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Precio</label>
                                            <input type="number" value={editOrder.total} onChange={(e) => { setEditOrder({ ...editOrder, total: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Anticipo</label>
                                            <input type="number" value={editOrder.advance_payment} onChange={(e) => { setEditOrder({ ...editOrder, advance_payment: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Restante</label>
                                            <input type="number" value={editOrder.total - editOrder.advance_payment} className="form-control" disabled></input>
                                        </div>
                                    </div>
                                </>}

                                {/* apartado de vitrina */}
                                {!loadingEdit && !print && editOrder.showcase && <>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 mt-1">
                                            <label className="form-label" htmlFor="name">Cliente</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, customer_name: e.target.value }) }} type="text" value={editOrder.customer_name} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Telefono</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, customer_phone: e.target.value }) }} type="text" value={editOrder.customer_phone} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mt-1">
                                            <label className="form-label" htmlFor="name">Tipo</label> <br></br>
                                            {!editOrder.showcase && <h6>Orden</h6>}
                                            {editOrder.showcase && <h6>Apartado</h6>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Fecha de Entrega</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, delivery_date: e.target.value }) }} type="date" value={editOrder.delivery_date} className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <label className="form-label" htmlFor="name">Hora de Entrega</label>
                                            <input onChange={(e) => { setEditOrder({ ...editOrder, delivery_hour: e.target.value }) }} type="time" value={editOrder.delivery_hour} className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 mt-2">
                                            <h6>Lista de pasteles</h6>
                                            {oldOrder.showcasecake?.map((cake,index) => 
                                                <span key={index} className="badge bg-primary me-2"> 
                                                    {cake}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Precio</label>
                                            <input type="number" value={editOrder.total} onChange={(e) => { setEditOrder({ ...editOrder, total: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Anticipo</label>
                                            <input type="number" value={editOrder.advance_payment} onChange={(e) => { setEditOrder({ ...editOrder, advance_payment: e.target.value }) }} className="form-control"></input>
                                        </div>
                                        <div className="col-sm-4 mt-1">
                                            <label className="form-label" htmlFor="name">Restante</label>
                                            <input type="number" value={editOrder.total - editOrder.advance_payment} className="form-control" disabled></input>
                                        </div>
                                    </div>
                                </>}

                                {print && <>
                                    {!isElectron() && <>
                                        <div className="row ">
                                            <div className="col-sm-12 text-center mt-1">
                                                <h4>Solo se pueden imprimir en la version de escritorio!</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 mt-1">
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => { printTicket(false) }} className="btn btn-success" type="button">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    {isElectron() && <>
                                        <div className="row ">
                                            <div className="col-sm-12 text-center mt-1">
                                                <h3>Imprimir?</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-1">
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => { printTicket(false) }} className="btn btn-danger" type="button">No</button>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <div className="d-grid gap-2">
                                                    <button onClick={() => { printTicket(true) }} className="btn btn-success" type="button">Si</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </>}

                                {loadingEdit && <Spinner size={8} margin={4} ></Spinner>}
                            </div>
                        </div>

                        {!loadingEdit && !print && <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { setModalEditOrder(false) }} className="btn btn-danger btn-sm" type="button">Cancelar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { procesEdit() }} className={`btn btn-success btn-sm ${checkEdiOtrder() ? '' : 'disabled'}`} type="button">Editar Pedido</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            
            {/* seccion para abrir modal de pago */}
            {/* modal de pago */}
            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalPayment ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">Pagar</h1>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 text-wrap text-center">
                                        <h3>Subtotal: ${numberPrice(subtotal)} {cart.payInDlls && <>/ Subtotal Dlls: ${numberPrice(subtotal/(tipoDeCambio > 0 ? tipoDeCambio : 1))}</>}</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    {/* <div className="col-sm-4">
                                        <label className="form-label">Pago Parcial</label> <br></br>
                                        <button onClick={() => {setCart({...cart, partial: false})}} className={`btn btn ${!cart.partial ? 'btn-primary' : 'btn-outline-primary'}`} style={{width: "45%"}} type="button">No</button>
                                        <button onClick={() => {setCart({...cart, partial: true})}} className={`btn ${cart.partial ? 'btn-success' : 'btn btn-outline-success'}  ms-2`} style={{width: "45%"}} type="button">Si</button>
                                    </div> */}
                                    <div className="col-sm-4">
                                        <label className="form-label">Pago en dlls</label> <br></br>
                                        <button onClick={() => {setCart({...cart, payInDlls: false})}} className={`btn btn ${!cart.payInDlls ? 'btn-primary' : 'btn-outline-primary'}`} style={{width: "45%"}} type="button">No</button>
                                        <button onClick={() => {turnDllsOn()}} className={`btn ${cart.payInDlls ? 'btn-success' : 'btn btn-outline-success'}  ms-2`} style={{width: "45%"}} type="button">Si</button>
                                    </div>
                                    {cart.payInDlls && <>
                                        <div className="col-sm-4">
                                            <label className="form-label">Tipo de Camio</label>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">$</span>
                                                <input onChange={(e) => { setTipoDeCamio(e.target.value)}} type="number" value={tipoDeCambio} className="form-control" />
                                            </div>
                                        </div>
                                    </>}
                                   
                                </div>
                                
                                <div className="row mt-2">
                                    <div className="col-sm-4 text-wrap">
                                        <label className="form-label">Metodo de Pago</label>
                                        <select className="form-control" value={cart.paymentMethod} onChange={e => setCart({ ...cart, paymentMethod: parseInt(e.target.value) })}>
                                            <option value="0" disabled>Selecione...</option>
                                            {paymentList.map(item => 
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Dividir pago</label> <br></br>
                                        <button onClick={() => {setCart({...cart, split: false, secondPayment: 0})}} className={`btn btn ${!cart.split ? 'btn-primary' : 'btn-outline-primary'}`} style={{width: "45%"}} type="button">No</button>
                                        <button onClick={() => {setCart({...cart, split: true, secondPayment: 0})}} className={`btn ${cart.split ? 'btn-success' : 'btn btn-outline-success'}  ms-2`} style={{width: "45%"}} type="button">Si</button>
                                    </div>
                                    <div className="col-sm-4 text-wrap">
                                        <label className="form-label">Descuento</label>
                                        <div className="input-group input-group-sm mb-3">
                                            <input max={100}  onChange={(e) => {changeDiscount(e.target.value)}} type="number" value={cart.discount} className="form-control" />
                                            <span onClick={() => {setCart({...cart, discount : 0})}} className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* totales en pesos */}
                                {!cart.payInDlls && <div className="row">
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Descuento</h5>
                                        <h5 className='mt-3'>${cart.discount > 0 ? numberPrice(( subtotal * (cart.discount / 100))) : 0}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Total</h5>
                                        <h5 className='mt-3'>${numberPrice(cart.total)}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Pago</h5>
                                        <div className="input-group input-group-sm mb-3">
                                            <span onClick={() => {setCart({...cart, payment : 0})}} className="input-group-text">$</span>
                                            <input min={0}  onChange={(e) => {setCart({...cart, payment : e.target.value})}} type="number" value={cart.payment} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Cambio</h5>
                                        <h5 className='mt-3'>${cart.change > 0 ? numberPrice(cart.change) : 0}</h5> 
                                    </div>
                                </div>}
                                
                                {/* totales en dlls */}
                                {cart.payInDlls && <div className="row">
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Descuento</h5>
                                        <h5 className='mt-3'>${cart.discount > 0 ? numberPrice(( (subtotal / (tipoDeCambio > 0 ? tipoDeCambio : 1)) * (cart.discount / 100))) : 0}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Total</h5>
                                        <h5 className='mt-3'>${tipoDeCambio > 0 ? numberPrice(cart.totalDlls) : 0}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Pago</h5>
                                        <div className="input-group input-group-sm mb-3">
                                            <span className="input-group-text">$</span>
                                            <input onMouseDown={() => {setCart({...cart, paymentDlls : 0})}} onChange={(e) => {setCart({...cart, paymentDlls : e.target.value})}} type="number" value={parseFloat(cart.paymentDlls)} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Cambio</h5>
                                        <h5 className='mt-3'>${cart.changeDlls > 0 ? numberPrice(cart.changeDlls) : 0} Dlls o ${cart.change > 0 ? numberPrice(cart.change) : 0}</h5> 
                                    </div>
                                </div>}
                                
                                {cart.split && <div className='row mt-1'>
                                    <div className="col-sm-6 text-wrap">
                                        <label className="form-label">Segundo Metodo de Pago</label>
                                        <select className="form-control" value={cart.secondPaymentMethod} onChange={e => setCart({ ...cart, secondPaymentMethod: e.target.value })}>
                                            <option value="0" disabled>Selecione...</option>
                                            {paymentList.map(item => 
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-6 text-wrap text-center">
                                        <label>Segundo Pago en Pesos</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input onMouseDown={() => {setCart({...cart, secondPayment : 0})}} onChange={(e) => {setCart({...cart, secondPayment : e.target.value})}} type="number" value={parseFloat(cart.secondPayment)} min={0} className="form-control" />
                                        </div>
                                    </div>
                                </div>}

                                {/* dinero en pesos*/}
                                {!cart.payInDlls && <div className="row mt-2">
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (subtotal + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">{numberPrice(subtotal)}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (5 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">5</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (10 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">10</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (20 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">20</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (50 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">50</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (100 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">100</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (200 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">200</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (500 + parseFloat(cart.payment > 0 ? cart.payment : 0))})}} className="btn btn-primary btn-lg" type="button">500</button>
                                        </div>
                                    </div>
                                </div>}
                                
                                {/* dinero en dlls*/}
                                {cart.payInDlls && <div className="row mt-2">
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: numberPrice(((subtotal / (tipoDeCambio > 0 ? tipoDeCambio : 1)) + parseFloat(cart.paymentDlls)))})}} className="btn btn-success btn-lg" type="button">{numberPrice(subtotal / (tipoDeCambio > 0 ? tipoDeCambio : 1))}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (1 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">1</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (2 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">2</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (5 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">5</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (10 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">10</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (20 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">20</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (50 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">50</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (100 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">100</button>
                                        </div>
                                    </div>
                                </div>}

                                <div className="row">
                                    <div className="col-sm-12 text-wrap mt-2">
                                    <label className="form-label">Notas (opcional)</label>
                                        <input onChange={(e) => {setCart({...cart,notes: e.target.value})}} type="text" value={cart.notes} className="form-control" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        {/* <div className="d-grid gap-2">
                                            <button onClick={() => {cancelPayment()}} className="btn btn-danger" type="button">Cancelar</button>
                                        </div> */}
                                    </div>
                                    <div className="col-md-8 ms-auto">
                                        <div className="d-grid gap-2">
                                            {/* pagar pesos */}
                                            {!cart.payInDlls && <button onClick={() => {goToPrint()}} className={`btn btn-success ${(((cart.payment + parseFloat(cart.secondPayment)) >= cart.total || (cart.partial & (cart.payment +  parseFloat(cart.secondPayment)) > 0)) && cart.paymentMethod != 0) ? '' : 'disabled'}`} type="button">Cobrar</button>}
                                            {/* pagar dlls */}
                                            {cart.payInDlls && <button onClick={() => {goToPrint()}} className={`btn btn-success ${(((parseFloat(cart.paymentDlls) + (parseFloat(cart.secondPayment) > 0 ? (parseFloat(cart.secondPayment) / (tipoDeCambio > 0 ? tipoDeCambio : 1)): 0)) >= cart.totalDlls || (cart.partial & (parseFloat(cart.paymentDlls) + (parseFloat(cart.secondPayment) > 0 ? (parseFloat(cart.secondPayment) / parseFloat(tipoDeCambio > 0 ? tipoDeCambio : 1)): 0)) > 0)) && cart.paymentMethod != 0) ? '' : 'disabled'}`} type="button">Cobrar</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* modal imprimir */}
            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalPrint ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Imprimir ticket</h1>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {!isElectron() && <>
                                    <div className="row ">
                                        <div className="col-sm-12 text-center mt-1">
                                            <h4>Solo se pueden imprimir en la version de escritorio!</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <div className="d-grid gap-2">
                                                <button onClick={() => { printTicketSales(false) }} className="btn btn-success" type="button">Terminar</button>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                {isElectron() && <>
                                    <div className="row ">
                                        <div className="col-sm-12 text-center mt-1">
                                            <h3>Imprimir Ticket?</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <div className="d-grid gap-2">
                                                <button onClick={() => { printTicketSales(false) }} className="btn btn-danger" type="button">No</button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <div className="d-grid gap-2">
                                                <button onClick={() => { printTicketSales(true) }} className="btn btn-success" type="button">Si</button>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Orders;