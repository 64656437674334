export const backend = "https://api-byrafaels.ponctium.com/api/";
// export const backend = "http://192.168.1.20/ponctium-backend/public/api/";

export const version = '1.0.8';

export const moneyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
});

export const moneyFormatDecimals = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
});