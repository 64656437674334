import { memo } from "react";
import Image from "./Image";
const Product = props => {
    const { product, add } = props;

    const numerPrice = (n) => {
        return parseFloat(n).toFixed(2);
    }

    return (
        <div onClick={() => {add(product)}} className="col-sm-2 ms-2 mt-2 badge text-wrap text-center product-cart" >
            <Image alt={product.name} src={product.image} ></Image>
            <p className="mt-1">  
                {product.name}
            </p>
            <p style={{marginBottom: "2px"}}>  
                ${numerPrice(product.price)}
            </p>
        </div>                
    );
}

export default memo(Product);