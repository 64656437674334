import React, {useContext, useState } from 'react';
import {addFilling} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth.context';
import Spinner from '../../helpers/Spinner';

const FillingAdd = () => {
    let navigate = useNavigate();

    const [filling, setFilling] = useState({
        id: '',
        name: '',
        active: '',
        created_at: '',
        updated_at: ''
    });
    
    // const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const { state, CheckResponse } = useContext(AuthContext);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(filling.name.length > 2){
            setSaving(true);
            addFilling(state.auth,filling).then(data => {
                CheckResponse(data);
                if(data.code == 200){
                    showToast('Hecho!','Guardado con exito, Redireccionando a otra pagina!','success');
                    setTimeout(function() {
                        navigate('/catalogs/filling/'+data.id);
                      }, 5000);
                }else{
                    showToast('Oops!','Algo salio mal!','danger');
                }
                setSaving(false);
              });
        }else{
            showToast('Oops!','El nombre debe tener al menos 3 caracteres!', 'danger');
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Agregar Relleno</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {<div className="row">
                <div className="col-sm-12">
                    <Toast toastList={list}/>
                    <form name="loginForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-4">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={e => setFilling({ ...filling, name: e.target.value })}
                                    value={filling.name}
                                    placeholder="Nombre"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving && <Spinner size={5} margin={4} ></Spinner>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>} 
            
        </>
    );
}

export default FillingAdd;