const OfflineSidebar = () => {

    return (
        <div className="d-flex flex-column flex-shrink-0 bg-light" style={{ "width": " 4.5rem", "height": "100vh", "minHeight":"100%" }}>
            <a className="ms-1 d-block p-3 link-dark text-decoration-none" style={{ "fontSize": " 2em", "marginBottom" : "-10px"}}>
                <i style={{"fontSize" : `1em`}} className="bi bi-wifi-off"></i>
            </a>
        </div>
    );
}

export default OfflineSidebar;