import { backend } from "../Constants";

export async function getVersion(ctx,version) {
    return await fetch(backend + 'version/'+version, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        console.log(err);
        return'Error en el servidor';
    });
}

export async function getVersions(ctx,rows = 10, page = 1) {
    return await fetch(backend + 'versions/'+rows+'?page='+page, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        console.log(err);
        return'Error en el servidor';
    });
}