import { backend } from "../Constants";

export async function getNecesaryInfo(ctx) {
    return await fetch(backend + 'bookcakeinfo', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        return'Error en el servidor';
    });
}

export async function createNewOrder(ctx,data) {
    return await fetch(backend+'bookcake', {
        method: 'POST',
        body: JSON.stringify({
            showcase: data.showcase,
            showcasecake: data.showcasecake,
            customer_name: data.customer_name,
            creator: data.creator,
            customer_phone: data.customer_phone,
            delivery_date: data.delivery_date,
            delivery_hour: data.delivery_hour,
            size: data.size,
            size_other: data.size_other,
            floors: data.floors,
            size_option: data.size_option,
            size_option_other: data.size_option_other,
            flavor_1: data.flavor_1,
            flavor_1_other: data.flavor_1_other,
            filling_1: data.filling_1,
            filling_1_other: data.filling_1_other,
            flavor_2: data.flavor_2,
            flavor_2_other: data.flavor_2_other,
            filling_2: data.filling_2,
            filling_2_other: data.filling_2_other,
            flavor_3: data.flavor_3,
            flavor_3_other: data.flavor_3_other,
            filling_3: data.filling_3,
            filling_3_other: data.filling_3_other,
            sugar_print: data.sugar_print,
            cake_tupper_print: data.cake_tupper_print, 
            box: data.box, 
            decorated_base: data.decorated_base, 
            desc: data.desc, 
            message: data.message, 
            total: data.total, 
            advance_payment: data.advance_payment, 
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function updateOrder(ctx,data) {
    return await fetch(backend+'bookcake/'+data.id, {
        method: 'PUT',
        body: JSON.stringify({
            showcase: data.showcase,
            customer: data.customer,
            customer_phone: data.customer_phone,
            delivery_date: data.delivery_date,
            delivery_hour: data.delivery_hour,
            desc: data.desc, 
            message: data.message, 
            total: data.total, 
            advance_payment: data.advance_payment, 
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}

export async function getBookcakes(ctx,actualDate) {
    return await fetch(backend + 'bookcakes/'+actualDate.getFullYear()+'/'+(actualDate.getMonth() + 1), {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        console.log(err);
        return'Error en el servidor';
    });
}

export async function acceptBookcakes(ctx,data) {
    return await fetch(backend + 'bookcake/accept/'+data.id+'/'+data.showcase, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        console.log(err);
        return'Error en el servidor';
    });
}

export async function cancelBookcakes(ctx,data) {
    return await fetch(backend + 'bookcake/cancel/'+data.id+'/'+data.showcase, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        console.log(err);
        return'Error en el servidor';
    });
}

export async function printBookcake(ctx,id,showcase) {
    return await fetch(backend + 'bookcake/'+id+'/'+showcase, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
    })
    .then(data => data.json())
    .catch((err) => {
        console.log(err);
        return'Error en el servidor';
    });
}