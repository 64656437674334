export function getActualDate() {
    const date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();

    let h = date.getHours();
    let i = date.getMinutes();
    let s = date.getSeconds();

    if(m < 10){m = `0${m}`;}
    if(d < 10){d = `0${d}`;}
    if(h < 10){h = `0${h}`;}
    if(i < 10){i = `0${i}`;}
    if(s < 10){s = `0${s}`;}
    return `${y}-${m}-${d} ${h}:${i}:${s}`;
    
}