import React, {useContext, useState } from 'react';
import {addUser} from '../services/catalogServices';
import Toast from '../helpers/Toast';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth.context';
import Spinner from '../helpers/Spinner';

const UserAdd = () => {
    let navigate = useNavigate();

    const [user, setUser] = useState({
        id: '',
        name: null,
        login: null,
        type: 0,
        password: null,
    });
    
    console.log(user);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const { state, CheckResponse } = useContext(AuthContext);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let error = false;


        if(user.name.length < 3){
            error = true;
            showToast('Oops!','El nombre debe tener al menos 3 caracteres!', 'danger');
        }
        
        if(user.password.length < 3){
            error = true;
            showToast('Oops!','La contraseña debe tener al menos 3 caracteres!', 'danger');
        }
        
        if(user.login.length < 3){
            error = true;
            showToast('Oops!','El login debe tener al menos 3 caracteres!', 'danger');
        }
        
        if(user.type === 0){
            error = true;
            showToast('Oops!','Se debe seleccionar un tipo de usuario!', 'danger');
        }

        if(!error){
            setSaving(true);
            addUser(state.auth,user).then(data => {
                CheckResponse(data);
                if(data.code == 200){
                    showToast('Hecho!','Guardado con exito, Redireccionando a otra pagina!','success');
                    setTimeout(function() {
                        navigate('/user/'+data.id);
                      }, 5000);
                }else{
                    showToast('Oops!','Algo salio mal!','danger');
                }
                setSaving(false);
            });
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Agregar Usuario</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {<div className="row">
                <div className="col-sm-12">
                    <Toast toastList={list}/>
                    <form name="loginForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={e => setUser({ ...user, name: e.target.value })}
                                    value={user.name}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label" htmlFor="login">Login</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="login"
                                    name="login"
                                    onChange={e => setUser({ ...user, login: e.target.value })}
                                    value={user.login}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label" htmlFor="password">Contraseña</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="password"
                                    name="password"
                                    onChange={e => setUser({ ...user, password: e.target.value })}
                                    value={user.password}
                                    placeholder="Contraseña"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label" htmlFor="type">Tipo de Usuario</label>
                                <select id="type" className="form-control" value={user.type} onChange={e => setUser({ ...user, type: e.target.value })}>
                                    <option value={0} disabled="true">Seleccione...</option> 
                                    <option value={3}>Usuario</option> 
                                    <option value={2}>Admin</option> 
                                    {state.auth.type == 1 && <option value={1}>Desarrollador</option>}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving && <Spinner size={5} margin={4} ></Spinner>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>} 
            
        </>
    );
}

export default UserAdd;