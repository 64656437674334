import React, {useContext, useEffect, useState } from 'react';
import {getSize,updateSize} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { AuthContext } from '../../../context/Auth.context';
import Spinner from '../../helpers/Spinner';
import { useNavigate, useParams } from 'react-router-dom';

const Size = () => {
    let navigate = useNavigate();

    const [size, setSize] = useState({
        id: null,
        name: null,
        floors: 1,
        active: null,
        created_at: null,
        updated_at: null,
        options: []
    });

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const { state, CheckResponse } = useContext(AuthContext);
    const {id} = useParams();

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    useEffect(() => {
        getSize(state.auth,id).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setSize(data.size);
            }
            setLoading(false);
          });
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();
        setSaving(true);
        updateSize(state.auth,size).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Guardado con exito!','success');
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setSaving(false);
          });
    }

    const toggleSelected = (id) => {
        let newsizeoptions = size.options;

        const objWithIdIndex = newsizeoptions.findIndex((obj) => obj.id === id);

        if (objWithIdIndex > -1) {
            newsizeoptions[objWithIdIndex].selected = !newsizeoptions[objWithIdIndex].selected;
            setSize({...size ,options: newsizeoptions});
        }
    }

    return (
        <>
            <style>
                {
                `.active {
                    border: solid 1px rgba(0, 0, 0, 0.125) !important
                }`
                }
            </style>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Tamaño</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <Toast 
                        toastList={list}
                    />
                    <form name="loginForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-4">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={e => setSize({ ...size, name: e.target.value })}
                                    value={size.name}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label" htmlFor="name">Pisos</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    step={1}
                                    min={1}
                                    name="floors"
                                    id="floors"
                                    onChange={e => setSize({ ...size, floors: e.target.value })}
                                    value={size.floors}
                                    placeholder="Pisos"
                                />
                            </div>
                            <div className="col-sm-3">
                                <label className="form-label" htmlFor="sizeoptionsSelect">Opciones de Tamaño</label>
                                    <ul className="list-group">
                                    {size.options.map(item => 
                                        <li key={item.id} onClick={() =>{toggleSelected(item.id)}} className={`list-group-item ${item.selected ? 'active' : ''}`} aria-current="true">{item.name}</li>
                                    )}
                                        
                                    </ul>
                            </div>

                            <div className="col-sm-4">
                                    <label className="form-label" htmlFor="name">Activo</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setSize({ ...size, active: !size.active })} className="form-check-input" type="checkbox" checked={size.active ? 'checked' : ''}></input>
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving &&  <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" style={{"width" : "5rem", "height": "5rem"}} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>}

            {loading && <Spinner size={8} margin={4} ></Spinner>}
            
        </>
    );
}

export default Size;