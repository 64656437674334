import { backend } from "../Constants";

export async function getDashboard(ctx) {
    return await fetch(backend + 'dashboard', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': ctx.token,
            'Type': ctx.type,
        },
        })
        .then(data => data.json())
        .catch((err) => {
            return'Error en el servidor';
        });
}