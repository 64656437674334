import {useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../../assets/img/ponctium 64.png';
import Spinner from "./Spinner";
import { switchUser } from "../services/salesServices";
import { FakeIpcRenderer } from "./FakeIpcRenderer";
import { isElectron } from "../functions/isElectron";

let ipcRenderer = FakeIpcRenderer;

if(isElectron()){
    ipcRenderer = window.require("electron").ipcRenderer;
}

const Sidebar = ({logout,state,version,newVersion,CheckResponse, doLogin}) => {
    const location = useLocation();
    const currentPage = location.pathname.split("/");
    
    const checkMemuState = () => {
        const ls = localStorage.getItem('menu');
        if (ls === 'true') {
            return true
        } else if (ls === null || ls === 'false') {
            return false
        }
    }  
    const [toggle, setToggle] = useState(false);
    const [switchUserModal, setSwitchUserModal] = useState(false);
    const [usersToSwith, setUsersToSwith] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [credentials, setCredentials] = useState({
        login: '',
        password: ''
    });
    
    const [activeItem, setActiveItem] = useState('');
    const [menuResponsive, setMenuResponsive] =  useState(checkMemuState()); 

    useEffect(() => {
        let active = "/";
        if(currentPage[1] !== ""){
            active = currentPage[1];
        }
        setActiveItem(active);
    }, []);
    
    const toggleUserMenu = () => {
        setToggle(!toggle);
    }
    
    const toggleMenu = () => {
        localStorage.setItem('menu',!menuResponsive);
        setMenuResponsive(!menuResponsive);
    }

    const optionalMenu = () =>{
        setToggle(false);
        setActiveItem('none');
    }

    const switchModal = () => {
        setLoadingUsers(true);
        setSwitchUserModal(true);
        setToggle(false);

        switchUser(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setUsersToSwith(data.users);
            }
            setLoadingUsers(false);
          });
    }

    const tryLogin = () => {
        const { login, password } = credentials;
        doLogin(login, password);
    }

    const openChasDrawer = () => {
        ipcRenderer.send("open-chash-drawer",JSON.stringify({config: state.config}));
    };
    
    return (
    <>
        {/* menu grande  */}
        {!menuResponsive && <div className="d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style={{ "width": "200px", /*"height": "100vh",*/  "minHeight":"100%" }}>
            <a style={{ "marginBottom": "-20px"}} onClick={toggleMenu} className="d-flex align-items-center text-white text-decoration-none">
            <i className="bi bi-list" style={{"fontSize" : "2.5em"}}></i> <span className="fs-4"> Ponctium <img src={logo} style={{"width" : "21px"}}></img></span>
            </a>
            <hr></hr>
            <ul className="nav nav-pills flex-column mb-auto">
            {state.auth.type != 3 &&<li className="nav-item">
                    <Link to={"/"} onClick={() => {setActiveItem('/')}} className={`nav-link ${activeItem == "/" ? 'active' : 'text-white'}`}>
                        <i className="bi bi-speedometer2"></i> Dashboard
                    </Link>
                </li>}
                {state.auth.type != 3 && <li>
                    <Link to={"catalogs"} onClick={() => {setActiveItem('catalogs')}} className={`nav-link ${activeItem == 'catalogs' ? 'active' : 'text-white'}`}>
                        <i className="bi bi-list-ol"></i> Catalogos
                    </Link>
                </li>}
                <li className="nav-item">
                    <Link to={"orders"} onClick={() => {setActiveItem('orders')}}  className={`nav-link ${activeItem == "orders" ? 'active' : 'text-white'}`}>
                        <i className="bi bi-calendar-event"></i> Ordenes
                    </Link>
                </li>
                <li>
                    <Link to={"sales"} onClick={() => {setActiveItem('sales')}} className={`nav-link ${activeItem == "sales" ? 'active' : 'text-white'}`}>
                        <i className="bi bi-cart"></i> Ventas
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"tickets"} onClick={() => {setActiveItem('tickets')}} className={`nav-link ${activeItem == "tickets" ? 'active' : 'text-white'}`}>
                        <i className="bi bi-receipt"></i> Tickets
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"cuts"} onClick={() => {setActiveItem('cuts')}} className={`nav-link ${activeItem == "cuts" ? 'active' : 'text-white'}`}>
                        <i className="bi bi-calculator"></i> Cortes
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to={"cake-labels"} onClick={() => {setActiveItem('cake-labels')}} className={`nav-link ${activeItem == "cake-labels" ? 'active' : 'text-white'}`}>
                        <i className="bi bi-cake"></i> Pasteles
                    </Link>
                </li> */}
                {isElectron() && <li className="nav-item">
                    <Link onClick={() => {openChasDrawer()}} className={`nav-link text-white`}>
                        <i className="bi bi-cash-coin"></i> Abrir caja
                    </Link>
                </li>}
            </ul>
            <hr></hr>
            <div className="dropdown">
                <a  className={`d-flex align-items-center text-white text-decoration-none dropdown-toggle`} onClick={toggleUserMenu}>
                <i className="bi bi-person-circle" style={{ "fontSize": "2em", "color":"#0d6efd"}}></i>
                <strong className="ms-2">{state.auth.displayName}</strong>
                </a>
                <a className={`d-flex text-center align-items-center text-white text-decoration-none`}>
                <p className="text-center mb-0 fs-6">V {version}</p>
                </a>

                {newVersion && <Link to={"versions"} className={`d-flex text-center align-items-center text-white text-decoration-none`}>
                <i className="bi bi-download" style={{ "fontSize": "2em", "color":"#0d6efd"}}></i>
                <p className="mb-0 fs-6 ms-2">Nueva Version</p>
                </Link>}
                <ul className={`dropdown-menu dropdown-menu-dark text-small shadow ${toggle ? 'show' : ''}`} style={{ "bottom": "15vh" }}>
                {state.auth.type != 3 &&<li><Link onClick={() => {optionalMenu()}} className="dropdown-item" to={"versions"}>Versiones</Link></li>}
                {state.auth.type != 3 &&<li><Link onClick={() => {optionalMenu()}} className="dropdown-item" to={"users"}>Usuarios</Link></li>}
                {state.auth.type != 3 &&<li><Link onClick={() => {optionalMenu()}} className="dropdown-item" to={"config"}>Configuración</Link></li>}
                {state.auth.type != 1 &&<li><a onClick={() => {switchModal()}} className="dropdown-item">Cambiar Usuario</a></li>}
                <li><hr className="dropdown-divider"></hr></li>
                <li><a className="dropdown-item" onClick={logout}>Cerrar Sesion</a></li>
                </ul>
            </div>
        </div>}
        
        {/* menu chiquito  */}
        {menuResponsive && <div className="d-flex flex-column flex-shrink-0 bg-light" style={{ "width": " 4.5rem", /*"height": "100vh",*/ "minHeight":"100%" }}>
            <a onClick={toggleMenu} className="ms-1 d-block p-3 link-dark text-decoration-none" style={{ "fontSize": " 2em", "marginBottom" : "-10px"}}>
                <i className="bi bi-list"></i>
            </a>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center" style={{ "fontSize": " 1.5em"}}>
                <li >
                    {state.auth.type != 3 &&<Link to={"/"} onClick={() => {setActiveItem('/')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "/" ? 'active' : ''}`}>
                        <i className="bi bi-speedometer2"></i>
                    </Link>}
                </li>
                <li>
                    {state.auth.type != 3 && <Link to={"catalogs"} onClick={() => {setActiveItem('catalogs')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "catalogs" ? 'active' : ''}`}>
                        <i className="bi bi-list-ol"></i>
                    </Link>}
                </li>
                <li >
                    <Link to={"orders"} onClick={() => {setActiveItem('orders')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "orders" ? 'active' : ''}`}>
                        <i className="bi bi-calendar-event"></i>
                    </Link>
                </li>
                <li>
                    <Link to={"sales"} onClick={() => {setActiveItem('sales')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "sales" ? 'active' : ''}`}>
                        <i className="bi bi-cart"></i>
                    </Link>
                </li>
                <li>
                    <Link to={"tickets"} onClick={() => {setActiveItem('tickets')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "tickets" ? 'active' : ''}`}>
                        <i className="bi bi-receipt"></i>
                    </Link>
                </li>
                <li >
                    <Link to={"cuts"} onClick={() => {setActiveItem('cuts')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "cuts" ? 'active' : ''}`}>
                        <i className="bi bi-calculator"></i>
                    </Link>
                </li>
                {/* <li >
                    <Link to={"cake-labels"} onClick={() => {setActiveItem('cake-labels')}} className={`nav-link py-2 border-bottom rounded-0 ${activeItem == "cake-labels" ? 'active' : ''}`}>
                    <i className="bi bi-cake"></i>
                    </Link>
                    </li> */}
                {isElectron() && <li>
                    <Link onClick={() => {openChasDrawer()}} className={`nav-link py-2 border-bottom rounded-0`}>
                        <i className="bi bi-cash-coin"></i>
                    </Link>
                </li>}
            </ul>
            <hr></hr>

            <div className="dropdown ms-3 mb-3">
                <a className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle show" onClick={toggleUserMenu}>
                    <i className="bi bi-person-circle" style={{ "fontSize": "2em", "color":"#0d6efd"}}></i>
                </a>
                <a className={`d-flex text-center align-items-center text-black text-decoration-none`}>
                    <p className="text-center mb-0 fs-6">V {version} </p>
                </a>
                {newVersion && <Link to={"versions"} onClick={() => {optionalMenu()}} className={`d-flex text-center align-items-center text-black text-decoration-none`}>
                    <i className="bi bi-download" style={{ "fontSize": "2em", "color":"#0d6efd"}}></i>
                </Link>}
                <ul className={`dropdown-menu text-small shadow ${toggle ? 'show' : ''}`} style={{ "bottom": "15vh" }}>
                    {state.auth.type != 3 &&<li><Link className="dropdown-item" onClick={() => {optionalMenu()}} to={"versions"}>Versiones</Link></li>}
                    {state.auth.type != 3 &&<li><Link className="dropdown-item" onClick={() => {optionalMenu()}} to={"users"}>Usuarios</Link></li>}
                    {state.auth.type != 3 &&<li><Link className="dropdown-item" onClick={() => {optionalMenu()}} to={"config"}>Configuración</Link></li>}
                    {state.auth.type != 1 &&<li><a className="dropdown-item" onClick={() => {switchModal()}}>Cambiar Usuario</a></li>}
                    <li><hr className="dropdown-divider"></hr></li>
                    <li><a className="dropdown-item" onClick={logout}>Cerrar Sesion</a></li>
                </ul>
            </div>
        </div>}

        <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: switchUserModal ? 'block' : 'none' }}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-header p-1 ps-3 pe-3">
                        <h1 className="modal-title fs-5">Cambiar Usuario</h1>
                        <button onClick={() => { setSwitchUserModal(false) }} type="button" className="btn-close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            
                            {loadingUsers && <div className="row">
                                <div className="col-md-12 mt-2">
                                    <Spinner size={4}></Spinner>
                                </div>
                            </div>}
                            {!loadingUsers && <div className="row">
                                <div className="col-md-12 mt-2">
                                    <label>Usuario</label>
                                    <select id="categorySelect" className="form-control" value={credentials.login} onChange={e => setCredentials({...credentials, login: e.target.value})}>
                                        <option value="" defaultValue={true}>Selecione...</option>
                                        {usersToSwith.map(item => 
                                            <option key={item.login} value={item.login}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Contraseña</label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        name="password"
                                        onChange={e => setCredentials({ ...credentials, password: e.target.value })}
                                        value={credentials.password}
                                        placeholder="********"
                                    />
                                </div>
                            </div>}
                        </div>
                    </div>
                    {!loadingUsers && <div className="modal-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <div className="d-grid gap-2">
                                        <button onClick={() => { tryLogin() }} className="btn btn-success btn-sm" type="button">Cambiar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </>
    );
}

export default Sidebar;