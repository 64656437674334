export async function resizeImg(base64, maxWidth, maxHeight) {
   return new Promise(resolve => {
       // Max size for thumbnail
      if(typeof(maxWidth) === 'undefined')  maxWidth = 100;
      if(typeof(maxHeight) === 'undefined')  maxHeight = 100;

      // Create and initialize two canvas
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var canvasCopy = document.createElement("canvas");
      var copyContext = canvasCopy.getContext("2d");

      // Create original image
      var img = new Image();
      img.src = base64;

      img.onload = function() { // This will print out the width.
         // Determine new ratio based on max size
         var ratio = 1;
         if(img.width > maxWidth)
         ratio = maxWidth / img.width;
         else if(img.height > maxHeight)
         ratio = maxHeight / img.height;
      
         // Draw original image in second canvas
         canvasCopy.width = img.width;
         canvasCopy.height = img.height;
         copyContext.drawImage(img, 10, 10);
      
         // Copy and resize second canvas to first canvas
         canvas.width = img.width * ratio;
         canvas.height = img.height * ratio;
         ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);
          
         resolve(canvas.toDataURL());
      }
   });
  
}