import { Link } from "react-router-dom";
import { isElectron } from "../functions/isElectron";

const OfflineIndicator = () => {

    return (
        <>
            <div className="d-flex justify-content-center text-center mt-5">
                <h3>Sin conexion a internet</h3>
            </div>
            <div className="d-flex justify-content-center text-center">
                <i style={{"fontSize" : `5em`}} className="bi bi-wifi-off"></i>
            </div>
            <div className="d-flex justify-content-center text-center">
                <h3>Reconectando...</h3>
            </div>
            {isElectron() &&<div className="d-flex justify-content-center text-center">
                <Link to="/sales">Ir a ventas</Link>
            </div>}
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-grow text-primary" style={{"width" : `10em`, "height": `10em`}}>
                    <span className="visually-hidden">Conecting...</span>
                </div>
            </div>
           
        </>
    );
}

export default OfflineIndicator;