import React, { Fragment, useContext, useEffect, useState } from 'react';
import {getCategory,updateCategory} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { AuthContext } from '../../../context/Auth.context';
import { useNavigate, useParams } from 'react-router-dom';

const Category = () => {
    let navigate = useNavigate();

    const [category, setCategory] = useState({
        id: '',
        name: '',
        ieps: '',
        cake_label: '',
        active: '',
        created_at: '',
        updated_at: ''
    });
    
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const {state, CheckResponse } = useContext(AuthContext);
    const {id} = useParams();

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    useEffect(() => {
        getCategory(state.auth,id).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setCategory(data.category);
            }
            setLoading(false);
          });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSaving(true);
        updateCategory(state.auth,category).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Guardado con exito!','success');
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setSaving(false);
          });
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Categoria</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <Toast 
                        toastList={list}
                    />
                    <form name="loginForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-3">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={e => setCategory({ ...category, name: e.target.value })}
                                    value={category.name}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-3">
                                    <label className="form-check-label mb-3"  htmlFor="flexSwitchCheckCheckedIeps">Ieps</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setCategory({ ...category, ieps: !category.ieps })} className="form-check-input" type="checkbox" id="flexSwitchCheckCheckedIeps" checked={category.ieps ? 'checked' : ''}></input>
                                </div>
                            </div>
                            {state.config.cake_labels && <div className="col-sm-3">
                                    <label className="form-check-label mb-3"  htmlFor="flexSwitchCheckCheckedCakeLabels">Etiqueta de pasteles</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setCategory({ ...category, cake_label: !category.cake_label })} className="form-check-input" type="checkbox" id="flexSwitchCheckCheckedCakeLabels" checked={category.cake_label ? 'checked' : ''}></input>
                                </div>
                            </div>}
                            <div className="col-sm-3">
                                    <label className="form-check-label mb-3" htmlFor="flexSwitchCheckCheckedActive">Activo</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setCategory({ ...category, active: !category.active })} className="form-check-input" type="checkbox" id="flexSwitchCheckCheckedActive" checked={category.active ? 'checked' : ''}></input>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving &&  <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" style={{"width" : "5rem", "height": "5rem"}} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>}

            {loading &&  <div className="d-flex justify-content-center mt-4">
                            <div className="spinner-border text-primary" style={{"width" : "8rem", "height": "8rem"}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>}

                    
            
        </Fragment>
    );
}

export default Category;