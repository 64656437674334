import OfflineSidebar from "../helpers/OfflineSidebar";
import Sales from "./Sales";

const NoSessionSale = () => {
    
    return ( 
        <main className="d-flex flex-nowrap" style={{"height": "100vh"}}>
            <OfflineSidebar/>
            <div className="container mt-3" style={{overflowY: "auto"}}>
                <div className="row">
                    <div className="col-sm-12">
                        <Sales/>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default NoSessionSale;