import {useContext} from "react";

import { AuthContext } from './context/Auth.context.js';
import LoginForm from './components/Login';
import Menu from './components/Menu';
import { useIsOnline } from "react-use-is-online";
import NoSessionSale from "./components/sales/NoSessionSale.js";

const App = () => {
  const { state } = useContext(AuthContext);
  const {isOnline} = useIsOnline();
  if (!state.isLoggedIn) 
    return isOnline ? <LoginForm/> : <NoSessionSale/>
  else
    return <Menu />
}

export default App;