import React, {Fragment, useContext, useEffect, useState } from 'react';
import {getFlavors} from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { AuthContext } from '../../../context/Auth.context';
import Spinner from '../../helpers/Spinner';
import { Link, useNavigate } from 'react-router-dom';
const Flavors = () => {
    let navigate = useNavigate();

    const [flavors, setFlavors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pages, setPages] = useState([]);
    const [total_current, setTotal_current] = useState(1);
    const [current_page, setCurrent_page] = useState(1);
    const [first_item, setFirst_item] = useState(1);
    const [last_item, setLast_item] = useState(1);
    const { state, CheckResponse } = useContext(AuthContext);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }
    
    const loadFlavors = () => {
        getFlavors(state.auth,10,current_page).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setFlavors(data.flavors);
                setPages(data.pages);
                setTotal_current(data.total_current);
                setCurrent_page(data.current_page);
                setFirst_item(data.first_item);
                setLast_item(data.last_item);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoading(false);
          });
    }

    useEffect(() => {
        loadFlavors();
    }, [current_page]);

    return (
        <>
            <Toast toastList={list}/>
            <div className="row">
                <div className="col-sm-4">
                    <h1>Sabores</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <Link to="/catalogs/flavor/" className="btn btn-success mt-2"><i className="bi bi-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Activo</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {flavors?.map(item => 
                                <tr key={item.id}>
                                    <th>{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.active == true && <span className="badge text-bg-success">Si </span>}
                                        {item.active == false && <span className="badge text-bg-danger">No </span>}
                                    </td>
                                    <th><Link className="btn btn-primary" to={`/catalogs/flavor/${item.id}`} role="button"><i className="bi bi-pencil"></i></Link></th>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <ul className="pagination">
                                {pages.map(page => 
                                    <Fragment key={page}>
                                        {current_page === page ? 
                                            <span key={page} className="page-link active me-2" style={{"width": "40px", "textAlign": "center"}}>{page}</span> 
                                            : 
                                            <li key={page} className="page-page me-2" style={{"width": "40px", "textAlign": "center"}}><a className="page-link" onClick={() => setCurrent_page(page)}>{page}</a></li>
                                        }
                                    </Fragment>
                                )}
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <p className="text-sm-start mt-2">Mostrando <b>{first_item}</b> a <b>{last_item}</b> de un total de <b>{total_current}</b></p>
                        </div>
                    </div>
                    
                </div>
            </div>}

            {loading && <Spinner size={8} margin={4} ></Spinner>}
        </>
    );
}

export default Flavors;