import React, {useContext, useEffect, useState } from 'react';
import {getConfig,updateConfig} from '../services/catalogServices';
import Toast from '../helpers/Toast';
import { AuthContext } from '../../context/Auth.context';
import Spinner from '../helpers/Spinner';
import { getActualDate } from '../functions/getActualDate';

const Config = () => {

    const [config, setConfigNew] = useState({
        printer_name: '',
        local_name: '',
        rfc: '',
        dir_1: '',
        dir_2: '',
        city: '',
        phone: '',
        email: '',
        cake_labels: false,
        cake_management: false,
        dlls_count_for_withdraw: false,
    });

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const {state, CheckResponse, setConfig} = useContext(AuthContext);
    const [modalPreview, setModalPreview] = useState(false);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    useEffect(() => {
        getConfig(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setConfigNew(data.config);
            }
            setLoading(false);
          });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSaving(true);
        updateConfig(state.auth,config).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Guardado con exito!','success');
                setConfig(data.config);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setSaving(false);
          });
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Configuración</h1>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <Toast 
                        toastList={list}
                    />
                    <form name="loginForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Nombre de la Impresora</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, printer_name: e.target.value })}
                                    value={config.printer_name}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Nombre de la empresa</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, local_name: e.target.value })}
                                    value={config.local_name}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">RFC</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, rfc: e.target.value })}
                                    value={config.rfc}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Dirección 1</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, dir_1: e.target.value })}
                                    value={config.dir_1}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Dirección 2</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, dir_2: e.target.value })}
                                    value={config.dir_2}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Ciudad</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, city: e.target.value })}
                                    value={config.city}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Telefono</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, phone: e.target.value })}
                                    value={config.phone}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                <label className="form-label">Correo</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={e => setConfigNew({ ...config, email: e.target.value })}
                                    value={config.email}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                    <label className="form-label" htmlFor="name">Etiquetas de pasteles</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setConfigNew({ ...config, cake_labels: !config.cake_labels })} className="form-check-input" type="checkbox" checked={config.cake_labels ? 'checked' : ''}></input>
                                    <label className="form-check-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                    <label className="form-label" htmlFor="name">Administrador de pasteles</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setConfigNew({ ...config, cake_management: !config.cake_management })} className="form-check-input" type="checkbox" checked={config.cake_management ? 'checked' : ''}></input>
                                    <label className="form-check-label"></label>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-2">
                                    <label className="form-label" htmlFor="name">Los dlls cuenta para el retiro de caja</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setConfigNew({ ...config, dlls_count_for_withdraw: !config.dlls_count_for_withdraw })} className="form-check-input" type="checkbox" checked={config.dlls_count_for_withdraw ? 'checked' : ''}></input>
                                    <label className="form-check-label"></label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 mt-2">
                                <div className="d-grid gap-2 col-8 mx-auto">
                                    <button onClick={() =>{setModalPreview(true)}} className="btn btn-primary" type="button"><i className="bi bi-eye"></i> Previsualizar recibo</button>
                                </div>
                            </div> 
                            <div className="col-sm-6 mt-2">
                                {!saving &&  <div className="d-grid gap-2 col-8 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving &&  <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" style={{"width" : "5rem", "height": "5rem"}} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
            <div className="modal fade show" style={{ background: "rgba(0, 0, 0, 0.8)", display: modalPreview ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header p-1 ps-3 pe-3">
                            <h1 className="modal-title fs-5">Previsualizar recibo</h1>
                            <button onClick={() => { setModalPreview(false) }} type="button" className="btn-close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {config.local_name.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h3>{config.local_name}</h3>
                                    </div>
                                </div>}
                                {config.rfc.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <label>{config.rfc}</label>
                                    </div>
                                </div>}
                                {config.dir_1.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <label>{config.dir_1}</label>
                                    </div>
                                </div>}
                                {config.dir_2.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <label>{config.dir_2}</label>
                                    </div>
                                </div>}
                                {config.city.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <label>{config.city}</label>
                                    </div>
                                </div>}
                                <hr></hr>
                                    <div className="col-sm-12 text-center">
                                        <p>{getActualDate()}</p>
                                    </div>
                                <hr></hr>
                                <div className="col-sm-12">
                                    <div className='row'>
                                        <div className="col-sm-8">
                                           <p className='text-start'> BOLILLO / TELERA</p>
                                        </div>
                                        <div className="col-sm-4">
                                            <p className='text-end'>$ 6.00</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-12">
                                            <p className='text-end'>X2    $ 12.00</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-8">
                                           <p className='text-start'> PLANCHA ZANAHORIA INTEGRAL</p>
                                        </div>
                                        <div className="col-sm-4">
                                            <p className='text-end'>$ 13.00</p>
                                            
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-12">
                                            <p className='text-end'>X2    $ 26.00</p>
                                        </div>
                                    </div>
                                </div>
                                {config.phone.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <label>{config.phone}</label>
                                    </div>
                                </div>}
                                {config.email.length > 1 && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <label>{config.email}</label>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => { setModalPreview(false) }} className="btn btn-primary btn-sm" type="button">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Spinner size={8} margin={4} ></Spinner>}
            
        </>
    );
}

export default Config;