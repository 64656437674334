import React, { useState } from 'react';
import { backend } from '../components/Constants';

export const AuthContext = React.createContext(null);

const initialState = {
    isLoggedIn: localStorage.getItem('logged'),
    isLoginPending: false,
    loginError: null,
    auth: {token: localStorage.getItem('token'), displayName: localStorage.getItem('name'), type: localStorage.getItem('type')},
    config: {}
}

export const ContextProvider = props => {
    const [state, setState] = useState(initialState);

    const login = (email, password) => {
        setState({
            isLoggedIn: false,
            isLoginPending: true,
            loginError: null
        });

        fetchLogin(email, password, (logged,data) => {
            if(logged) {
                setState({
                    isLoggedIn: true,
                    isLoginPending: false,
                    loginError: null,
                    auth: data.auth,
                    config: data.config
                });
                localStorage.setItem('logged', true);
                localStorage.setItem('token', data.auth.token);
                localStorage.setItem('name', data.auth.displayName);
                localStorage.setItem('type', data.auth.type);
            } else {
                setState({
                    isLoggedIn: false,
                    isLoginPending: false,
                    loginError: data
                });
            }
        });
    }

    const logout = () => {
        localStorage.removeItem('logged');
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('type');
        setState({
            isLoggedIn: false,
            isLoginPending: false,
            loginError: null,
            auth: {token: null, displayName: null, type: null}
        });
    }
    
    const CheckResponse = (data) => {
        if(data.code == 501){
            logout();
        }
    }
    
    const setConfig = (data) => {
        setState({
            ...state , config: data
        });
    }
    
    return (
        <AuthContext.Provider
            value={{
                state,
                login,
                logout,
                CheckResponse,
                setConfig
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

const fetchLogin = async (login_data, password_data, callback) => {
    await fetch(backend + 'auth', {
    method: 'POST',
    body: JSON.stringify({
        login: login_data,
        password: password_data,
    }),
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
    },
    })
    .then((response) => response.json())
    .then((data) => {
        if(data.code == 200){
            return callback(true,data);
        }else if(data.code == 500) {
            return callback(false,new Error(data.message));
        }
        else{
            return callback(false,new Error('Error en el servidor'));
        }
    })
    .catch((err) => {
        return callback(false,new Error('Error en el servidor'));
    });
};

