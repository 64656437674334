export class FakeIpcRenderer {
    constructor() {
      
    }

    static on() {}
    
    static send(){}

    static removeAllListeners(listener){}

}