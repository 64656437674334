import React, { useContext, useEffect, useState } from 'react';
import { getOrderCreators } from '../../services/catalogServices';
import Toast from '../../helpers/Toast';
import { AuthContext } from '../../../context/Auth.context';
import { Link, useNavigate } from 'react-router-dom';
const OrderCreators = () => {
    let navigate = useNavigate();
    const [orderCreators, setOrderCreators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    const { state,CheckResponse } = useContext(AuthContext);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }
    
    useEffect(() => {
        getOrderCreators(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setOrderCreators(data.creators);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoading(false);
          });
    }, []);

    return (
        <>
            <Toast toastList={list}/>
            <div className="row">
                <div className="col-sm-8">
                    <h1>Quien hace pedidos</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <Link to="/catalogs/order/creator" className="btn btn-success mt-2"><i className="bi bi-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                {/* <th>Activo</th> */}
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderCreators.map(item => 
                                <tr key={item.id}>
                                    <th>{item.id}</th>
                                    <td>{item.name}</td>
                                    {/* <td>
                                        {item.active == true && <span className="badge text-bg-success">Si </span>}
                                        {item.active == false && <span className="badge text-bg-danger">No </span>}
                                    </td> */}
                                    <th><Link className="btn btn-primary" to={`/catalogs/order/creator/${item.id}`} role="button"><i className="bi bi-pencil"></i></Link></th>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                </div>
            </div>}

            {loading &&  <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" style={{"width" : "8rem", "height": "8rem"}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </>
    );
}

export default OrderCreators;