import React, {useContext, useEffect, useState } from 'react';
import {addTicket, getCurrentCut, getSaleItems, saveOfflineCart, setCutLimits, updateCutAlert} from '../services/salesServices';
import Toast from '../helpers/Toast';
import Spinner from '../helpers/Spinner';
import '../../styles/sales.css';
import Product from './Product';
import { AuthContext } from '../../context/Auth.context';
import { isElectron } from '../functions/isElectron';
import { FakeIpcRenderer } from '../helpers/FakeIpcRenderer';
import { useIsOnline } from 'react-use-is-online';
import { Link, useNavigate } from 'react-router-dom';
import beep_file from '../../assets/sounds/beep.wav';
import { getID } from '../functions/getID';
import { getActualDate } from '../functions/getActualDate';

let beep = new Audio(beep_file);

let ipcRenderer = FakeIpcRenderer;

if(isElectron()){
    ipcRenderer = window.require("electron").ipcRenderer;
    ipcRenderer.removeAllListeners('save-sales-json-reply');
    ipcRenderer.removeAllListeners('get-sales-json-reply');
    ipcRenderer.removeAllListeners('generate-ticket-reply');
}
    
const Sales = () => {
    let navigate = useNavigate();
    
    const {isOnline} = useIsOnline();
    const cartInitialValue = {productList:[], payment: 0, total: 0, discount:0, paymentMethod: 1, change:0, notes: '', split : false, payInDlls: false, paymentDlls: 0, changeDlls: 0, totalDlls: 0, partial: false, secondPaymentMethod : 0, secondPayment: 0, date:''};
    const [saleitems, setSaleItems] = useState([]);
    const [tipoDeCambio, setTipoDeCamio] = useState(16);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(0);
    const [paymentList, setPaymentList] = useState([]);
    const [subtotal, setSubTotal] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [lastUpdate, setLastUpdate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [cart, setCart] = useState(cartInitialValue);
    const [modalPayment, setModalPayment] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalPrint, setModalPrint] = useState(false);
    const {state, CheckResponse, setConfig } = useContext(AuthContext);
    const [currentCut, setCurrentCut] = useState({});

    const changeDiscount = (d) => {
        let disc = d;
        if(d > 100) disc = 100;

        if(d < 0) disc = 0;
        setCart({...cart, discount :  disc});
    }
    
    const numberPrice = (n) => {
        return parseFloat(n).toFixed(2);
        // return parseFloat(newN);
    }

    useEffect(() => {
        loadSaleItems();

        if(isElectron() && isOnline){
            const offlineCart = localStorage.getItem('offlineCart');
            const offlineCut = localStorage.getItem('offlineCut');
            if(isJson(offlineCart) && offlineCart !== null && isJson(offlineCut) && offlineCut !== null){
                
                const localCart = JSON.parse(offlineCart);
                const localCut = JSON.parse(offlineCut);
                
                const saveOfflineCartTimer = setTimeout(() => {
                    const loadingListId = getID();
                    setList([...list,{
                        id: loadingListId,
                        title: 'Guardando Recibos offline',
                        description: '',
                        type: "info",
                        loading: true,
                        short: true,
                        delete: false
                    }]);

                    saveOfflineCart(state.auth, localCart, localCut).then(data => {
                        CheckResponse(data);
                        if(data.code === 200){
                            // setCurrentCut(data.cut);
                            // actualizar el corte local
                            showToast('Hecho!','Recibos guardados!','success');
                            localStorage.removeItem('offlineCut');
                            localStorage.removeItem('offlineCart');
                        }
            
                        // const listItemIndex = list.findIndex(e => e.id === loadingListId);
                        // list.splice(listItemIndex, 1);
                        // setList([...list]);
                    });
                    
                    clearTimeout(saveOfflineCartTimer);
                },10000);


            }
        }
        
        ipcRenderer.removeAllListeners("get-sales-json-reply");
        ipcRenderer.removeAllListeners("save-sales-json-reply");
        ipcRenderer.removeAllListeners("generate-ticket-reply");
        
        // respuestas de electron #######################################
        ipcRenderer.on("get-sales-json-reply", (event, args) => {
            if(isJson(args) && args.length > 0){
                let localData = args;
                setSelectedCategory(localData.data[0].name);
                setSaleItems(localData.data);
                setLastUpdate(localData.delivered);
                setPaymentList(localData.payments);
                setCart({ ...cart, paymentMethod: localData.payments[0].id });
                setDefaultPaymentMethod(localData.payments[0].id);
                setConfig(localData.config);
                setTipoDeCamio(parseFloat(localData.exchange_rate));
                setLoading(false);
                if(!isOnline){
                    createOfflineCut(localData.config);
                }
            }else{
                if(isOnline){
                    loadSaleItemsFromWeb(true);
                }else{
                    showToast('Oops!','No es posible cargar la informacion!','danger');
                }
            }
        
        });
        ipcRenderer.on("save-sales-json-reply", (event, arg) => {
            if(arg){
                showToast('Hecho!','informacion guardada con exito','success');
            }else{
                showToast('Oops!','No se pudo guardar la informacion!','danger');
            }
        });
        ipcRenderer.on("generate-ticket-reply", (event, args) => {
            if(args == false){
                showToast('Oops!','No se pudo imprimir, revisa tu impresora o configuracion','danger');
            }else{
                // showToast('Hecho!','informacion guardada con exito','success');
            }
        
        });
        // respuestas de electron #######################################

    }, []);

    // comunicacion con electron #######################################
    const openChasDrawer = () => {
        ipcRenderer.send("open-chash-drawer",JSON.stringify({data: cart, config: state.config}));
    };
    
    const saveLocalJson = (data) => {
        ipcRenderer.send("save-sales-json", data);
    };
    
    const getLocaljson = () => {
        ipcRenderer.send("get-sales-json", true);
    };
    
    const generateTicket = () => {
        console.log(cart);
        ipcRenderer.send("generate-ticket", JSON.stringify({data: cart, config: state.config}));
    };
    // comunicacion con electron #######################################

    const cutAlertUpdate = () => {
        if(isOnline){
            //agregar toast de carga
            const loadingListId = getID();
            setList([...list,{
                id: loadingListId,
                title: 'Actualizando corte',
                description: '',
                type: "info",
                loading: true,
                short: true,
                delete: false
            }]);
            updateCutAlert(state.auth,currentCut).then(data => {
                CheckResponse(data);
                //quitar toast de carga
                const listItemIndex = list.findIndex(e => e.id === loadingListId);
                list.splice(listItemIndex, 1);
                setList([...list]);
                if(data.code == 200){
                    setCurrentCut({...data.cut});
    
                    showToast('Hecho!','Corte actualizado!','success');
                }else{
                    showToast('Oops!','Algo salio mal!','danger');
                }
    
            });
        }else if(!isOnline && isElectron()){
            setCurrentCut({...currentCut, alerts: 0});
            showToast('Hecho!','Corte actualizado!','success',3);
        }

    }
    
    useEffect(() => {
        if(currentCut.alerts > 0){
            setList([...list,{
                id: getID(),
                title: `Se alcanzo el limite de $${parseFloat(currentCut.limit).toLocaleString()}!!`,
                description: `Favor de guardar en la caja de seguridad`,
                type: "warning",
                action: cutAlertUpdate,
                linkDesc: "Guardado, ya no mostrar notificacion!",
                delete: 10
            }]);
        }
    }, [currentCut]);
    
  
    useEffect(() => {
        let newTotal = 0;
        cart.productList.map(product => {
            newTotal += product.qty * product.price;
        });
        setSubTotal(newTotal);
       
    }, [cart]);
   
    useEffect(() => {
        let newTotal = subtotal;
        
        if(subtotal > 0){

            if(cart.discount > 0){
                newTotal = newTotal - ( newTotal * (cart.discount / 100));
            }
    
            let change = (parseFloat(cart.payment) + parseFloat(parseFloat(cart.secondPayment))) -  parseFloat(newTotal);
    
            let newTP = tipoDeCambio > 0 ? (tipoDeCambio) : 1;

            if(change < 0) change = 0;
            let newTotalDlls = parseFloat((subtotal/newTP));
            
            if(cart.discount > 0){
                newTotalDlls = parseFloat((newTotalDlls - ( newTotalDlls * (cart.discount / 100))));
            }
            let secondPaymentDlls = 0;
    
            if(parseFloat(cart.secondPayment) > 0){
                secondPaymentDlls = parseFloat(cart.secondPayment)/newTP;
            }
            var changeDlls = parseFloat(((parseFloat(cart.paymentDlls) +  parseFloat(secondPaymentDlls)) -  parseFloat(newTotalDlls)));

            if(changeDlls < 0) changeDlls = 0;
    
            if(cart.payInDlls) change =  parseFloat(changeDlls) *  parseFloat(newTP);

            if(change < 0.50){
                change = 0;
            }
            if(changeDlls < 0.10){
                changeDlls = 0;
            }
            
            setCart({...cart, total: newTotal, totalDlls: newTotalDlls, changeDlls: changeDlls, change: change});
        }
        
    }, [cart.payment, cart.discount, parseFloat(cart.paymentDlls), tipoDeCambio, cart.payInDlls, parseFloat(cart.secondPayment), subtotal]);

    const showToast = (t,d,tt,dd= 5) => {
        setList([...list, {
            id: getID(),
            title: t,
            description: d,
            type: tt,
            short: true,
            delete: dd
        }]);
    }
    
    const cleanCart = () => {
        setCart({...cart, productList: [], payment: 0, total: 0, discount:0, paymentMethod: defaultPaymentMethod, change:0, notes: '', split: false, payInDlls:false, paymentDlls: 0, changeDlls: 0, totalDlls: 0, partial: false, secondPaymentMethod : defaultPaymentMethod, secondPayment: 0});
    }

    const createOfflineCut = (configToSet) =>{
        
        const offlineCutSaved = localStorage.getItem('offlineCut');

        if(isJson(offlineCutSaved) && offlineCutSaved !== null) {
            setCurrentCut({...JSON.parse(offlineCutSaved)});
        }else{
            //ver por que rayos no se actualiza el limit
            const offlineCut = {
                accumulated: configToSet.cut_limit,
                alerts : 0,
                id: -1,
                start: getActualDate(),
                limit: configToSet.cut_limit,
                total: 0, 
                total_for_withdraw: 0,
            };

            setCurrentCut({...offlineCut});
    
            localStorage.setItem('offlineCut',JSON.stringify(offlineCut));
        }
    }
    
    const cancelPayment = () => {
        setModalPayment(false);
        setCart({...cart, payment: 0, total: 0, discount:0, paymentMethod: defaultPaymentMethod, change:0, notes: '', split: false, payInDlls:false, paymentDlls: 0, changeDlls: 0, totalDlls: 0, partial: false, secondPaymentMethod : defaultPaymentMethod, secondPayment: 0});
    }

    const isJson = (str) => {
        try {
            if(str.length > 1){

                JSON.parse(str);
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    const turnDllsOn = () => {
        const newTP = tipoDeCambio > 0 ? parseFloat(tipoDeCambio) : 1;
        let newTotalDlls = subtotal/newTP;
        if(cart.discount > 0){
            newTotalDlls = newTotalDlls - ( newTotalDlls * (cart.discount / 100));
        }
        
        let changeDlls = parseFloat(cart.paymentDlls) - parseFloat(newTotalDlls);
        
        if(changeDlls < 0.10) changeDlls = 0;

        var change = (changeDlls * newTP);

        if(change < 0.50){
            change = 0;
        }

        setCart({...cart, totalDlls: newTotalDlls, changeDlls: changeDlls, change: change, payInDlls: true});
    }
    
    const loadSaleItems =() =>{
        if(isOnline){
            checkCurrenntCut();
            setTimeout(function() {
                if(isElectron()){
                    getLocaljson();
                }else{
                    loadSaleItemsFromWeb(false);
                }
            }, 2000);
            
        }else{
            getLocaljson();
        }
    }

    const loadSaleItemsFromWeb = (save) =>{
        setModalUpdate(false);
        setLoading(true);
        getSaleItems(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                // localStorage.setItem('saleitems',JSON.stringify(data));
                if(save){
                    saveLocalJson(data);
                }
                if(data.data.length > 0){
                    setSelectedCategory(data.data[0].name);
                }
                setSaleItems(data.data);
                setLastUpdate(data.delivered);
                setPaymentList(data.payments);
                setCart({ ...cart, paymentMethod: data.payments[0].id });
                setDefaultPaymentMethod(data.payments[0].id);
                setConfig(data.config);
                setTipoDeCamio(parseFloat(data.exchange_rate));

            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }

            setLoading(false);
        });
    }
    
    const checkCurrenntCut = () =>{
        getCurrentCut(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                // localStorage.setItem('saleitems',JSON.stringify(data));
                if(data.active){
                    setCurrentCut({...data.current});
                }else{
                    showToast('Oops!','No hay un corte!','danger');
                    navigate('/cuts');
                }
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
        });
    }
    
    const addProductToCart = (prd) => {
        let newCart = cart.productList;

        const objWithIdIndex = newCart.findIndex((obj) => obj.id === prd.id);

        if (objWithIdIndex > -1) {
            newCart[objWithIdIndex].qty = newCart[objWithIdIndex].qty + 1;
            setCart({...cart,productList: newCart});
        }else{
            newCart.unshift({id: prd.id, name: prd.name, price: prd.price, qty: 1});
            setCart({...cart,productList: newCart});
        }
        beep.play();
        // setTimeout(function(){
        //     let scrollDiv = document.getElementById("scrollable");
        //     scrollDiv.scrollTop = (scrollDiv.scrollHeight + 200);
        // }, 200);

    }

    const ProductRemoveFromCart = (id) => {
        let newCart = cart.productList;

        const objWithIdIndex = newCart.findIndex((obj) => obj.id === id);

        if (objWithIdIndex > -1) {
            newCart.splice(objWithIdIndex, 1);
            setCart({...cart,productList: newCart});
        }  
    }
    
    const ProductUpdateQty = (id, type) => {
        let newCart = cart.productList;
        const objWithIdIndex = newCart.findIndex((obj) => obj.id === id);

        if (objWithIdIndex > -1) {
            
            beep.play();
            
            if(type == 'up'){
                newCart[objWithIdIndex].qty += 1;
                setCart({...cart,productList: newCart});
            }
            if(type == 'down'){
                let newQty = newCart[objWithIdIndex].qty - 1;

                if(newQty < 0){
                    ProductRemoveFromCart(id);
                }else{
                    newCart[objWithIdIndex].qty = newQty;
                    setCart({...cart,productList: newCart});
                }
            }
        }  
    }

    const addNewProduct = () => {
        var r = Math.random();
        let newId = (Math.floor(r * (1 - 1000) + 1000) * -1);

        let newCart = cart.productList;

        newCart.push({id: newId, name: '', price: 0, qty: 1});

        beep.play();
        setCart({...cart, productList: newCart});
        
    }

    const changeNewProduct = (id, type, newValue) => {
        let newCart = cart.productList;

        const objWithIdIndex = newCart.findIndex((obj) => obj.id === id);
        
        if (objWithIdIndex > -1) {
            if(type === 'name'){
                newCart[objWithIdIndex].name = newValue;
            }
            if(type === 'price'){
                newCart[objWithIdIndex].price = newValue;
            }
            setCart({...cart, productList: newCart});
        }
    }

    const goToPrint = () => {
        const dateToCart =  getActualDate();

        if(cart.payInDlls){
            const newPayment = (tipoDeCambio * cart.paymentDlls);
            setCart({...cart, date: dateToCart, payment: newPayment});
        }else{
            setCart({...cart, date: dateToCart});
        }

        setModalPayment(false);
        setModalPrint(true);
    }
    
    const printTicket = (print) => {
        
        setModalPrint(false);
        
        if(print){
            generateTicket();
            // enviar a imprimir el ticket con el cart y la configuracion que se imprima antes de que se guarde, si algo sale mal que se agrege a los tickets locales
        }else{
            openChasDrawer();
        }

        setCart(cartInitialValue);
        
        if(isOnline){
            const loadingListId = getID();
            setList([...list,{
                id: loadingListId,
                title: 'Guardando Recibo',
                description: '',
                type: "info",
                loading: true,
                short: true,
                delete: false
            }]);
            addTicket(state.auth,cart,currentCut,tipoDeCambio).then(data => {
                CheckResponse(data);
                if(data.code === 200){
                    setCurrentCut({...data.cut});
                    showToast('Hecho!','Recibo guardado!','success',3);
                }else if (data.code === 502){
                    showToast('Oops!','No hay un corte!','danger');
                    navigate('/cuts');
                }
            });
        }else{
            showToast('Recibo local','Se guardara al conectarse a internet','info',3);
            const currentCart = cart;
            const newCut = currentCut;
            let total_for_withdraw = currentCart.total;
            if(currentCart.payInDlls){
                // const pago_1_index = paymentList.findIndex((obj) => obj.id == currentCart.paymentMethod);
                // if (pago_1_index > -1) {
                //     if(paymentList[pago_1_index].count_for_cut_withdraw == 0){
                        if(state.config.dlls_count_for_withdraw == 0){
                            total_for_withdraw -= (currentCart.paymentDlls * tipoDeCambio);
                        }
                //     }
                // }
            }else{
                const pago_1_index = paymentList.findIndex((obj) => obj.id == currentCart.paymentMethod);
                if (pago_1_index > -1) {
                    if(paymentList[pago_1_index].count_for_cut_withdraw == 0){
                        
                        total_for_withdraw -= currentCart.payment;
                    }
                }
            }

            if(currentCart.split){
                const pago_2_index = paymentList.findIndex((obj) => obj.id == currentCart.secondPaymentMethod);
    
                if (pago_2_index > -1) {
                    if(paymentList[pago_2_index].count_for_cut_withdraw == 0){
                        total_for_withdraw -= currentCart.secondPayment;
                    }
                }
            }
            
            newCut.total = newCut.total + currentCart.total;
            
            if(total_for_withdraw > 0){
                newCut.total_for_withdraw = newCut.total_for_withdraw + total_for_withdraw;
            }

            if(newCut.total_for_withdraw > newCut.accumulated){
                newCut.accumulated = newCut.accumulated + newCut.limit;
                newCut.alerts = 1;
            }

            setCurrentCut({...newCut});
            localStorage.setItem('offlineCut',JSON.stringify(newCut));

            const offlineCart = localStorage.getItem('offlineCart');

            if(isJson(offlineCart) && offlineCart !== null){
                const localData = JSON.parse(offlineCart);
                currentCart.tipoDeCambio = tipoDeCambio;
                localData.push(currentCart);
                localStorage.setItem('offlineCart',JSON.stringify(localData));
                
            }else{
                currentCart.tipoDeCambio = tipoDeCambio;
                const newData = [currentCart];
                localStorage.setItem('offlineCart',JSON.stringify(newData));
            }
        }
    }

    return (
        <>  
            {/* lista de productos y lista de compra  */}
            {!loading && <div>
                <Toast toastList={list} position='top-0 start-0'/>
                {/* <Toast toastList={cutsList} position='top-0 start-0' autoDeleteTime={10000} />
                <Toast toastList={loadingList} position='top-0 start-0' autoDelete={false}/> */}
                <div className="row">
                    {saleitems.length > 0 && <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12">
                                {saleitems.map(category => 
                                <button key={category.name} className={`btn ${selectedCategory == category.name ? 'btn-light' : 'btn-primary'}  ms-1 mb-1`} type="button" onClick={() => {setSelectedCategory(category.name)}}>
                                    {category.name}
                                </button>
                                )}
                            </div>
                        </div>
                        {saleitems.map(category => 
                            <div key={category.name} className={`row mt-2 collapse ${selectedCategory == category.name ? 'show' : ''}`}>
                                <>
                                {category.products.length > 0 && category.products && category.products.map && category.products.map(product => 
                                    <Product key={product.id} add={addProductToCart} product={product}></Product>
                                )}
                                {category.products.length == 0 && 
                                <div className="col-sm-12 text-center">
                                    <h2 className=' mt-4'>No hay productos</h2>
                                    <Link to="/catalogs/product/" className="btn btn-primary mt-2">Agregar</Link>
                                </div>}
                                </>
                            </div>
                        )}
                        
                    </div>}
                    {saleitems.length == 0 && <div className="col-sm-8 text-center">
                        <h2 className=' mt-4'>No hay categorias</h2>
                        <Link to="/catalogs/category/" className="btn btn-primary mt-2">Agregar</Link>
                    </div>}
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="d-grid gap-2">
                                    <button onClick={() => {addNewProduct()}} className="btn btn-info btn-sm" type="button"><i className="bi bi-box-seam"></i></button>
                                </div>
                            </div>
                            {isElectron() && <>
                                {isOnline &&<div className="col-sm-4">
                                    <div className="d-grid gap-2">
                                        <button onClick={() => {setModalUpdate(true)}} className="btn btn-warning btn-sm" type="button"><i className="bi bi-arrow-repeat"></i></button>
                                    </div>
                                </div>}
                                <div className="col-sm-4">
                                    <div className="d-grid gap-2">
                                        <button onClick={() => {openChasDrawer()}} className="btn btn-success btn-sm" type="button"><i className="bi bi-cash-coin"></i></button>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <div className="d-grid gap-2">
                                    <button onClick={() => {setModalPayment(true)}} className={`btn btn-success ${subtotal > 0 ? '' : 'disabled'}`} type="button"><i className="bi bi-cash"></i> Cobrar ${numberPrice(subtotal)}</button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12 text-wrap text-center">
                                <h4>Carrito de compra</h4>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-sm-12 text-wrap">
                                <ul id="scrollable" style={{"height" : "500px", "overflow": "auto"}} className="list-group">
                                    {cart.productList.map(product => 
                                        <div key={product.id}>
                                            {product.id > 0 && <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                                <table className='ms-2' style={{"width" : "100%"}}>
                                                    <tbody>
                                                        <tr style={{"width" : "100%"}}>
                                                            <td style={{"width" : "100%"}}>{product.name}</td>
                                                        </tr>
                                                        <tr style={{"width" : "100%"}}>
                                                            <td style={{"width" : "80%"}}>
                                                                <div className="input-group input-group-sm">
                                                                    <button onClick={() => {ProductUpdateQty(product.id, 'down')}} className="btn btn-outline-danger btn-sm" type="button"><i className="bi bi-dash-square"></i></button>
                                                                    <input onChange={() => {}} value={product.qty} type="numer" className="form-control form-control-sm" style={{"maxWidth": "80px", "textAlign" : "center"}}></input>
                                                                    <button onClick={() => {ProductUpdateQty(product.id, 'up')}} className="btn btn-outline-success btn-sm" type="button"><i className="bi bi-plus-square"></i></button>
                                                                    <span className="input-group-text ms-4">$ {numberPrice(product.qty * product.price)}</span>
                                                                </div>
                                                            </td>
                                                            <td style={{"textAlign" : "center", "width" : "20%"}}>
                                                                <button onClick={() => {ProductRemoveFromCart(product.id)}} type="button" className="btn btn-danger btn ms-2"><i className="bi bi-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>}
                                        
                                            {product.id < 0 && <li className="list-group-item d-flex justify-content-between align-items-center p-1">
                                                <table className='ms-2' style={{"width" : "100%"}}>
                                                    <tbody>
                                                        <tr style={{"width" : "100%"}}>
                                                            <td style={{"width" : "80%"}}><input onChange={(e) => {changeNewProduct(product.id, 'name', e.target.value)}} value={product.name} type="text" className="form-control form-control-sm mb-1"></input></td>
                                                            <td style={{"textAlign" : "center", "width" : "20%"}} rowSpan={2}>
                                                                <button onClick={() => {ProductRemoveFromCart(product.id)}} type="button" className="btn btn-danger btn ms-2"><i className="bi bi-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="input-group input-group-sm mb-1">
                                                                    <span className="input-group-text me-4">{numberPrice(product.qty)}</span>
                                                                    <span className="input-group-text ">$</span>
                                                                    <input onChange={(e) => {changeNewProduct(product.id, 'price', e.target.value)}} value={product.price} type="number" className="form-control form-control-sm" style={{"maxWidth": "80px", "textAlign" : "center"}}></input>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>}
                                        </div>
                                    )}
                                </ul> 
                            </div>
                        </div>
                        {cart.productList.length > 1 && <div className="row mt-3">
                            <div className="col-sm-12">
                                <div className="d-grid gap-2">
                                    <button onClick={() => {cleanCart()}} className={`btn btn-danger`} type="button"><i className="bi bi-trash"></i> Limpiar Lista de Productos</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>}

            {loading && <Spinner size={8} margin={4}></Spinner>}
            
            {/* modal de pago */}
            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalPayment ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">Pagar</h1>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 text-wrap text-center">
                                        <h3>Subtotal: ${numberPrice(subtotal)} {cart.payInDlls && <>/ Subtotal Dlls: ${numberPrice(subtotal/(tipoDeCambio > 0 ? tipoDeCambio : 1))}</>}</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <label className="form-label">Pago Parcial</label> <br></br>
                                        <button onClick={() => {setCart({...cart, partial: false})}} className={`btn btn ${!cart.partial ? 'btn-primary' : 'btn-outline-primary'}`} style={{width: "45%"}} type="button">No</button>
                                        <button onClick={() => {setCart({...cart, partial: true})}} className={`btn ${cart.partial ? 'btn-success' : 'btn btn-outline-success'}  ms-2`} style={{width: "45%"}} type="button">Si</button>
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Pago en dlls</label> <br></br>
                                        <button onClick={() => {setCart({...cart, payInDlls: false})}} className={`btn btn ${!cart.payInDlls ? 'btn-primary' : 'btn-outline-primary'}`} style={{width: "45%"}} type="button">No</button>
                                        <button onClick={() => {turnDllsOn()}} className={`btn ${cart.payInDlls ? 'btn-success' : 'btn btn-outline-success'}  ms-2`} style={{width: "45%"}} type="button">Si</button>
                                    </div>
                                    {cart.payInDlls && <>
                                        <div className="col-sm-4">
                                            <label className="form-label">Tipo de Camio</label>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">$</span>
                                                <input onChange={(e) => { setTipoDeCamio(e.target.value)}} type="number" value={tipoDeCambio} className="form-control" />
                                            </div>
                                        </div>
                                    </>}
                                   
                                </div>
                                
                                <div className="row mt-2">
                                    <div className="col-sm-4 text-wrap">
                                        <label className="form-label">Metodo de Pago</label>
                                        <select className="form-control" value={cart.paymentMethod} onChange={e => setCart({ ...cart, paymentMethod: parseInt(e.target.value) })}>
                                            <option value="0" disabled>Selecione...</option>
                                            {paymentList.map(item => 
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Dividir pago</label> <br></br>
                                        <button onClick={() => {setCart({...cart, split: false, secondPayment: 0})}} className={`btn btn ${!cart.split ? 'btn-primary' : 'btn-outline-primary'}`} style={{width: "45%"}} type="button">No</button>
                                        <button onClick={() => {setCart({...cart, split: true, secondPayment: 0})}} className={`btn ${cart.split ? 'btn-success' : 'btn btn-outline-success'}  ms-2`} style={{width: "45%"}} type="button">Si</button>
                                    </div>
                                    <div className="col-sm-4 text-wrap">
                                        <label className="form-label">Descuento</label>
                                        <div className="input-group input-group-sm mb-3">
                                            <input max={100}  onChange={(e) => {changeDiscount(e.target.value)}} type="number" value={cart.discount} className="form-control" />
                                            <span onClick={() => {setCart({...cart, discount : 0})}} className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* totales en pesos */}
                                {!cart.payInDlls && <div className="row">
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Descuento</h5>
                                        <h5 className='mt-3'>${cart.discount > 0 ? numberPrice(( subtotal * (cart.discount / 100))) : 0}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Total</h5>
                                        <h5 className='mt-3'>${numberPrice(cart.total)}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Pago</h5>
                                        <div className="input-group input-group-sm mb-3">
                                            <span onClick={() => {setCart({...cart, payment : 0})}} className="input-group-text">$</span>
                                            <input min={0}  onChange={(e) => {setCart({...cart, payment : e.target.value})}} type="number" value={cart.payment} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Cambio</h5>
                                        <h5 className='mt-3'>${cart.change > 0 ? numberPrice(cart.change) : 0}</h5> 
                                    </div>
                                </div>}
                                
                                {/* totales en dlls */}
                                {cart.payInDlls && <div className="row">
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Descuento</h5>
                                        <h5 className='mt-3'>${cart.discount > 0 ? numberPrice(( (subtotal / (tipoDeCambio > 0 ? tipoDeCambio : 1)) * (cart.discount / 100))) : 0}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Total</h5>
                                        <h5 className='mt-3'>${tipoDeCambio > 0 ? numberPrice(cart.totalDlls) : 0}</h5> 
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Pago</h5>
                                        <div className="input-group input-group-sm mb-3">
                                            <span className="input-group-text">$</span>
                                            <input onMouseDown={() => {setCart({...cart, paymentDlls : 0})}} onChange={(e) => {setCart({...cart, paymentDlls : e.target.value})}} type="number" value={parseFloat(cart.paymentDlls)} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap text-center mt-3">
                                        <h5>Cambio</h5>
                                        <h5 className='mt-3'>${cart.changeDlls > 0 ? numberPrice(cart.changeDlls) : 0} Dlls o ${cart.change > 0 ? numberPrice(cart.change) : 0}</h5> 
                                    </div>
                                </div>}
                                
                                {cart.split && <div className='row mt-1'>
                                    <div className="col-sm-6 text-wrap">
                                        <label className="form-label">Segundo Metodo de Pago</label>
                                        <select className="form-control" value={cart.secondPaymentMethod} onChange={e => setCart({ ...cart, secondPaymentMethod: e.target.value })}>
                                            <option value="0" disabled>Selecione...</option>
                                            {paymentList.map(item => 
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-6 text-wrap text-center">
                                        <label>Segundo Pago en Pesos</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input onMouseDown={() => {setCart({...cart, secondPayment : 0})}} onChange={(e) => {setCart({...cart, secondPayment : e.target.value})}} type="number" value={parseFloat(cart.secondPayment)} min={0} className="form-control" />
                                        </div>
                                    </div>
                                </div>}

                                {/* dinero en pesos*/}
                                {!cart.payInDlls && <div className="row mt-2">
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (subtotal + cart.payment)})}} className="btn btn-primary btn-lg" type="button">{numberPrice(subtotal)}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (5 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">5</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (10 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">10</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (20 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">20</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (50 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">50</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (100 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">100</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (200 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">200</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, payment: (500 + cart.payment)})}} className="btn btn-primary btn-lg" type="button">500</button>
                                        </div>
                                    </div>
                                </div>}
                                
                                {/* dinero en dlls*/}
                                {cart.payInDlls && <div className="row mt-2">
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: numberPrice(((subtotal / (tipoDeCambio > 0 ? tipoDeCambio : 1)) + parseFloat(cart.paymentDlls)))})}} className="btn btn-success btn-lg" type="button">{numberPrice(subtotal / (tipoDeCambio > 0 ? tipoDeCambio : 1))}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (1 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">1</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (2 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">2</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (5 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">5</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (10 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">10</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (20 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">20</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (50 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">50</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 text-wrap mt-2">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setCart({...cart, paymentDlls: (100 + parseFloat(cart.paymentDlls))})}} className="btn btn-success btn-lg" type="button">100</button>
                                        </div>
                                    </div>
                                </div>}

                                <div className="row">
                                    <div className="col-sm-12 text-wrap mt-2">
                                    <label className="form-label">Notas (opcional)</label>
                                        <input onChange={(e) => {setCart({...cart,notes: e.target.value})}} type="text" value={cart.notes} className="form-control" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {cancelPayment()}} className="btn btn-danger" type="button">Cancelar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-8 ms-auto">
                                        <div className="d-grid gap-2">
                                            {/* pagar pesos */}
                                            {!cart.payInDlls && <button onClick={() => {goToPrint()}} className={`btn btn-success ${(((cart.payment + parseFloat(cart.secondPayment)) >= cart.total || (cart.partial & (cart.payment +  parseFloat(cart.secondPayment)) > 0)) && cart.paymentMethod != 0) ? '' : 'disabled'}`} type="button">Cobrar</button>}
                                            {/* pagar dlls */}
                                            {cart.payInDlls && <button onClick={() => {goToPrint()}} className={`btn btn-success ${(((parseFloat(cart.paymentDlls) + (parseFloat(cart.secondPayment) > 0 ? (parseFloat(cart.secondPayment) / (tipoDeCambio > 0 ? tipoDeCambio : 1)): 0)) >= cart.totalDlls || (cart.partial & (parseFloat(cart.paymentDlls) + (parseFloat(cart.secondPayment) > 0 ? (parseFloat(cart.secondPayment) / parseFloat(tipoDeCambio > 0 ? tipoDeCambio : 1)): 0)) > 0)) && cart.paymentMethod != 0) ? '' : 'disabled'}`} type="button">Cobrar</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* modal de actualziacion de productos */}
            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalUpdate ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Actualizar productos</h1>
                        <button onClick={() => {setModalUpdate(false)}} type="button" className="btn-close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 text-wrap text-center">
                                    <h3>Ultima acualizacion: {lastUpdate}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-wrap text-center">
                                    <h4>Actualizar Productos</h4>
                                    <i className="bi bi-arrow-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-grid gap-2">
                                        <button onClick={() => {loadSaleItemsFromWeb(true)}} className="btn btn-warning btn-sm" type="button"><i className="bi bi-arrow-repeat"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
            {/* modal imprimir */}
            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalPrint ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Imprimir ticket</h1>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {!isElectron() && <>
                                    <div className="row ">
                                        <div className="col-sm-12 text-center mt-1">
                                            <h4>Solo se pueden imprimir en la version de escritorio!</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 mt-1">
                                            <div className="d-grid gap-2">
                                                <button onClick={() => { printTicket(false) }} className="btn btn-success" type="button">Terminar</button>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                {isElectron() && <>
                                    <div className="row ">
                                        <div className="col-sm-12 text-center mt-1">
                                            <h3>Imprimir Ticket?</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mt-1">
                                            <div className="d-grid gap-2">
                                                <button onClick={() => { printTicket(false) }} className="btn btn-danger" type="button">No</button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <div className="d-grid gap-2">
                                                <button onClick={() => { printTicket(true) }} className="btn btn-success" type="button">Si</button>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sales;