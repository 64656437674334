import React, {useContext, useEffect, useState } from 'react';
import {getUser,updateUser} from '../services/catalogServices';
import Toast from '../helpers/Toast';
import { AuthContext } from '../../context/Auth.context';
import Spinner from '../helpers/Spinner';
import { useNavigate, useParams } from 'react-router-dom';

const User = () => {
    let navigate = useNavigate();
    
    const [user, setUser] = useState({
        id: null,
        name: null,
        login: null,
        type: null,
        newPassword: null,
        active: null,
    });
    
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [list, setList] = useState([]);
    const { state, CheckResponse } = useContext(AuthContext);
    const {id} = useParams();
    
    const showToast = (t,d,tt) => {
        setList([...list, {
            id: Math.floor((Math.random() * 101) + 1),
            title: t,
            description: d,
            type: tt
        }]);
    }

    useEffect(() => {
        getUser(state.auth,id).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                data.newPassword = '';
                setUser(data.user);
            }
            setLoading(false);
          });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSaving(true);
        updateUser(state.auth,user).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Guardado con exito!','success');
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setSaving(false);
          });
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <h1>Usuario</h1>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <div className="d-grid gap-2">
                        <button onClick={() =>{navigate(-1)}} className={"btn btn-secondary mt-2"}><i className="bi bi-arrow-90deg-left"></i></button>
                    </div>
                </div>
            </div>
            {!loading && <div className="row">
                <div className="col-sm-12">
                    <Toast 
                        toastList={list}
                    />
                    <form name="loginForm" onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label">Nombre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    onChange={e => setUser({ ...user, name: e.target.value })}
                                    value={user.name}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label">Login</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="login"
                                    onChange={e => setUser({ ...user, login: e.target.value })}
                                    value={user.login}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label">Nueva Contraseña</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="newPassword"
                                    onChange={e => setUser({ ...user, newPassword: e.target.value })}
                                    value={user.newPassword}
                                    placeholder="Contraseña"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                <label className="form-label">Tipo de Usuario</label>
                                <select className="form-control" value={user.type} onChange={e => setUser({ ...user, type: e.target.value })}>
                                    <option value={3}>Usuario</option> 
                                    <option value={2}>Admin</option> 

                                    {state.auth.type == 1 && <option value={1}>Desarrollador</option>}
                                </select>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-1">
                                    <label className="form-label">Activo</label>
                                    <div className="form-check form-switch">
                                    <input onChange={e => setUser({ ...user, active: !user.active })} className="form-check-input" type="checkbox" checked={user.active ? 'checked' : ''}></input>
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-5">
                                {!saving &&  <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-success" type="submit"><i className="bi bi-save"></i> Guardar</button>
                                </div>}
                                {saving &&  <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" style={{"width" : "5rem", "height": "5rem"}} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>}

            {loading && <Spinner size={8} margin={4} ></Spinner>}
            
        </>
    );
}

export default User;