import React, { Fragment, useContext, useEffect, useState } from 'react';
import {endCut, getCuts, startCut, setCutLimits, getCutPrints} from '../services/salesServices';
import Toast from '../helpers/Toast';
import Spinner from '../helpers/Spinner';
import { AuthContext } from '../../context/Auth.context';
import { useNavigate, Link } from 'react-router-dom';
import { FakeIpcRenderer } from '../helpers/FakeIpcRenderer';
import { isElectron } from '../functions/isElectron';
import { getID } from '../functions/getID';
import { moneyFormatDecimals } from '../Constants';

let ipcRenderer = FakeIpcRenderer;

if(isElectron()){
    ipcRenderer = window.require("electron").ipcRenderer;
    ipcRenderer.removeAllListeners('print-cut-reply');
}

const Cuts = () => {

    let navigate = useNavigate();
    const [cuts, setCuts] = useState([]);
    const [cutLimit, setCutLimit] = useState([]);
    const [activeCut, setActiveCut] = useState(false);
    const [currentCut, setCurrentCut] = useState([]);
    const [loading, setLoading] = useState(true);
    const [limitLoading, setLimitLoading] = useState(false);
    const [list, setList] = useState([]);
    const [pages, setPages] = useState([]);
    const [total_current, setTotal_current] = useState(1);
    const [current_page, setCurrent_page] = useState(1);
    const [first_item, setFirst_item] = useState(1);
    const [last_item, setLast_item] = useState(1);
    const {state, CheckResponse, setConfig } = useContext(AuthContext);
    const [saving, setSaving] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: getID(),
            title: t,
            description: d,
            type: tt
        }]);
    }
    
    useEffect(() => {
        loadCuts();
    }, [current_page]);
    
    
    useEffect(() => {
        ipcRenderer.removeAllListeners("print-cut-reply");
        
        // respuestas de electron #######################################
        ipcRenderer.on("print-cut-reply", (event, args) => {
            setModalLoading(false);
            if(args == false){
                showToast('Oops!','No se pudo imprimir, revisa tu impresora o configuracion','danger');
            }
        });
    });

    const loadCuts =() =>{
        getCuts(state.auth, 10, current_page).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setCuts(data.cuts);
                setCutLimit(data.cutLimit);
                setActiveCut(data.active_cut);
                setCurrentCut(data.current_cut);
                setCuts(data.cuts);
                setPages(data.pages);
                setTotal_current(data.total_current);
                setCurrent_page(data.current_page);
                setFirst_item(data.first_item);
                setLast_item(data.last_item);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoading(false);
          });
    }

    const startNewCut = () => {
        
        setSaving(true);
        startCut(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Empezado con exito, Redireccionando a ventas!','success');
                setTimeout(function() {
                    navigate('/sales');
                }, 3000);
            }else{
                setSaving(false);
                showToast('Oops!','Algo salio mal!','danger');
            }
            });
    }

    const endActualCut = () => {
        
        setSaving(true);
        endCut(state.auth).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Terminado con exito!','success');
                loadCuts();
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setSaving(false);
            });
    }
    
    const updateCutLimits = () => {
        
        setLimitLoading(true);
        setCutLimits(state.auth,cutLimit).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                showToast('Hecho!','Limite actualizado con exito!','success');
                loadCuts();
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLimitLoading(false);
        });
    }

    const printCut = async (idCut) => {
        setModalLoading(true);
        getCutPrints(state.auth,idCut).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                data.data['id'] = idCut;
                ipcRenderer.send("print-cut", JSON.stringify({cut: data.data, config: state.config}));
            }else{
                showToast('Oops!','Algo salio mal!','danger');
                setModalLoading(false);
            }
        });
    }

    return (
        <>
            <Toast 
                toastList={list}
            />
            <div className="row">
                <div className="col-sm-4">
                    <h1>Cortes</h1>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-4">
                {!activeCut && !saving && <button className="btn btn-primary mt-4" onClick={() => startNewCut()}><i className="bi bi-plus-circle"></i> Empezar Corte</button>}
                {activeCut && !saving && <button className="btn btn-danger mt-4" onClick={() => endActualCut()}><i className="bi bi-x-circle"></i> Terminar Corte</button>}
                {saving && <Spinner size={2} margin={4}></Spinner>}
                </div>
                {!state.auth.type !== 3 && !limitLoading && <>
                    <div className="col-sm-4 mt-1">
                        <label>Limite del corte</label>
                        <input type="number" value={cutLimit} onChange={(e)=> setCutLimit(e.target.value)} className='form-control'></input>
                    </div>
                    <div className="col-sm-4 mt-1">
                        <button className="btn btn-success mt-4" onClick={() => updateCutLimits()}>Actualizar limite</button>
                    </div>
                </>}
                {limitLoading && <div className="col-sm-4 mt-1">
                    <Spinner size={3} margin={4}></Spinner>
                </div>}
            </div>
            {!loading && <div className="row mt-4">
                <div className="col-sm-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Tickets</th>
                                <th>Usuario</th>
                                <th>Empezo</th>
                                <th>Termino</th>
                                <th>Total</th>
                                <th>Estado</th>
                                {isElectron() && <th>Imprimir</th>}
                               
                            </tr>
                        </thead>
                        <tbody>
                            {cuts?.map(item => 
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <th>
                                        {item.total > 0 &&  <Link to={`/cut/${item.id}`} className="btn btn-primary"><i className="bi bi-eye"></i></Link>}
                                    </th>
                                    <td>{item.user != null ? item.user.name : ""}</td>
                                    <td>{item.start}</td>
                                    <td>
                                        {item.status == 0 && <p className='text-success'>Activo</p>}
                                        {item.status == 1 && item.end}
                                    </td>
                                    <td>{moneyFormatDecimals.format(item.total_sum)}</td>
                                    <th>
                                        {item.status == 0 && <p className='text-success'>Activo</p>}
                                        {item.status == 1 && item.total > 0 && <p className='text-primary'>Terminado</p>}
                                    </th>
                                    {isElectron() && <th>
                                        {item.status == 1 && item.total > 0 && <a className="btn btn-primary" onClick={() => printCut(item.id)}><i className="bi bi-printer"></i></a>}
                                    </th>}
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <ul className="pagination">
                                {pages.map(page => 
                                    <Fragment key={page}>
                                        {current_page === page ? 
                                            <span key={page} className="page-link active me-2" style={{"width": "40px", "textAlign": "center"}}>{page}</span> 
                                            : 
                                            <li key={page} className="page-page me-2" style={{"width": "40px", "textAlign": "center"}}><a className="page-link" onClick={() => setCurrent_page(page)}>{page}</a></li>
                                        }
                                    </Fragment>
                                )}
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <p className="text-sm-start mt-2">Mostrando <b>{first_item}</b> a <b>{last_item}</b> de un total de <b>{total_current}</b></p>
                        </div>
                    </div> 
                </div>
            </div>}

            {loading && <Spinner size={8} margin={4}></Spinner>}

            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalLoading ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 text-wrap text-center">
                                        <h3>Imprimiendo</h3>
                                    </div>
                                    <div className="col-sm-12 text-wrap text-center">
                                        <Spinner size={8} margin={4}></Spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cuts;