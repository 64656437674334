import React, { Fragment, useContext, useEffect, useState } from 'react';
import {endCut, getTickets, startCut, setCutLimits, getCutPrints, deleteTicket} from '../services/salesServices';
import Toast from '../helpers/Toast';
import Spinner from '../helpers/Spinner';
import { AuthContext } from '../../context/Auth.context';
import { useNavigate, useParams } from 'react-router-dom';
import { FakeIpcRenderer } from '../helpers/FakeIpcRenderer';
import { isElectron } from '../functions/isElectron';
import { getID } from '../functions/getID';

let ipcRenderer = FakeIpcRenderer;

if(isElectron()){
    ipcRenderer = window.require("electron").ipcRenderer;
}

const Tickets = () => {

    const [tickets, setTickets] = useState([]);
    const [currentTicket, setCurrentTicket] = useState([]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pages, setPages] = useState([]);
    const [total_current, setTotal_current] = useState(1);
    const [current_page, setCurrent_page] = useState(1);
    const [first_item, setFirst_item] = useState(1);
    const [last_item, setLast_item] = useState(1);
    const {state, CheckResponse} = useContext(AuthContext);
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [loadingPrinting, setLoadingPrinting] = useState(false);
    
    const date = new Date();
    const day =date.getDate();
    const month = date.getMonth() + 1;
    const formatDate = `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
    
    const [initialDate, setInitialDate] = useState(formatDate);
    const [endDate, setEndDate] = useState(formatDate);

    const [loadingDelete, setLoadingDelete] = useState(false);

    const showToast = (t,d,tt) => {
        setList([...list, {
            id: getID(),
            title: t,
            description: d,
            type: tt
        }]);
    }
    
    useEffect(() => {
        loadTickets();
    }, [current_page, endDate, initialDate]);
    
    useEffect(() => {
        ipcRenderer.removeAllListeners("generate-ticket-reply");
        
        ipcRenderer.on("generate-ticket-reply", (event, args) => {
           setLoadingPrinting(false); 
           setModal(false); 
            if(args == false){
                showToast('Oops!','No se pudo imprimir, revisa tu impresora o configuracion','danger');
            }
        });
    });

    const loadTickets = () =>{
        setLoading(true);
        getTickets(state.auth, initialDate, endDate, 10, current_page).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                setTickets(data.tickets);
                setPages(data.pages);
                setTotal_current(data.total_current);
                setCurrent_page(data.current_page);
                setFirst_item(data.first_item);
                setLast_item(data.last_item);
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoading(false);
          });
    }
    
    const deleteTicketCurrent = () =>{
        setLoadingDelete(true);
        deleteTicket(state.auth, currentTicket.id).then(data => {
            CheckResponse(data);
            if(data.code == 200){
                loadTickets();
                showToast('Hecho!','Ticket eliminado con exito','success');
            }else{
                showToast('Oops!','Algo salio mal!','danger');
            }
            setLoadingDelete(false);
            setModalDelete(false);
          });
    }

    const modalTickets = (ticket) => {
        setCurrentTicket(ticket);
        setModal(true);
    }
    
    const modalDeleteTicker= (ticket) => {
        setCurrentTicket(ticket);
        setModalDelete(true);
    }

    const printTicket = async () => {
        setLoadingPrinting(true);
        
        const productList = [];
        console.log(currentTicket);
        for (let index = 0; index < currentTicket.details.length; index++) {
            const d = currentTicket.details[index];

            productList.push({
                id: d.id,
                name: d.name,
                price: d.unit_price,
                qty: d.qty
            });
            
        }

        const print = {
            change: currentTicket.paid_amount - currentTicket.total,
            changeDlls: currentTicket.exchange_rate > 0 ? ((currentTicket.paid_amount - currentTicket.total) / currentTicket.exchange_rate)  : 0,
            date: currentTicket.created,
            discount: parseFloat(currentTicket.discount),
            notes: currentTicket.notes,
            partial: !!currentTicket.partial_payment,
            payInDlls: currentTicket.exchange_rate > 0 ? true : false,
            payment: parseFloat(currentTicket.paid_amount),
            paymentDlls: currentTicket.exchange_rate > 0 ? parseFloat(currentTicket.paid_amount_dlls) : 0,
            paymentMethod: currentTicket.id_metodo_pago,
            productList: productList,
            secondPayment: 0,
            secondPaymentMethod: currentTicket.id_metodo_pago_partial_payment == null ? 0 : currentTicket.id_metodo_pago_partial_payment,
            split: currentTicket.split_playment,
            total: parseFloat(currentTicket.total),
            totalDlls: currentTicket.exchange_rate > 0 ? currentTicket.paid_amount / currentTicket.exchange_rate : 0,
        }
        console.log(print);
        ipcRenderer.send("generate-ticket", JSON.stringify({data: print, config: state.config}));
    }

    return (
        <>
            <Toast 
                toastList={list}
            />
            <div className="row">
                <div className="col-sm-4">
                    <h1>Tickets</h1>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-4">
                    <h5>Desde el:</h5>
                    <input
                        className="form-control"
                        type="date"
                        name="name"
                        id="name"
                        onChange={e => setInitialDate(e.target.value)}
                        value={initialDate}
                        placeholder="Nombre"
                    />
                </div>
                <div className="col-sm-4">
                    <h5>Hasta el:</h5>
                    <input
                        className="form-control"
                        type="date"
                        name="name"
                        id="name"
                        onChange={e => setEndDate(e.target.value)}
                        value={endDate}
                        placeholder="Nombre"
                    />
                </div>
            </div>
            {!loading && <div className="row mt-4">
                <div className="col-sm-12">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Sub Total</th>
                                <th>Descuento</th>
                                <th>Total</th>
                                <th>Fecha</th>
                                <th>Pago</th>
                                <th>Usuario</th>
                                <th>Productos</th>
                                {state.auth.type == 1 && <th>Eliminar</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {tickets?.map(item => 
                                <tr key={item.id}>
                                    <td>{item.total}</td>
                                    {item.discount == 0 && <td></td>}
                                    {item.discount > 0 && <td>{item.discount} %</td>}
                                    <td>{item.paid_amount}</td>
                                    <td>{item.created}</td>
                                    <td>{item.pago.name}</td>
                                    <td>{item.user.name}</td>
                                    <th>
                                        {item.details.length > 0 && <button onClick={() => {modalTickets(item)}} className="btn btn-primary"><i className="bi bi-eye"></i></button>}
                                    </th>
                                    {state.auth.type == 1 && <th>
                                        {item.details.length > 0 && <button onClick={() => {modalDeleteTicker(item)}} className="btn btn-danger"><i className="bi bi-trash"></i></button>}
                                    </th>}
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <ul className="pagination">
                                {pages.map(page => 
                                    <Fragment key={page}>
                                        {current_page === page ? 
                                            <span key={page} className="page-link active me-2" style={{"width": "40px", "textAlign": "center"}}>{page}</span> 
                                            : 
                                            <li key={page} className="page-page me-2" style={{"width": "40px", "textAlign": "center"}}><a className="page-link" onClick={() => setCurrent_page(page)}>{page}</a></li>
                                        }
                                    </Fragment>
                                )}
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <p className="text-sm-start mt-2">Mostrando <b>{first_item}</b> a <b>{last_item}</b> de un total de <b>{total_current}</b></p>
                        </div>
                    </div> 
                </div>
            </div>}

            {loading && <Spinner size={8} margin={4}></Spinner>}

            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modal ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 text-wrap text-center">
                                        {loadingPrinting && <h3>Imprimiendo</h3>}
                                        {!loadingPrinting && <h3>Productos</h3>}
                                    </div>
                                    <div className="col-sm-12 text-wrap text-left">
                                        {loadingPrinting && <Spinner size={8} margin={4}></Spinner>}
                                        {!loadingPrinting && <>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Precio unitario</th>
                                                        <th>Cantidad</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTicket.details?.map(item => 
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>${item.unit_price}</td>
                                                            <td>{item.qty}</td>
                                                            <td>${item.total}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </>}
                                    </div>
                                </div>
                                {!loadingPrinting && <div className="row">
                                    <div className={isElectron() ? "col-sm-6" : "col-sm-12"}>
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setModal(false)}} className="btn btn-danger" type="button">Cerrar</button>
                                        </div>
                                    </div>
                                    {isElectron() && <div className="col-sm-6">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {printTicket()}} className="btn btn-primary" type="button">Imprimir</button>
                                        </div>
                                    </div>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade show" style={{background: "rgba(0, 0, 0, 0.8)" ,display : modalDelete ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 text-wrap text-center">
                                        <h3>Eliminar ticket</h3>
                                    </div>
                                    <div className="col-sm-12 text-wrap text-left">
                                        
                                        {loadingDelete && <Spinner size={8} margin={4}></Spinner>}
                                        {!loadingDelete && <>
                                            <h4>Eliminar el ticket con fecha {currentTicket.created} y un total de {currentTicket.total} ?</h4>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Precio unitario</th>
                                                        <th>Cantidad</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTicket.details?.map(item => 
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>${item.unit_price}</td>
                                                            <td>{item.qty}</td>
                                                            <td>${item.total}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </>}
                                    </div>
                                </div>
                                {!loadingDelete && <div className="row">
                                    <div className="col-sm-6">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {setModalDelete(false)}} className="btn btn-danger" type="button">Cerrar</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-grid gap-2">
                                            <button onClick={() => {deleteTicketCurrent()}} className="btn btn-primary" type="button">Eliminar</button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tickets;